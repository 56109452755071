import gql from 'graphql-tag'

const jobsAssignedUser = gql`
  query JobsAssignedUser(
    $filter: paramQueryjobsAssignedUser!
    $workspaceId: Float!
    $userId: [Float!]!
    $pagination: pagination
  ) {
    jobsAssignedUser(
      filter: $filter
      workspace_id: $workspaceId
      user_id: $userId
      pagination: $pagination
    ) {
      count
      jobs {
        id
        name
        expected_close_date

        priority {
          id
          name
          color
        }

        status {
          id
          name
          color
        }

        assignedTo {
          id
          user {
            id
            phone
            email
            name
            first_name
            last_name
            photo
          }
        }

        job_type {
          id
          name
          color
          folder {
            id
            name
            color
          }

          statuses {
            id
            name
            color
          }

          priorities {
            id
            name
            color
            urutan
          }
        }
      }
    }
  }
`

export default jobsAssignedUser
