<template>
  <LimitElements
    :elements="users"
    :limit="limit"
    class="v-avatar-group"
  >
    <template #default="{ data }">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-avatar
            v-bind="attrs"
            :size="size"
            class="primary elevation-1"
            v-on="on"
          >
            <v-img
              v-if="data.user.photo"
              :src="data.user.photo"
            />
            <span
              v-else
              class="white--text"
            >
              {{ avatarText(getUserName(data.user)) }}
            </span>
          </v-avatar>
        </template>

        <span>{{ getUserName(data.user) }}</span>
      </v-tooltip>
    </template>

    <template #others="{ data }">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-avatar
            v-bind="attrs"
            :size="size"
            class="primary mb-1"
            v-on="on"
          >
            <v-img
              v-if="data.user.photo"
              :src="data.user.photo"
            />
            <span
              v-else
              class="white--text"
            >
              {{ avatarText(getUserName(data.user)) }}
            </span>
          </v-avatar>
        </template>

        <span>{{ getUserName(data.user) }}</span>
      </v-tooltip>
    </template>

    <template #others-activator="{ data }">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-avatar
            v-bind="attrs"
            v-on="on"
            :size="size"
            color="white"
            class="primary--text caption elevation-1"
          >
            <span>+{{ data.limit }}</span>
          </v-avatar>
        </template>

        <span>Lihat User Lain</span>
      </v-tooltip>
    </template>
  </LimitElements>
</template>

<script setup>
import { avatarText } from '@/@core/utils/filter'
import LimitElements from './LimitElements.vue'

/**
 * Note: User Object has at least consist of:
 * {
 *   user: {
 *     id: 1,
 *     [nameKey]: 'Jane Doe',
 *     first_name: 'Jane',
 *     last_name: 'Doe',
 *     photo: 'https://example.com/jane-doe.jpg',
 *     ...
 *   },
 *   ...
 * }
 */
const props = defineProps({
  users: {
    type: Array,
    default: () => [],
  },
  limit: {
    type: Number,
    default: 3,
  },
  nameKey: {
    type: String,
    default: 'name',
  },
  size: {
    type: Number,
    default: 36,
  },
})

const getUserName = user => {
  if (user.first_name) {
    return `${user.first_name} ${user.last_name}`
  }

  return user[props.nameKey]
}
</script>
