import gql from 'graphql-tag'

export const toggleStatusAutomation = gql`
  mutation ToggleStatusAutomation($workspaceId: Float!, $value: Boolean!, $automationId: Float!) {
    toggleStatusAutomation(
      workspace_id: $workspaceId
      value: $value
      automation_id: $automationId
    ) {
      status
      msg
      data
    }
  }
`
