import {
  mdiCalendarRemoveOutline,
  mdiCheckCircle,
  mdiCreationOutline,
  mdiFormatListBulleted,
  mdiLabelOutline,
  mdiLightbulbAlertOutline,
  mdiProgressCheck,
  mdiUpdate,
} from '@mdi/js'

export const jobSortFieldList = [
  {
    id: 4,
    label: 'Waktu Ditutup',
    icon: mdiCalendarRemoveOutline,
    target: 'expected_close_date',
  },
  {
    id: null,
    label: 'Job Diciptakan',
    icon: mdiCreationOutline,
    target: 'created_at',
  },
  {
    id: null,
    label: 'Job Diupdate',
    icon: mdiUpdate,
    target: 'updated_at',
  },
  {
    id: 2,
    label: 'Status Job',
    icon: mdiProgressCheck,
    target: 'status',
  },
  {
    id: 3,
    label: 'Prioritas Job',
    icon: mdiLightbulbAlertOutline,
    target: 'priority',
  },
  {
    id: 10,
    label: 'Nama Job',
    icon: mdiLabelOutline,
    target: 'name',
  },
]

export const commentSortFieldList = [
  {
    id: null,
    label: 'Comment Diciptakan',
    icon: mdiCreationOutline,
    target: 'created_at',
  },
  {
    id: null,
    label: 'Comment Diupdate',
    icon: mdiUpdate,
    target: 'updated_at',
  },
  {
    id: null,
    label: 'Comment Di-Resolve',
    icon: mdiCheckCircle,
    target: 'resolved_at ',
  },
  {
    id: null,
    label: 'Berdasarkan Prioritas',
    icon: mdiFormatListBulleted,
    target: 'lexorank',
  },
]
