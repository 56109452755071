<template>
  <div>
    <ckeditor
      :id="id"
      v-model="editorData"
      :editor="editor"
      :config="editorConfig"
      :disabled="readOnly"
      @ready="onEditorReady"
    />

    <v-menu
      v-model="mentionMenu"
      :position-x="menuX"
      :position-y="menuY"
      absolute
    >
      <v-card width="400">
        <user-team-selector
          v-if="mentionMenu"
          @select="handleSelectMention"
        />
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { MentionCustomization } from '@/components/ckeditor/extensions/mentionHelper'
import UserTeamSelector from '@/components/inputs/user-team-selector/UserTeamSelector.vue'
import store from '@/store'
import { Alignment } from '@ckeditor/ckeditor5-alignment'
import { Autoformat } from '@ckeditor/ckeditor5-autoformat'
import {
  Bold,
  Code,
  Italic,
  Strikethrough,
  Subscript,
  Superscript,
  Underline,
} from '@ckeditor/ckeditor5-basic-styles'
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote'
import { InlineEditor } from '@ckeditor/ckeditor5-editor-inline'
import { Essentials } from '@ckeditor/ckeditor5-essentials'
import { Font } from '@ckeditor/ckeditor5-font'
import { Highlight } from '@ckeditor/ckeditor5-highlight'
import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line'
import {
  AutoImage,
  Image,
  ImageInsert,
  ImageResizeButtons,
  ImageResizeEditing,
  ImageResizeHandles,
  ImageStyle,
  ImageToolbar,
} from '@ckeditor/ckeditor5-image'
import { Indent } from '@ckeditor/ckeditor5-indent'
import { Link } from '@ckeditor/ckeditor5-link'
import { List, TodoList } from '@ckeditor/ckeditor5-list'
import { MediaEmbed } from '@ckeditor/ckeditor5-media-embed'
import { Mention } from '@ckeditor/ckeditor5-mention'
import { PageBreak } from '@ckeditor/ckeditor5-page-break'
import { Paragraph } from '@ckeditor/ckeditor5-paragraph'
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office'
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format'
import { StandardEditingMode } from '@ckeditor/ckeditor5-restricted-editing'
import {
  SpecialCharacters,
  SpecialCharactersEssentials,
} from '@ckeditor/ckeditor5-special-characters'
import { Table } from '@ckeditor/ckeditor5-table'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import { WordCount } from '@ckeditor/ckeditor5-word-count'
import { ref, watch } from 'vue'

export default {
  name: 'CkeditorField',
  components: {
    ckeditor: CKEditor.component,
    UserTeamSelector,
  },
  props: {
    id: {
      type: String,
      default: 'comment',
    },
    content: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Input teks...',
    },
    readOnly: {
      type: Boolean,
      default: true,
    },
    ready: {
      type: Function,
      default: () => {},
    },
  },

  setup() {
    const editorInstance = ref()
    const attachMenu = ref(false)
    const selectedUser = ref([])
    const mentionMenu = ref(false)
    const menuX = ref(0)
    const menuY = ref(0)

    const onEditorReady = editor => {
      editorInstance.value = editor

      editor.keystrokes.set('Shift+2', data => {
        const { x, y, width } = data.domTarget.getBoundingClientRect()

        menuX.value = x + width - 400 - 1
        menuY.value = y + 1
        mentionMenu.value = true

        setTimeout(() => {
          document.querySelector('.v-menu__content input').focus()
        }, 500)
      })
    }

    const closeDialog = () => {
      editorInstance.value.commands.get('uppyUpload').execute(false)
      attachMenu.value = false
    }

    const handleSelectMention = mentions => {
      const editor = editorInstance.value
      const { focus } = editor.model.document.selection
      const workspaceSlug = store.getters.getCurrentWorkspace.identifier_id
      const mentionLength = selectedUser.value.reduce((acc, item) => acc + item.name.length + 2, 0)
      const shiftedLength = mentionLength ? 0 - mentionLength : -1

      if (!mentions.length && selectedUser.value.length === 1) {
        editor.model.change(writer => {
          const range = editor.model.createRange(focus.getShiftedBy(shiftedLength), focus)
          writer.remove(range)
        })
      }

      mentions.forEach((item, index) => {
        editor.execute('mention', {
          marker: '@',
          mention: {
            id: `@${item.name}`,
            name: item.name,
            link: `/${workspaceSlug}/users/detail/${item.id}`,
            userId: item.id,
          },
          range:
            index === 0 ? editor.model.createRange(focus.getShiftedBy(shiftedLength), focus) : null,
        })
      })

      selectedUser.value = mentions.length ? mentions : selectedUser.value
    }

    watch(mentionMenu, () => {
      if (!mentionMenu.value) {
        selectedUser.value = []
      }
    })

    const focusEditor = () => {
      editorInstance.value.focus()
    }

    return {
      attachMenu,
      closeDialog,
      focusEditor,
      handleSelectMention,
      mentionMenu,
      menuX,
      menuY,
      onEditorReady,
    }
  },
  data() {
    return {
      editor: InlineEditor,
      editorConfig: {
        placeholder: this.placeholder,
        plugins: [
          Essentials,
          Bold,
          Italic,
          Link,
          Paragraph,
          Alignment,
          Autoformat,
          BlockQuote,
          Font,
          Highlight,
          HorizontalLine,
          Image,
          Indent,
          List,
          PageBreak,
          PasteFromOffice,
          RemoveFormat,
          StandardEditingMode,
          SpecialCharacters,
          SpecialCharactersEssentials,
          Table,
          WordCount,
          Strikethrough,
          Underline,
          Subscript,
          Superscript,
          Code,
          TodoList,
          AutoImage,
          MediaEmbed,
          ImageInsert,
          ImageResizeEditing,
          ImageResizeHandles,
          ImageToolbar,
          ImageResizeButtons,
          ImageStyle,
          Mention,
          MentionCustomization,
        ],
        toolbar: {
          items: [
            {
              label: 'Fonts',
              icon: 'text',
              items: ['fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor'],
            },
            'highlight',
            'bold',
            'italic',
            'underline',
            {
              label: 'Special Format',
              icon: 'paragraph',
              items: ['strikethrough', 'subscript', 'superscript', 'code', 'blockQuote'],
            },
            '|',
            'alignment',
            {
              label: 'Lists',
              icon: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 570 570">
                     <path d="M64 144a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM192 64c-17.7 0-32 14.3-32 
                     32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 
                     14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 
                     0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM64 
                     464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48-208a48 48 0 1 0 -96 0 48 48 0 1 0 96 0z"/>
                     </svg>`,
              items: ['bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'],
            },
            '|',
            'undo',
            'redo',
            'link',
            'insertTable',
            'mediaEmbed',
            '|',
            'removeFormat',
            'specialCharacters',
            'horizontalLine',
            '|',
          ],
        },
        image: {
          styles: {
            options: [
              {
                name: 'alignLeft',
                title: 'Left aligned image',
              },
              {
                name: 'alignRight',
                title: 'Right aligned image',
              },
              {
                name: 'alignCenter',
                title: 'Centered image',
              },
              {
                name: 'alignBlockLeft',
                title: 'Left aligned image',
              },
              {
                name: 'alignBlockRight',
                title: 'Right aligned image',
              },
            ],
          },
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: null,
              icon: 'original',
            },
            {
              name: 'resizeImage:25',
              value: '25',
              icon: 'small',
            },
            {
              name: 'resizeImage:50',
              value: '50',
              icon: 'medium',
            },
            {
              name: 'resizeImage:75',
              value: '75',
              icon: 'large',
            },
          ],
          toolbar: [
            {
              name: 'imageStyle:icons',
              title: 'Wrap Text',
              items: ['imageStyle:alignLeft', 'imageStyle:alignRight'],
              defaultItem: 'imageStyle:alignLeft',
            },
            {
              name: 'imageStyle:pictures',
              title: 'Break Text',
              items: [
                'imageStyle:alignBlockLeft',
                'imageStyle:alignCenter',
                'imageStyle:alignBlockRight',
              ],
              defaultItem: 'imageStyle:alignCenter',
            },
            'resizeImage',
            '|',
            'viewer',
          ],
        },
        ...this.configs,
      },
    }
  },
  computed: {
    editorData: {
      get() {
        return this.content
      },
      set(value) {
        this.$emit('update:content', value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/styles/ckeditor-lark.scss';
@import '~@core/preset/preset/mixins.scss';

.ck-content {
  @include style-scroll-bar();
}

.ck-read-only {
  background-color: inherit !important;
  color: map-deep-get($material-light, 'text', 'primary') !important;
  border: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .dark-layout & {
    background-color: inherit !important;
    color: map-deep-get($material-dark, 'text', 'primary') !important;
  }
}

.ck-editor__editable_inline {
  max-height: 400px;
}
</style>
