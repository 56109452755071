<template>
  <v-row
    no-gutters
    dense
    class="w-full h-full"
  >
    <!-- Action Type – Job -->
    <template v-if="[1, 2, 3].includes(actionType)">
      <template v-if="actionType === 1">
        <v-col
          class="px-1 pb-2"
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="localFolder"
            class="text-truncate"
            solo
            flat
            dense
            outlined
            return-object
            auto-select-first
            item-value="id"
            item-text="name"
            hide-details="auto"
            placeholder="Pilih Folder"
            :rules="[required]"
            :items="folderList"
          >
            <template #selection="{ item }">
              <div class="d-flex align-center py-3 px-1 overflow-hidden">
                <v-icon
                  :color="item.color"
                  class="mx-1"
                  size="18"
                >
                  {{ item.is_public ? icons.mdiFolder : icons.mdiFolderLock }}
                </v-icon>

                <span class="text-truncate">{{ item.name }}</span>
              </div>
            </template>

            <template #item="{ item }">
              <div class="d-flex align-center py-3 px-1 w-full">
                <v-icon
                  :color="item.color"
                  class="mx-1"
                  size="18"
                >
                  {{ item.is_public ? icons.mdiFolder : icons.mdiFolderLock }}
                </v-icon>

                <span class="text-truncate">{{ item.name }}</span>
              </div>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col
          class="px-1 pb-2"
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="localJobType"
            class="text-truncate"
            solo
            flat
            dense
            outlined
            return-object
            auto-select-first
            height="100%"
            item-value="id"
            item-text="name"
            hide-details="auto"
            placeholder="Pilih Job Type"
            :rules="[required]"
            :items="jobTypeList"
          >
            <template #selection="{ item }">
              <div class="d-flex align-center py-3 px-1 overflow-hidden">
                <v-badge
                  class="mr-1"
                  dot
                  inline
                  :color="item.color"
                />

                <v-icon
                  v-if="!item.is_public"
                  size="14"
                  class="mr-1"
                >
                  {{ icons.mdiLockOutline }}
                </v-icon>

                <span class="text-truncate">{{ item.name }}</span>
              </div>
            </template>

            <template #item="{ item }">
              <div class="d-flex align-center py-3 px-1 w-full">
                <v-badge
                  class="mr-1"
                  dot
                  inline
                  :color="item.color"
                />

                <v-icon
                  v-if="!item.is_public"
                  size="14"
                  class="mr-1"
                >
                  {{ icons.mdiLockOutline }}
                </v-icon>

                <span class="text-truncate">{{ item.name }}</span>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
      </template>

      <template v-if="selectedField.includes('name')">
        <template v-if="actionType === 1">
          <v-col
            v-if="localValue.nameByTrigger"
            class="pb-2 px-1"
            cols="12"
          >
            <span
              class="text-subtitle-2 d-flex"
              style="opacity: 0.75"
            >
              <v-icon
                class="mr-1"
                size="16"
                style="margin-top: 2.5px"
              >
                {{ icons.mdiInformationOutline }}
              </v-icon>
              Nama Job yang akan dibuat akan mengikuti nama dari Job / Dokumen yang memicu Trigger
              Automation ini
            </span>
          </v-col>

          <v-col
            class="px-1 pb-2"
            cols="12"
            :md="localValue.nameByTrigger ? 12 : 3"
          >
            <v-chip
              class="d-flex flex-column align-stretch flex-fill pl-3 pr-1 overflow-hidden"
              style="border-radius: 6px; height: 40px"
              :color="localValue.nameByTrigger ? 'primary' : ''"
              @click="localValue.nameByTrigger = !localValue.nameByTrigger"
            >
              <v-icon
                v-if="localValue.nameByTrigger"
                size="20"
                style="color: inherit !important"
              >
                {{ icons.mdiPlayCircleOutline }}
              </v-icon>
              <v-icon
                v-else
                size="20"
                style="color: inherit !important"
              >
                {{ icons.mdiLabelOutline }}
              </v-icon>

              <span class="ml-2 text-subtitle-2 text-truncate">
                {{ localValue.nameByTrigger ? 'Nama Trigger Bawaan' : 'Nama Job Kustom' }}
              </span>
            </v-chip>
          </v-col>
        </template>

        <v-col
          v-show="!localValue.nameByTrigger"
          class="pb-2 px-1"
          cols="12"
          :md="actionType !== 1 ? 12 : 9"
        >
          <v-text-field
            v-model="localValue.data.name"
            class="flex-fill"
            placeholder="Masukan Nama Job"
            outlined
            dense
            hide-details="auto"
            :rules="[required]"
            :disabled="localValue.nameByTrigger"
          >
            <template #prepend-inner>
              <v-icon
                class="mx-1"
                size="20"
                style="margin-top: 1.5px"
              >
                {{ icons.mdiBriefcaseOutline }}
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </template>

      <v-col
        v-if="selectedField.includes('priority')"
        class="pb-md-0 px-1"
        cols="12"
      >
        <SingleSelectInput
          :value="localPriority"
          :items="jobTypeDetail?.priorities"
          @input="
            localPriority = $event
            localValue.data.job_priority_id = $event.id
          "
        >
          <template #activator="{ attrs, on, editMode }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              depressed
              outlined
              class="d-flex justify-start px-3 mb-2"
              minHeight="40px"
              style="
                border-radius: 5px;
                width: 100%;
                border: solid 1px var(--color-foreground-high);
              "
              @click="editMode = true"
            >
              <v-icon
                class="ml-1 mr-2"
                size="20"
                style="opacity: 0.8"
              >
                {{ icons.mdiFlagOutline }}
              </v-icon>

              <v-chip
                v-if="localPriority"
                class="mx-1"
                v-on="on"
                v-bind="attrs"
                x-small
                label
                style="cursor: pointer"
                :color="localPriority.color"
                @click="editMode = true"
              >
                {{ localPriority.name }}
              </v-chip>
              <span
                v-else
                class="font-weight-regular"
                style="opacity: 0.6"
              >
                Pilih Prioritas
              </span>
            </v-btn>
          </template>
        </SingleSelectInput>
      </v-col>

      <v-col
        v-if="selectedField.includes('status')"
        class="px-md-1"
        cols="12"
      >
        <SingleSelectInput
          :value="localStatus"
          :items="jobTypeDetail?.statuses"
          @input="
            localStatus = $event
            localValue.data.job_status_id = $event.id
          "
        >
          <template #activator="{ attrs, on, editMode }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              depressed
              outlined
              class="d-flex justify-start px-3 mb-2"
              minHeight="40px"
              style="
                border-radius: 5px;
                width: 100%;
                border: solid 1px var(--color-foreground-high);
              "
              @click="editMode = true"
            >
              <v-icon
                class="ml-1 mr-2"
                size="20"
                style="opacity: 0.8"
              >
                {{ icons.mdiInformationOutline }}
              </v-icon>

              <v-chip
                v-if="localStatus"
                class="mx-1"
                v-on="on"
                v-bind="attrs"
                x-small
                label
                style="cursor: pointer"
                :color="localStatus.color"
                @click="editMode = true"
              >
                {{ localStatus.name }}
              </v-chip>
              <span
                v-else
                class="font-weight-regular"
                style="opacity: 0.6"
              >
                Pilih Status
              </span>
            </v-btn>
          </template>
        </SingleSelectInput>
      </v-col>

      <v-col
        v-if="selectedField.includes('start_date')"
        class="px-md-1"
        cols="12"
      >
        <v-autocomplete
          v-model="localValue.data.start_date"
          class="mb-2"
          flat
          dense
          outlined
          auto-select-first
          hide-details="auto"
          placeholder="Pilih Tanggal mulai"
          item-text="label"
          item-value="id"
          :items="actionDateList"
        >
          <template #prepend-inner>
            <v-icon
              class="mx-1"
              size="20"
              style="margin-top: 1.5px"
            >
              {{ icons.mdiCalendarStartOutline }}
            </v-icon>
          </template>

          <template #item="{ item }">
            <div class="d-flex align-center flex-grow-1 py-3">
              <v-icon
                class="ml-1 mr-2"
                size="20"
                style="opacity: 0.8"
              >
                {{ item.icon }}
              </v-icon>

              <span class="subtitle-2"> {{ item.label }} </span>
            </div>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col
        v-if="selectedField.includes('expected_close_date')"
        class="px-md-1"
        cols="12"
      >
        <v-autocomplete
          v-model="localValue.data.expected_close_date"
          class="mb-2"
          flat
          dense
          outlined
          auto-select-first
          hide-details="auto"
          placeholder="Pilih Tanggal perkiraan selesai"
          item-text="label"
          item-value="id"
          :items="actionDateList"
        >
          <template #prepend-inner>
            <v-icon
              class="mx-1"
              size="20"
              style="margin-top: 1.5px"
            >
              {{ icons.mdiCalendarCheckOutline }}
            </v-icon>
          </template>

          <template #item="{ item }">
            <div class="d-flex align-center flex-grow-1 py-3">
              <v-icon
                class="ml-1 mr-2"
                size="20"
                style="opacity: 0.8"
              >
                {{ item.icon }}
              </v-icon>

              <span class="subtitle-2"> {{ item.label }} </span>
            </div>
          </template>
        </v-autocomplete>
      </v-col>

      <template v-if="selectedField.includes('assign_user_id')">
        <v-col
          class="px-1"
          cols="4"
        >
          <v-autocomplete
            v-model="localValueAssigneeAction_Type"
            flat
            dense
            outlined
            auto-select-first
            hide-details="auto"
            placeholder="Pilih Aksi Assign"
            item-text="label"
            item-value="id"
            :rules="[required]"
            :items="actionAssignList"
          >
            <template #selection="{ item }">
              <v-icon
                class="ml-1 mr-2"
                size="20"
                style="opacity: 0.8"
              >
                {{ item.icon }}
              </v-icon>

              {{ item.label }}
            </template>

            <template #item="{ item }">
              <div class="d-flex align-center flex-grow-1 py-3">
                <v-icon
                  class="ml-1 mr-2"
                  size="20"
                  style="opacity: 0.8"
                >
                  {{ item.icon }}
                </v-icon>

                <span class="subtitle-2"> {{ item.label }} </span>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          class="px-1"
          cols="8"
        >
          <v-menu
            @input="handleUserAssigneeSelector()"
            :close-on-content-click="false"
            style="z-index: 206"
          >
            <template #activator="{ attrs, on }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                depressed
                class="d-flex justify-start px-3 mb-2"
                minHeight="40px"
                style="
                  border-radius: 5px;
                  width: 100%;
                  height: 40px !important;
                  border: solid 1px var(--color-foreground-high);
                "
              >
                <v-icon
                  class="ml-1 mr-2"
                  size="20"
                  style="opacity: 0.8"
                >
                  {{ icons.mdiClipboardAccountOutline }}
                </v-icon>

                <UserLimitElements
                  v-if="
                    localValue.assign?.assign_user_id &&
                    localValue.assign?.assign_user_id.length > 1
                  "
                  :users="
                    usersList.reduce((acc, user) => {
                      if (localValueAssignee.includes(user.id)) {
                        acc.push({ user })
                      }
                      return acc
                    }, [])
                  "
                  :size="26"
                />

                <span
                  v-else
                  class="font-weight-regular"
                  style="opacity: 0.6"
                >
                  Tidak Memiliki Assignee
                </span>
              </v-btn>
            </template>

            <UserTeamSelector
              v-if="openAssignee"
              v-model="localValueAssignee"
              width="100%"
              object-type="job-type"
              :object-id="jobTypeDetail.id"
            />
          </v-menu>
        </v-col>
      </template>

      <v-col
        class="px-1"
        cols="12"
      >
        <v-menu
          bottom
          center
          offset-y
        >
          <template #activator="{ on, attrs }">
            <v-btn
              class="v-card v-sheet--outlined"
              depressed
              outlined
              style="
                border-radius: 5px;
                width: 100%;
                height: 40px !important;
                border: solid 1px var(--color-foreground-high);
              "
              v-bind="attrs"
              v-on="on"
            >
              <h4
                class="font-medium pl-2"
                style="color: inherit !important"
              >
                Choose Job Attributes
              </h4>
            </v-btn>
          </template>

          <v-card
            outlined
            class="d-flex flex-column pa-2"
          >
            <v-btn
              v-for="(option, index) in actionFieldList"
              :key="`automation_option_action_list${index}`"
              class="mb-2 px-0"
              depressed
              :disabled="['name', 'priority', 'status'].includes(option.name) && actionType === 1"
              :text="selectedField.includes(option.name) ? false : true"
              :color="selectedField.includes(option.name) ? 'primary' : ''"
              style="border-radius: 5px; min-width: 40px !important; height: 40px !important"
              @click="
                selectedField.includes(option.name)
                  ? (selectedField = selectedField.filter(item => item !== option.name))
                  : selectedField.push(option.name)
              "
            >
              <v-icon
                class="mx-2"
                size="20"
              >
                {{ option.icon }}
              </v-icon>

              <h4
                class="font-medium mr-auto"
                style="color: inherit !important"
              >
                {{ option.label }}
              </h4>
            </v-btn>
          </v-card>
        </v-menu>
      </v-col>

      <v-col
        class="my-3"
        cols="12"
      >
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12">
        <template v-if="localValue?.custom_attribute && localValue?.custom_attribute?.length > 0">
          <div
            v-for="(attribute, index) in localValue?.custom_attribute"
            :key="`action-custom_attribute_${index}`"
            class="d-flex"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    depressed
                    class="mr-2 px-0"
                    :color="hover ? 'error' : undefined"
                    style="
                      border-radius: 5px;
                      min-width: 40px !important;
                      height: 40px !important;
                      border: solid 1px var(--color-foreground-high);
                    "
                    @click="localValue.custom_attribute.splice(index, 1)"
                  >
                    <v-icon size="22">
                      {{ icons.mdiDelete }}
                    </v-icon>
                  </v-btn>
                </v-hover>
              </template>

              <span>Hapus Custom Attribute</span>
            </v-tooltip>

            <v-row
              class="d-flex mb-2"
              no-gutters
              dense
            >
              <v-col
                class="pr-md-1"
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="attribute.id"
                  flat
                  dense
                  outlined
                  auto-select-first
                  hide-details="auto"
                  placeholder="Pilih Custom Attribute"
                  item-text="name"
                  item-value="id"
                  :rules="[required]"
                  :items="
                    jobTypeDetail.custom_attributes.filter(item => {
                      const selectedIds = localValue.custom_attribute
                        .map(attr => attr.id)
                        .filter(id => id !== attribute.id)

                      return !selectedIds.includes(item.id)
                    })
                  "
                >
                  <template #selection>
                    <v-icon
                      class="ml-1 mr-2"
                      size="20"
                      style="opacity: 0.8"
                    >
                      {{ handleCustomAttributeSelector(attribute.id)?.icon }}
                    </v-icon>

                    {{
                      jobTypeDetail.custom_attributes.find(({ id }) => id === attribute.id)?.name
                    }}
                  </template>

                  <template #item="{ item }">
                    <div class="d-flex align-center flex-grow-1 py-3">
                      <v-icon
                        class="ml-1 mr-2"
                        size="20"
                        style="opacity: 0.8"
                      >
                        {{ customAttributeTypeMap[item.data_type.name]?.icon }}
                      </v-icon>

                      <span class="subtitle-2"> {{ item.name }} </span>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                class="pl-md-1"
                cols="12"
                md="8"
              >
                <AutomationCustomField
                  :value="attribute.value"
                  :property="`CA_${attribute.id}`"
                  :type="handleCustomAttributeSelector(attribute.id)?.type"
                  :icon="handleCustomAttributeSelector(attribute.id)?.icon"
                  @input="localValue.custom_attribute[index].value = $event"
                />
              </v-col>
            </v-row>
          </div>
        </template>

        <div
          v-else
          class="d-flex align-center justify-center"
          style="min-height: 75px"
        >
          <span class="w-full text-subtitle-2 font-weight-medium text-center">
            {{
              jobTypeDetail.custom_attributes.length > 0
                ? 'Tidak ada Custom Attribute yang akan digunakan'
                : 'Tidak ada Custom Attribute yang tersedia'
            }}
          </span>
        </div>

        <v-btn
          v-if="jobTypeDetail.custom_attributes.length > 0"
          class="mt-2 v-card v-sheet--outlined"
          depressed
          outlined
          :disabled="
            localValue.custom_attribute?.length === jobTypeDetail.custom_attributes?.length
          "
          style="border-radius: 5px; width: 100%"
          @click="handleAddCustomAttribute()"
        >
          <h4 class="font-medium pl-2">Add Custom Attribute</h4>
        </v-btn>
      </v-col>
    </template>

    <!-- Action Type – Document -->
    <template v-else-if="[4, 5, 6].includes(actionType)"></template>
  </v-row>
</template>

<script>
import { required } from '@/@core/utils/validation'
import SingleSelectInput from '@/components/inputs/editable/SingleSelectInput.vue'
import UserTeamSelector from '@/components/inputs/user-team-selector/UserTeamSelector.vue'
import UserLimitElements from '@/components/misc/UserLimitElements.vue'
import {
  actionAssignList,
  actionDateList,
  actionFieldList,
  customAttributeTypeMap,
} from '@/constants/automation'
import dateFormat from '@/utils/dateFormat'
import {
  mdiBriefcaseOutline,
  mdiCalendarCheckOutline,
  mdiCalendarStartOutline,
  mdiClipboardAccountOutline,
  mdiDelete,
  mdiFlagOutline,
  mdiInformationOutline,
  mdiLabelOutline,
  mdiPlayCircleOutline,
  mdiFolder,
  mdiFolderLock,
} from '@mdi/js'
import { computed, inject, onBeforeMount, ref, watch } from 'vue'
import AutomationCustomField from './AutomationCustomField.vue'

export default {
  components: {
    AutomationCustomField,
    SingleSelectInput,
    UserLimitElements,
    UserTeamSelector,
  },

  props: {
    to_do: {
      required: true,
    },

    actionType: {
      require: true,
      type: Number,
    },
  },

  setup(props, { emit }) {
    const folderDetail = inject('folderDetail')
    const folderList = inject('folderList')
    const jobTypeDetail = inject('jobTypeDetail')
    const jobTypeList = inject('jobTypeList')
    const usersList = inject('usersList')
    const localValue = ref(props.to_do)
    const localPriority = ref()
    const localStatus = ref()
    const localFolder = ref(folderDetail)
    const localJobType = ref(jobTypeDetail)

    const selectedField = ref([])

    const openAssignee = ref(false)
    const localValueAssignee = ref(
      localValue.value?.assign?.assign_user_id
        ? localValue.value?.assign.assign_user_id.slice(1)
        : [],
    )
    const localValueAssigneeAction_Type = computed({
      get() {
        return localValue.value?.assign?.assign_user_id[0] || 0
      },
      set(value) {
        if (localValue.value && localValue.value.assign) {
          localValue.value.assign.assign_user_id[0] = value
        }
      },
    })

    const determineActionType = () => {
      if ([1, 2, 3].includes(props.actionType)) return 'job'
      if (props.actionType === 4) return 'document-add'
      if (props.actionType === 6) return 'document-update'
    }

    const updateDataByActionType = () => {
      if (determineActionType() === 'job') {
        if (props.actionType === 1) selectedField.value = ['name', 'priority', 'status']

        localValue.value = {
          nameByTrigger: undefined,

          data: {
            name: undefined,
            job_priority_id: undefined,
            job_status_id: undefined,
            job_type_id: localFolder.value?.id,
            start_date: undefined,
            expected_close_date: undefined,
          },

          assign: undefined,
          // assign: {
          //   assign_user_id: [
          //     // ini merupakan action yang ingin dilakukan (Replace, Add, Remove, Toggle)
          //     // index "0" dari assign_user_id akan dianggap sebagai operator.
          //     0,
          //   ],
          // },

          custom_attribute: undefined,
        }
      }
    }

    const handleUserAssigneeSelector = () => {
      openAssignee.value = !openAssignee.value

      if (!localValue.value.assign?.assign_user_id?.length > 0) return

      if (!localValue.value.assign?.assign_user_id)
        localValue.value.assign = {
          assign_user_id: [0],
        }

      // Get the part of assign_user_id after the operator
      const assignUserIds = localValue.value.assign.assign_user_id.slice(1)

      // If there's a difference, update assign_user_id to match localValueAssignee
      if (
        assignUserIds.length !== localValueAssignee.value.length ||
        assignUserIds.some((id, index) => id !== localValueAssignee.value[index])
      ) {
        localValue.value.assign.assign_user_id = [
          localValue.value.assign.assign_user_id[0], // Keep the operator intact
          ...localValueAssignee.value, // Update the rest with localValueAssignee
        ]
      }
    }

    const handleAddCustomAttribute = () => {
      if (!Array.isArray(localValue.value.custom_attribute)) {
        localValue.value.custom_attribute = []
      }

      // Get the filtered list of custom attributes excluding already selected ones
      const selectedIds = localValue.value.custom_attribute.map(attr => attr.id)

      const availableAttributes = jobTypeDetail.value.custom_attributes.filter(item => {
        return !selectedIds.includes(item.id) // Only include unselected attributes
      })

      // If there are available attributes, push the next available one
      if (availableAttributes.length > 0) {
        const nextAttribute = availableAttributes[0] // You can choose the logic for selection
        localValue.value.custom_attribute.push({
          id: nextAttribute.id,
          value: null,
        })
      }
    }

    const handleCustomAttributeSelector = attributeId =>
      customAttributeTypeMap[
        jobTypeDetail.value.custom_attributes.find(({ id }) => id === attributeId)?.data_type?.name
      ]

    onBeforeMount(updateDataByActionType)

    watch([folderDetail, jobTypeDetail], () => {
      localValue.value = undefined
      emit('input', undefined)

      updateDataByActionType()
    })

    watch(
      () => props.actionType,
      () => {
        localValue.value = undefined
        emit('input', undefined)

        updateDataByActionType()
      },
    )

    watch(
      localValue,
      () => {
        emit('input', localValue.value)
      },
      {
        deep: true,
      },
    )

    watch(
      selectedField,
      () => {
        // Add fields if they're selected
        if (selectedField.value.includes('assign_user_id'))
          localValue.value.assign = {
            assign_user_id: [0],
          }

        // Set fields to undefined if they're not selected
        actionFieldList.forEach(field => {
          if (!selectedField.value.includes(field.name)) {
            if (field.name in localValue.value.data) localValue.value.data[field.name] = undefined
            else if (field.name === 'assign_user_id') {
              // Reset to initial state
              localValueAssignee.value = []
              localValue.value.assign = {}
            }
          }
        })
      },
      { deep: true },
    )

    return {
      folderDetail,
      folderList,
      jobTypeDetail,
      jobTypeList,
      usersList,
      localValue,
      localPriority,
      localStatus,
      localFolder,
      localJobType,
      selectedField,
      openAssignee,
      localValueAssignee,
      localValueAssigneeAction_Type,

      customAttributeTypeMap,
      actionFieldList,
      actionDateList,
      actionAssignList,

      handleAddCustomAttribute,
      handleCustomAttributeSelector,
      handleUserAssigneeSelector,

      dateFormat,
      required,

      icons: {
        mdiBriefcaseOutline,
        mdiFlagOutline,
        mdiInformationOutline,
        mdiPlayCircleOutline,
        mdiLabelOutline,
        mdiCalendarStartOutline,
        mdiCalendarCheckOutline,
        mdiDelete,
        mdiClipboardAccountOutline,
        mdiFolder,
        mdiFolderLock,
      },
    }
  },
}
</script>
