import store from '@/store'
import { apolloClient } from '@/vue-apollo'
import { computed, ref } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import {
  usersTeams,
  teamHierarchy,
  teams,
  requestInviteCount as requestInviteCountQuery,
} from '@/graphql/queries'
import errorHandling from '@/utils/errorHandling'
import {
  removeFromTeam,
  requestInviteToTeam,
  joinTeam as joinTeamMutation,
  leaveTeam as leaveTeamMutation,
  deleteTeam as deleteTeamMutation,
  restoreTeam as restoreTeamMutation,
  archiveTeam as archiveTeamMutation,
  unarchiveTeam as unarchiveTeamMutation,
  updateTeamMember as updateTeamMemberMutation,
  changeTeamOwnership as changeTeamOwnershipMutation,
} from '@/graphql/mutations'
import { createFieldMapper } from 'vuex-use-fields'
import { sortOptions, orderOptions, filterOptions } from '@/constants/team'

const useFieldTeam = createFieldMapper({ getter: 'team/getField', setter: 'team/setField' })

const useTeam = () => {
  const workspaceId = store.getters.getCurrentWorkspaceId
  const loadingSearchUsersTeams = ref(false)
  const searchUsersTeamsResults = ref({
    users: [],
    teams: [],
  })
  const searchUsersTeams = useDebounceFn(
    async (search = null, visibilityId = 6, jobTypeId, success = () => {}) => {
      /* eslint-disable no-unneeded-ternary */
      loadingSearchUsersTeams.value = true
      await apolloClient
        .query({
          query: usersTeams,
          variables: {
            search,
            visibility_id: visibilityId ? visibilityId : 6,
            job_type_id: jobTypeId ? jobTypeId : null,
            workspace_id: workspaceId,
          },
          fetchPolicy: 'no-cache',
        })
        .then(result => {
          searchUsersTeamsResults.value = result.data.usersTeams
          loadingSearchUsersTeams.value = false
          success()
        })
        .catch(err => {
          errorHandling(err)
          loadingSearchUsersTeams.value = false
        })
    },
    500,
  )

  const {
    teams: teamList,
    loadingTeam,
    teamFilter,
    teamSort,
    teamSearch,
    teamPagination,
    requestInviteCount,
  } = {
    ...useFieldTeam([
      'teams',
      'loadingTeam',
      'teamFilter',
      'teamSort',
      'teamSearch',
      'teamPagination',
      'requestInviteCount',
    ]),
  }

  const sortCount = computed(() => teamSort.value.length)
  const filterCount = computed(() => teamFilter.value.length)

  const fetchTeams = async ({ search, sort, pagination, filter, user_id }) => {
    try {
      const { data } = await apolloClient.query({
        query: teams,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: workspaceId,
          pagination,
          filter,
          search,
          sort,
          userId: user_id,
        },
      })

      return data.teams
    } catch (err) {
      errorHandling(err)
      throw err
    }
  }
  const fetchTeamHiearchy = async ({ jobTypeId, folderId, documentId, teamId } = {}) => {
    try {
      const { data } = await apolloClient.query({
        query: teamHierarchy,
        variables: {
          team_id: teamId,
          folder_id: folderId,
          job_type_id: jobTypeId,
          document_uuid: documentId,
          workspace_id: workspaceId,
        },
        fetchPolicy: 'no-cache',
      })

      return data.teamHierarchy
    } catch (err) {
      errorHandling(err)
      throw err
    }
  }
  const fetchRequestInviteCount = async () => {
    try {
      const { data } = await apolloClient.query({
        query: requestInviteCountQuery,
        variables: {
          workspace_id: workspaceId,
        },
        fetchPolicy: 'no-cache',
      })

      return data.requestInviteCount
    } catch (err) {
      errorHandling(err)
      throw err
    }
  }

  const archiveTeam = async id => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: archiveTeamMutation,
        variables: {
          team_id: id,
          workspace_id: workspaceId,
        },
      })

      return data.archiveTeam
    } catch (err) {
      errorHandling(err)
      throw err
    }
  }
  const unarchiveTeam = async id => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: unarchiveTeamMutation,
        variables: {
          team_id: id,
          workspace_id: workspaceId,
        },
      })

      return data.unarchiveTeam
    } catch (err) {
      errorHandling(err)
      throw err
    }
  }
  const deleteTeam = async id => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: deleteTeamMutation,
        variables: {
          team_id: id,
          workspace_id: workspaceId,
        },
      })

      return data.deleteTeam
    } catch (err) {
      errorHandling(err)
      throw err
    }
  }
  const joinTeam = async id => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: joinTeamMutation,
        variables: {
          team_id: id,
          workspace_id: workspaceId,
        },
      })

      return data.joinTeam
    } catch (err) {
      errorHandling(err)
      throw err
    }
  }
  const requestJoin = async id => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: requestInviteToTeam,
        variables: {
          team_id: id,
          workspace_id: workspaceId,
        },
      })

      return data.requestInviteToTeam
    } catch (err) {
      errorHandling(err)
      throw err
    }
  }
  const restoreTeam = async id => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: restoreTeamMutation,
        variables: {
          team_id: id,
          workspace_id: workspaceId,
        },
      })

      return data.restoreTeam
    } catch (err) {
      errorHandling(err)
      throw err
    }
  }

  const changeTeamOwnership = async ({ teamId, userId }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: changeTeamOwnershipMutation,
        variables: {
          team_id: teamId,
          user_id: userId,
          workspace_id: workspaceId,
        },
      })

      return data.changeTeamOwnership
    } catch (err) {
      errorHandling(err)
      throw err
    }
  }
  const updateTeamMember = async ({ teamId, isPublic, member }) => {
    const { data } = await apolloClient.mutate({
      mutation: updateTeamMemberMutation,
      variables: {
        member,
        team_id: teamId,
        is_public: isPublic,
        workspace_id: workspaceId,
      },
    })

    return data.updateTeamMember
  }
  const removeTeamMember = async ({ teamId, memberId }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: removeFromTeam,
        variables: {
          user_id: memberId,
          team_id: teamId,
          workspace_id: workspaceId,
        },
      })

      return data.updateTeamMember
    } catch (err) {
      errorHandling(err)
      throw err
    }
  }
  const leaveTeam = async id => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: leaveTeamMutation,
        variables: {
          team_id: id,
          workspace_id: workspaceId,
        },
      })

      return data.leaveTeam
    } catch (err) {
      errorHandling(err)
      throw err
    }
  }

  return {
    loadingSearchUsersTeams,
    searchUsersTeamsResults,
    searchUsersTeams,

    sortOptions,
    orderOptions,
    filterOptions,

    teamList,
    teamSort,
    teamFilter,
    teamSearch,
    teamPagination,
    requestInviteCount,

    fetchTeams,
    fetchTeamHiearchy,
    fetchRequestInviteCount,
    changeTeamOwnership,
    loadingTeam,

    sortCount,
    filterCount,

    joinTeam,
    leaveTeam,
    deleteTeam,
    requestJoin,
    restoreTeam,
    archiveTeam,
    unarchiveTeam,
    removeTeamMember,
    updateTeamMember,
  }
}

export default useTeam
