<template>
  <v-card
    :width="width"
    min-height="350px"
    class="user-selector-container elevation-0"
  >
    <v-text-field
      v-model="searchQuery"
      placeholder="Cari User"
      hide-details
      dense
      :outlined="false"
      class="py-2 px-3"
    >
    </v-text-field>

    <v-divider></v-divider>

    <v-card
      max-height="300"
      class="users-list"
    >
      <v-list dense>
        <v-list-item-group v-model="selectedUserId">
          <v-list-item
            v-for="user in filteredUsers"
            :key="user.id"
            :value="user.id"
            color="primary"
            :class="{
              'disabled-user': user.disabled,
            }"
            @click="!user.disabled && selectUserId(user.id)"
          >
            <v-list-item-avatar>
              <v-avatar
                size="30"
                class="mr-2 v-avatar-light-bg"
              >
                <v-img
                  v-if="user.photo"
                  :src="user.photo"
                />
                <span
                  v-else
                  class="text-caption font-medium"
                >
                  {{ avatarText(user.name) }}
                </span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <span>
                {{ user.name }}
                <span
                  v-if="user.currentUserLogin"
                  class="primary--text"
                >
                  (Anda)
                </span>
              </span>
            </v-list-item-content>
            <v-list-item-action>
              <div v-if="isInvitable(user)">
                <invite-permission
                  :object-type="objectType"
                  :object-detail="objectDetail"
                  :user-id="user.id"
                  @success="user.object_permission = $event"
                />
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-card>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import { mdiCloseCircle } from '@mdi/js'
import useUser from '@/composables/useUser'
import { ref, onMounted, computed } from 'vue'
import InvitePermission from './invite-permission/InvitePermission.vue'
import store from '@/store'

export default {
  components: {
    InvitePermission,
  },
  props: {
    width: {
      type: [Number, String],
      default: 400,
    },
    objectId: {
      type: Number,
      required: true,
    },
    objectType: {
      type: String,
      required: true,
      validator: value => ['document', 'job-type', 'folder'].includes(value),
    },
    assignUser: {
      type: Object,
      default: null,
    },
    selectAssignUser: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { fetchUsers, userList } = useUser()

    const searchQuery = ref('')
    const ownerId = ref()
    const usersList = ref([])

    const selectedUserId = ref(props.selectAssignUser || null)

    const isSelected = userId => selectedUserId.value === userId

    const isJoinedObject = permission => permission !== 'not_have_permission'
    const isCurrentUserOwner = computed(() => store.getters.getUserData.id === ownerId.value)

    const selectUserId = id => {
      selectedUserId.value = selectedUserId.value === id ? null : id
      emit('input', selectedUserId.value)
    }

    const fetchUsersList = async () => {
      if (props.objectType === 'job-type') {
        const users = await fetchUsers({ job_type_id: props.objectId })

        usersList.value = users.map(user => {
          if (props.assignUser) {
            selectUserId(props.assignUser.id)
          }

          return {
            id: user.id,
            name: user.name,
            email: user.email,
            photo: user.photo,
            is_object_owner: user.is_object_owner,
            availability: user.availability,
            currentUserLogin: store.getters.getUserData.id === user.id,
          }
        })
      } else if (props.objectType === 'document') {
        const users = await fetchUsers({ document_uuid: props.objectId })

        usersList.value = users.map(user => {
          if (user.is_object_owner) ownerId.value = user.id

          let permissionType = user.document_permissions?.find(
            el => el.document.uuid === props.objectId,
          )?.permission_type

          /* TOOD: find another way to handle this */
          // if (objectDetail.value.is_public || user.is_object_owner) permissionType = true
          if (permissionType === undefined) permissionType = 'not_have_permission'

          if (props.assignUser) {
            selectUserId(props.assignUser.id)
          }

          return {
            id: user.id,
            name: user.name,
            email: user.email,
            photo: user.photo,
            is_object_owner: user.is_object_owner,
            object_permission: permissionType,
            availability: user.availability,
            currentUserLogin: store.getters.getUserData.id === user.id,
            disabled:
              permissionType === 'not_have_permission' ||
              (!isJoinedObject(permissionType) && !user.is_object_owner),
          }
        })
      }
    }

    onMounted(() => {
      fetchUsersList()
    })

    const filteredUsers = computed(() => {
      if (!searchQuery.value.trim()) {
        return usersList.value
      }

      return usersList.value.filter(user =>
        user.name.toLowerCase().includes(searchQuery.value.trim().toLowerCase()),
      )
    })

    const isInvitable = user => {
      if (
        !isJoinedObject(user.object_permission) &&
        isCurrentUserOwner.value &&
        !user.is_object_owner
      ) {
        return true
      }

      return false
    }

    return {
      avatarText,

      userList,
      filteredUsers,
      searchQuery,

      isInvitable,
      selectUserId,
      isSelected,
      selectedUserId,

      icons: {
        mdiCloseCircle,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

.users-list {
  @include style-scroll-bar();
  overflow-y: auto;
}

.disabled-user {
  pointer-events: none;
}

.v-list-item__action {
  pointer-events: all;
}
</style>
