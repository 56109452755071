import gql from 'graphql-tag'

const referenceLogsByUser = gql`
  query ReferenceLogsByUser(
    $pagination: pagination!
    $createdBy: [Int!]!
    $workspaceId: Float!
    $categoryId: [Int!]
    $searchJobName: String
  ) {
    referenceLogsByUser(
      pagination: $pagination
      created_by: $createdBy
      workspace_id: $workspaceId
      category_id: $categoryId
      search_job_name: $searchJobName
    ) {
      count

      referenceLogs {
        id
        group_id
        category_id
        created_at

        job {
          id
          name
          job_type {
            id
            name
            color
            folder {
              id
              name
              color
            }
          }
        }

        custom_attribute {
          id
          name
          data_type {
            id
          }
        }

        created_by {
          id
          name
          photo
        }

        from
        to
      }
    }
  }
`

export default referenceLogsByUser
