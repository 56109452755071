var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pt-0 pb-5 px-5 d-flex flex-column flex-fill overflow-auto",staticStyle:{"max-height":"100%"},attrs:{"id":"user-view"}},[_c('v-row',{class:`${
      (_vm.isFullview && _vm.$vuetify.breakpoint.lg) || (_vm.isFullview && _vm.$vuetify.breakpoint.xl)
        ? 'overflow-hidden'
        : ''
    }`,attrs:{"dense":"","no-gutters":"","align-content":"start"}},[_c('v-col',{style:({
        height:
          (_vm.isFullview && _vm.$vuetify.breakpoint.lg) || (_vm.isFullview && _vm.$vuetify.breakpoint.xl)
            ? '100%'
            : 'unset',
        maxHeight: '100%',
      }),attrs:{"cols":"12","lg":_vm.isFullview ? 3 : 12}},[_c('div',{staticClass:"d-flex flex-column overflow-hidden h-full",staticStyle:{"max-height":"100%"}},[_c('v-card',{staticClass:"pa-4 mb-4 d-flex flex-column align-center justify-center",attrs:{"outlined":""}},[_c('v-avatar',{staticClass:"v-avatar-light-bg mb-2",attrs:{"size":"80"}},[_vm._v(" "+_vm._s(_vm.avatarText(`${_vm.userDetail.user.first_name} ${_vm.userDetail.user.last_name}`))+" ")]),_c('h6',{staticClass:"text-h6 font-weight-bold primary--text text-center"},[_vm._v(" "+_vm._s(_vm.userDetail.user.first_name)+" "+_vm._s(_vm.userDetail.user.last_name)+" ")]),_c('span',{staticClass:"text-subtitle-1 text-center"},[_vm._v(_vm._s(_vm.userDetail.user.email))]),_c('span',{staticClass:"text-subtitle-2 text-center"},[_vm._v(_vm._s(_vm.userDetail.user.phone))]),_c('div',{staticClass:"d-flex mt-3 mb-4"},[_c('v-chip',{staticClass:"mx-1 v-chip-light-bg primary--text",attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.userDetail.role.name)+" ")]),_c('v-chip',{staticClass:"mx-1",attrs:{"small":"","color":_vm.userStatusColor(_vm.userDetail.user.user_status.id)}},[_vm._v(" "+_vm._s(_vm.userDetail.user.user_status.name)+" ")])],1),_c('v-card',{staticClass:"pa-3 d-flex justify-space-between w-100 align-center",attrs:{"outlined":""}},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("Ketersediaan")]),_c('v-switch',{staticClass:"ma-0",attrs:{"value":_vm.userDetail.user.availability,"disabled":"","hide-details":"","dense":""}})],1)],1),_c('v-card',{class:`d-flex py-2 px-4 ${
            (_vm.isFullview && _vm.$vuetify.breakpoint.lg) || (_vm.isFullview && _vm.$vuetify.breakpoint.xl)
              ? 'overflow-hidden h-full'
              : 'mb-4'
          }`,attrs:{"outlined":""}},[(_vm.userTeams.length === 0)?[_c('v-alert',{staticClass:"mt-2 mb-2 d-flex align-center justify-center",attrs:{"elevation":"2","width":"100%"}},[_c('span',[_vm._v("Pengguna tidak bergabung ke dalam tim.")])])]:[_c('v-expansion-panels',{attrs:{"value":_vm.isFullview ? 0 : undefined,"readonly":_vm.isFullview}},[_c('v-expansion-panel',{key:"0",staticClass:"custom-panel v-card v-sheet--outlined",staticStyle:{"display":"flex","overflow":"hidden","max-height":"100%","flex-direction":"column"},attrs:{"outlined":""}},[_c('v-expansion-panel-header',{staticClass:"py-0 px-2"},[_c('span',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v("Tim")])]),_c('v-expansion-panel-content',{staticClass:"custom-panel-content overflow-auto mb-2",style:({
                    maxHeight:
                      !_vm.isFullview || _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                        ? '420px'
                        : '100%',
                  })},[_vm._l((_vm.userTeams),function(team){return [(!_vm.successLeaveTeam.includes(team.id))?_c('v-card',{key:team.id,staticClass:"px-4 py-3 d-flex flex-column mb-2",attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('router-link',{staticClass:"text-subtitle-2 primary--text pa-0 ma-0 text-truncate font-weight-semibold d-inline-block",staticStyle:{"max-width":"125px"},attrs:{"to":{
                            name: 'team-detail',
                            params: {
                              id: team.id,
                            },
                          }}},[(team.archived_at)?_c('v-icon',{staticClass:"ms-1",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiArchive)+" ")]):_vm._e(),_vm._v(" "+_vm._s(team.name)+" ")],1),_c('v-chip',{staticClass:"ml-2 px-3",staticStyle:{"height":"20px"},attrs:{"small":"","active":""}},[_vm._v(" "+_vm._s(team.users.length > 0 && team.users.filter(tu => tu.user.id == _vm.idUser)[0]?.permission_type)+" ")])],1),_c('div',{staticClass:"d-flex flex-column"},[(_vm.isJoinedTeam(team) && _vm.currentUserId === _vm.idUser)?[(team.is_public)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.successLeaveTeam.includes(team.id)),expression:"!successLeaveTeam.includes(team.id)"}],staticClass:"items-center w-full mt-3",staticStyle:{"min-width":"80px"},attrs:{"small":"","variant":"outlined","color":"error","loading":_vm.isLoadingTeam.includes(team.id)},on:{"click":function($event){return _vm.handleLeaveTeam({
                                team_id: team.id,
                              })}}},[_vm._v(" Leave Team ")]):_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.successLeaveTeam.includes(team.id)),expression:"!successLeaveTeam.includes(team.id)"}],staticClass:"items-center w-full mt-3",staticStyle:{"min-width":"80px"},attrs:{"small":"","variant":"outlined","color":"error","loading":_vm.isLoadingTeam.includes(team.id)},on:{"click":function($event){return _vm.handleLeaveTeam({
                                team_id: team.id,
                              })}}},[_vm._v(" Leave Team ")])]:_vm._e(),(!_vm.isJoinedTeam(team) || _vm.successLeaveTeam.includes(team.id))?[(team.is_public && !_vm.successJoinTeam.includes(team.id))?_c('v-btn',{staticClass:"items-center w-full mt-3",staticStyle:{"min-width":"80px"},attrs:{"small":"","color":"primary","loading":_vm.isLoadingTeam.includes(team.id)},on:{"click":function($event){return _vm.handleJoinTeam({
                                id: team.id,
                                isPublic: team.is_public,
                              })}}},[_vm._v(" Join ")]):(!_vm.successRequestJoinTeam.includes(team.id))?_c('v-btn',{staticClass:"items-center w-full mt-3",staticStyle:{"min-width":"80px"},attrs:{"small":"","color":"primary","loading":_vm.isLoadingTeam.includes(team.id)},on:{"click":function($event){return _vm.handleJoinTeam({
                                id: team.id,
                                isPublic: team.is_public,
                              })}}},[_vm._v(" Request Join ")]):(
                              _vm.successRequestJoinTeam.includes(team.id) || _vm.isRequestedTeam(team)
                            )?_c('v-btn',{staticClass:"items-center w-full mt-3",staticStyle:{"min-width":"80px"},attrs:{"small":"","color":"primary","loading":_vm.isLoadingTeam.includes(team.id)},on:{"click":function($event){return _vm.handleJoinTeam({
                                id: team.id,
                                isPublic: team.is_public,
                              })}}},[_vm._v(" Re-request Join ")]):_vm._e()]:_vm._e()],2)]):_vm._e()]})],2)],1)],1)]],2)],1)]),(!_vm.isLoading)?_c('v-col',{staticClass:"w-full",style:({
        height:
          (_vm.isFullview && _vm.$vuetify.breakpoint.lg) || (_vm.isFullview && _vm.$vuetify.breakpoint.xl)
            ? '100%'
            : 'unset',
        maxHeight:
          (_vm.isFullview && _vm.$vuetify.breakpoint.lg) || (_vm.isFullview && _vm.$vuetify.breakpoint.xl)
            ? '100%'
            : '500px',
      }),attrs:{"cols":"12","lg":_vm.isFullview ? 9 : 12}},[_c('v-card',{class:`pa-4 h-full d-flex flex-column flex-fill overflow-hidden ${
          (_vm.isFullview && _vm.$vuetify.breakpoint.lg) || (_vm.isFullview && _vm.$vuetify.breakpoint.xl)
            ? 'ml-4'
            : ''
        }`,attrs:{"outlined":""}},[_c('v-tabs',{staticClass:"user-tabs mb-3",staticStyle:{"flex":"0"},attrs:{"show-arrows":"","centered":""},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.label,staticClass:"w-full"},[_c('v-icon',{staticClass:"me-3",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',{staticClass:"text-capitalize text-subtitle-2"},[_vm._v(_vm._s(tab.label))])],1)}),1),_c('v-tabs-items',{staticClass:"flex-fill h-full overflow-hidden px-2",attrs:{"id":"user-tabs-content","touchless":""},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab-item',{staticClass:"h-full"},[_c('UserDetailReferenceLog',{on:{"handleScroll":_vm.handleReferenceLogsByUser}})],1),_c('v-tab-item',{staticClass:"h-full"},[_c('UserDetailTrackJob',{on:{"handleScrollJob":_vm.handleEachJobsAssignedUserPagination}})],1),_c('v-tab-item',{staticClass:"h-full"},[_c('UserDetailAssignedComment')],1)],1)],1)],1):_vm._e()],1),_c('user-target-form',{ref:"userTargetForm"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }