import gql from 'graphql-tag'

const notificationByObject = gql`
  query NotificationsByObject(
    $filter: filterNotification!
    $paginationv2: paginationV2!
    $objectType: String!
    $objectId: String!
    $workspaceId: Float!
  ) {
    notificationsByObject(
      filter: $filter
      paginationv2: $paginationv2
      object_type: $objectType
      object_id: $objectId
      workspace_id: $workspaceId
    ) {
      count
      notifications {
        id
        created_at

        read_at
        archived_at
        snoozed_at
        unsnoozed_at
        category_id

        static_data

        job {
          id
          name
          job_type {
            id
          }
        }

        document {
          uuid
          name
          last_edited_at
          last_edited_by {
            id
            name
            photo
          }
          deleted_at
          archived_at
        }

        referenceLog {
          id
          category_id
          created_at
          job {
            id
            name
            job_type {
              id
            }
          }
          custom_attribute {
            id
            name
            data_type {
              id
            }
          }
          created_by {
            id
            name
            photo
          }
          from_value
          to_value
        }

        comment {
          id
          comment
          strip_comment
          user {
            id
            phone
            email
            name
            first_name
            last_name
            photo
          }
          created_at
          updated_at
          deleted_at
          resolved_at
          lexorank
          assigned_user {
            id
            email
            name
            photo
          }

          parent {
            id
            comment
            strip_comment
            user {
              id
              phone
              email
              name
              first_name
              last_name
              photo
            }
            created_at
            updated_at
            deleted_at
            resolved_at
            lexorank
            assigned_user {
              id
              email
              name
              photo
            }
          }

          job {
            id
            name
            job_type {
              id
            }
          }

          attachment {
            id
            file_name
            file_type
          }
        }

        workspace {
          id
          name
        }

        team {
          id
          name
        }
      }
    }
  }
`

export default notificationByObject
