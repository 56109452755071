<template>
  <v-card min-width="300">
    <v-card-title class="pa-3">
      <div
        v-if="multiple"
        class="d-flex item-select-chip-group"
      >
        <template v-if="value.length">
          <v-chip
            v-for="item in value"
            :key="item.id"
            :color="item.color"
            x-small
            label
          >
            {{ item.name }}
          </v-chip>
        </template>
        <span
          v-else
          class="caption"
        >
          -
        </span>
      </div>
      <v-chip
        v-else-if="value"
        x-small
        label
        :key="value.id"
        :color="value.color"
      >
        {{ value.name }}
      </v-chip>
      <span
        v-else
        class="caption"
      >
        -
      </span>
    </v-card-title>

    <v-divider />

    <v-card-text class="pa-0">
      <v-list
        dense
        class="pt-0"
      >
        <v-subheader class="px-3">Pilih opsi item</v-subheader>
        <v-list-item-group
          v-if="items.length"
          v-model="localValue"
          :multiple="multiple"
          :value-comparator="(a, b) => a?.id === b?.id"
          @change="$emit('input', localValue)"
        >
          <v-list-item
            v-for="item in items"
            :key="item.id"
            class="px-1"
            :value="item"
            color="primary"
            dense
          >
            <v-list-item-content class="py-0 px-2">
              <v-list-item-title>
                <v-chip
                  :color="item.color"
                  small
                  label
                  style="cursor: pointer"
                >
                  {{ item.name }}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <span
          v-else
          class="caption px-3 text--disabled"
        >
          Tidak ada item yang tersedia
        </span>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
  value: {
    type: [Object, Array],
    default: null,
  },
  items: {
    type: Array,
    default: () => [],
  },
  multiple: {
    type: Boolean,
    default: false,
  },
})

const localValue = ref(props.value)
</script>

<style lang="scss">
.item-select-chip-group {
  .v-chip {
    &:not(:first-child) {
      margin-left: 2px;
    }
    &:not(:last-child) {
      margin-right: 2px;
    }
  }
}
</style>
