import gql from 'graphql-tag'

export const updateAutomation = gql`
  mutation UpdateAutomation(
    $action: [CreateActionParams!]!
    $trigger: [CreateTriggerParams!]!
    $automation: CreateAutomationParams!
    $workspaceId: Float!
    $automationId: Float!
  ) {
    updateAutomation(
      action: $action
      trigger: $trigger
      automation: $automation
      workspace_id: $workspaceId
      automation_id: $automationId
    ) {
      status
      msg
      data
    }
  }
`
