<template>
  <div class="w-full h-full">
    <!-- Property.type – String -->
    <v-text-field
      v-if="type === 'string'"
      v-model="localValue"
      class="flex-fill"
      placeholder="Masukan Nilai"
      outlined
      dense
      hide-details="auto"
      :rules="[required]"
      @input="$emit('input', localValue)"
    >
      <template #prepend-inner>
        <v-icon
          class="mx-1"
          size="20"
          style="margin-top: 1.5px"
        >
          {{ icon }}
        </v-icon>
      </template>
    </v-text-field>

    <!-- Property.type – Number -->
    <v-text-field
      v-else-if="type === 'integer'"
      v-model="localValue"
      class="flex-fill"
      placeholder="Masukan Nilai"
      type="number"
      outlined
      dense
      hide-details="auto"
      :rules="[required]"
      @input="$emit('input', localValue)"
    >
      <template #prepend-inner>
        <v-icon
          class="mx-1"
          size="20"
          style="margin-top: 1.5px"
        >
          {{ icon }}
        </v-icon>
      </template>
    </v-text-field>

    <!-- Property.type – Date -->
    <DateInput
      v-else-if="type === 'date'"
      :value="localValue || null"
      @input="
        localValue = $event
        $emit('input', $event)
      "
    >
      <template #activator="{ attrs, on, editMode }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          depressed
          outlined
          class="d-flex justify-start px-2 mb-1"
          style="border-radius: 5px; width: 100%; border: solid 1px var(--color-foreground-high)"
          @click="editMode = true"
        >
          <v-icon
            class="ml-1 mr-2"
            size="20"
          >
            {{ icon }}
          </v-icon>

          <span
            class="font-weight-regular"
            style="opacity: 0.6"
          >
            {{ localValue ? dateFormat(localValue, 3) : 'Pilih Tanggal' }}
          </span>
        </v-btn>
      </template>
    </DateInput>

    <!-- Property – Priority -->
    <SingleSelectInput
      v-else-if="type === 'priority'"
      :value="localValue"
      :items="jobTypeDetail?.priorities"
      @input="
        localValue = $event
        $emit('input', $event?.id)
      "
    >
      <template #activator="{ attrs, on, editMode }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          depressed
          outlined
          class="d-flex justify-start px-3 mb-0"
          minHeight="40px"
          style="border-radius: 5px; width: 100%; border: solid 1px var(--color-foreground-high)"
          @click="editMode = true"
        >
          <v-icon
            class="ml-1 mr-2"
            size="20"
            style="opacity: 0.8"
          >
            {{ icon }}
          </v-icon>

          <v-chip
            v-if="localValue"
            class="ml-1 mr-2"
            v-on="on"
            v-bind="attrs"
            x-small
            label
            style="cursor: pointer"
            :color="localValue.color"
            @click="editMode = true"
          >
            {{ localValue.name }}
          </v-chip>
          <span
            v-else
            class="font-weight-regular"
            style="opacity: 0.6"
          >
            Pilih Prioritas
          </span>
        </v-btn>
      </template>
    </SingleSelectInput>

    <!-- Property – Status -->
    <SingleSelectInput
      v-else-if="type === 'status'"
      :value="localValue"
      :items="jobTypeDetail?.statuses"
      @input="
        localValue = $event
        $emit('input', $event?.id)
      "
    >
      <template #activator="{ attrs, on, editMode }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          depressed
          outlined
          class="d-flex justify-start px-3 mb-0"
          minHeight="40px"
          style="border-radius: 5px; width: 100%; border: solid 1px var(--color-foreground-high)"
          @click="editMode = true"
        >
          <v-icon
            class="ml-1 mr-2"
            size="20"
            style="opacity: 0.8"
          >
            {{ icon }}
          </v-icon>

          <v-chip
            v-if="localValue"
            class="ml-1 mr-2"
            v-on="on"
            v-bind="attrs"
            x-small
            label
            style="cursor: pointer"
            :color="localValue.color"
            @click="editMode = true"
          >
            {{ localValue.name }}
          </v-chip>
          <span
            v-else
            class="font-weight-regular"
            style="opacity: 0.6"
          >
            Pilih Prioritas
          </span>
        </v-btn>
      </template>
    </SingleSelectInput>

    <!-- Property – Assign User -->
    <v-menu
      v-else-if="type === 'user'"
      @input="openInteraction = !openInteraction"
      :close-on-content-click="false"
      style="z-index: 206"
    >
      <template #activator="{ attrs, on }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          depressed
          class="d-flex justify-start px-3 mb-2"
          minHeight="40px"
          style="
            border-radius: 5px;
            width: 100%;
            height: 40px !important;
            border: solid 1px var(--color-foreground-high);
          "
        >
          <v-icon
            class="ml-1 mr-2"
            size="20"
            style="opacity: 0.8"
          >
            {{ icon }}
          </v-icon>

          <UserLimitElements
            v-if="localValue && localValue?.length > 0"
            :users="
              usersList.reduce((acc, user) => {
                if (localValue.includes(user.id)) {
                  acc.push({ user })
                }
                return acc
              }, [])
            "
            :size="26"
          />

          <span
            v-else
            class="font-weight-regular"
            style="opacity: 0.6"
          >
            Tidak Memiliki Assignee
          </span>
        </v-btn>
      </template>

      <UserTeamSelector
        v-if="openInteraction"
        v-model="localValue"
        width="100%"
        object-type="job-type"
        :object-id="jobTypeDetail.id"
        single-select
        @input="$emit('input', localValue)"
      />
    </v-menu>

    <!-- Property – Custom Attribute, Multiple -->
    <template v-else-if="type === 'multiple' || type === 'single'">
      <v-autocomplete
        v-model="localValue"
        flat
        dense.
        outlined
        auto-select-first
        hide-details="auto"
        placeholder="Masukan Pilihan"
        item-text="name"
        item-value="id"
        :multiple="type === 'multiple'"
        :rules="[required]"
        :items="localList"
        @input="$emit('input', localValue)"
      >
        <template #prepend-inner>
          <v-icon
            class="ml-1 mr-2"
            size="20"
            style="margin-top: 1.5px"
          >
            {{ icon }}
          </v-icon>
        </template>

        <template #selection="{ item }">
          <span>
            {{ item.name }}
          </span>
        </template>

        <template #item="{ item }">
          <div class="d-flex align-center flex-grow-1 py-3">
            <span class="subtitle-2"> {{ item.name }}</span>
          </div>
        </template>
      </v-autocomplete>
    </template>

    <div
      v-else
      class="d-flex align-center px-3"
      style="
        border-radius: 5px;
        min-height: 40px;
        width: 100%;
        border: solid 1px var(--color-foreground-high);
      "
    >
      <v-icon
        class="ml-1 mr-2"
        size="20"
        style="opacity: 0.8"
      >
        {{ icons.mdiAlertOutline }}
      </v-icon>

      <span
        class="font-weight-regular text--disabled"
        style="color: var(--ck-color-base-text)"
      >
        Tipe Data belum di implementasikan!
      </span>
    </div>
  </div>
</template>

<script>
import { required } from '@/@core/utils/validation'
import DateInput from '@/components/inputs/editable/DateInput.vue'
import SingleSelectInput from '@/components/inputs/editable/SingleSelectInput.vue'
import { attributePropertyMap } from '@/constants/automation'
import dateFormat from '@/utils/dateFormat'
import { mdiAlertOutline } from '@mdi/js'
import { inject, onMounted, ref, watch } from 'vue'
import UserTeamSelector from '@/components/inputs/user-team-selector/UserTeamSelector.vue'
import UserLimitElements from '@/components/misc/UserLimitElements.vue'

export default {
  components: {
    SingleSelectInput,
    DateInput,
    UserTeamSelector,
    UserLimitElements,
  },

  props: {
    value: {
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    property: {
      type: String,
    },
    operator: {
      type: Number,
    },
  },

  setup(props, { emit }) {
    const folderDetail = inject('folderDetail')
    const jobTypeDetail = inject('jobTypeDetail')
    const usersList = inject('usersList')
    const localValue = ref(props.value)
    const localList = ref()
    const openInteraction = ref(false)

    const findCorrespondingItems = () => {
      if (props.property?.startsWith('CA_'))
        return (localList.value = jobTypeDetail.value.custom_attributes.find(
          item => item.id == props.property.split('CA_')[1],
        )?.options)

      if (![props.value, props.property, props.operator].every(prop => prop !== undefined)) return

      if (props.property === 2) {
        if (props.operator === 3)
          return (localValue.value.values =
            jobTypeDetail?.priorities.filter(priority => priority.id === localValue.value) || [])

        localValue.value.values = jobTypeDetail?.priorities.find(
          priority => priority.id === localValue.value || priority.id === localValue.value[0],
        )
      }

      if (props.property === 3) {
        if (props.operator === 3)
          return (localValue.value.values =
            jobTypeDetail?.statuses.filter(status => status.id === localValue.value) || [])

        localValue.value.values = jobTypeDetail?.statuses.find(
          status => status.id === localValue.value || status.id === localValue.value[0],
        )
      }
    }

    const handleUserAssigneeSelector = () => {
      if (!localValue.value.assign) return

      // Get the part of assign_user_id after the operator
      const assignUserIds = localValue.value.assign.assign_user_id.slice(1)

      // If there's a difference, update assign_user_id to match localValueAssignee
      if (
        assignUserIds.length !== localValue.value.length ||
        assignUserIds.some((id, index) => id !== localValue.value[index])
      ) {
        localValue.value.assign.assign_user_id = [
          localValue.value.assign.assign_user_id[0], // Keep the operator intact
          ...localValue.value, // Update the rest with localValueAssignee
        ]
      }
    }

    onMounted(findCorrespondingItems)

    watch([folderDetail, jobTypeDetail], () => {
      localValue.value = undefined
      emit('input', undefined)
    })

    watch([() => props.operator, () => props.property], () => {
      localValue.value = undefined
      emit('input', undefined)
    })

    return {
      folderDetail,
      jobTypeDetail,
      usersList,
      localValue,
      localList,

      openInteraction,
      handleUserAssigneeSelector,

      attributePropertyMap,

      dateFormat,
      required,

      icons: {
        mdiAlertOutline,
      },
    }
  },
}
</script>
