var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full"},[(_vm.type === 'string')?_c('v-text-field',{staticClass:"flex-fill",attrs:{"placeholder":"Masukan Nilai","outlined":"","dense":"","hide-details":"auto","rules":[_vm.required]},on:{"input":function($event){return _vm.$emit('input', _vm.localValue)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"mx-1",staticStyle:{"margin-top":"1.5px"},attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])]},proxy:true}],null,false,16913194),model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}}):(_vm.type === 'integer')?_c('v-text-field',{staticClass:"flex-fill",attrs:{"placeholder":"Masukan Nilai","type":"number","outlined":"","dense":"","hide-details":"auto","rules":[_vm.required]},on:{"input":function($event){return _vm.$emit('input', _vm.localValue)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"mx-1",staticStyle:{"margin-top":"1.5px"},attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])]},proxy:true}]),model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}}):(_vm.type === 'date')?_c('DateInput',{attrs:{"value":_vm.localValue || null},on:{"input":function($event){_vm.localValue = $event
      _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on, editMode }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex justify-start px-2 mb-1",staticStyle:{"border-radius":"5px","width":"100%","border":"solid 1px var(--color-foreground-high)"},attrs:{"depressed":"","outlined":""},on:{"click":function($event){editMode = true}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"ml-1 mr-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]),_c('span',{staticClass:"font-weight-regular",staticStyle:{"opacity":"0.6"}},[_vm._v(" "+_vm._s(_vm.localValue ? _vm.dateFormat(_vm.localValue, 3) : 'Pilih Tanggal')+" ")])],1)]}}])}):(_vm.type === 'priority')?_c('SingleSelectInput',{attrs:{"value":_vm.localValue,"items":_vm.jobTypeDetail?.priorities},on:{"input":function($event){_vm.localValue = $event
      _vm.$emit('input', $event?.id)}},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on, editMode }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex justify-start px-3 mb-0",staticStyle:{"border-radius":"5px","width":"100%","border":"solid 1px var(--color-foreground-high)"},attrs:{"depressed":"","outlined":"","minHeight":"40px"},on:{"click":function($event){editMode = true}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"ml-1 mr-2",staticStyle:{"opacity":"0.8"},attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]),(_vm.localValue)?_c('v-chip',_vm._g(_vm._b({staticClass:"ml-1 mr-2",staticStyle:{"cursor":"pointer"},attrs:{"x-small":"","label":"","color":_vm.localValue.color},on:{"click":function($event){editMode = true}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.localValue.name)+" ")]):_c('span',{staticClass:"font-weight-regular",staticStyle:{"opacity":"0.6"}},[_vm._v(" Pilih Prioritas ")])],1)]}}])}):(_vm.type === 'status')?_c('SingleSelectInput',{attrs:{"value":_vm.localValue,"items":_vm.jobTypeDetail?.statuses},on:{"input":function($event){_vm.localValue = $event
      _vm.$emit('input', $event?.id)}},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on, editMode }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex justify-start px-3 mb-0",staticStyle:{"border-radius":"5px","width":"100%","border":"solid 1px var(--color-foreground-high)"},attrs:{"depressed":"","outlined":"","minHeight":"40px"},on:{"click":function($event){editMode = true}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"ml-1 mr-2",staticStyle:{"opacity":"0.8"},attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]),(_vm.localValue)?_c('v-chip',_vm._g(_vm._b({staticClass:"ml-1 mr-2",staticStyle:{"cursor":"pointer"},attrs:{"x-small":"","label":"","color":_vm.localValue.color},on:{"click":function($event){editMode = true}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.localValue.name)+" ")]):_c('span',{staticClass:"font-weight-regular",staticStyle:{"opacity":"0.6"}},[_vm._v(" Pilih Prioritas ")])],1)]}}])}):(_vm.type === 'user')?_c('v-menu',{staticStyle:{"z-index":"206"},attrs:{"close-on-content-click":false},on:{"input":function($event){_vm.openInteraction = !_vm.openInteraction}},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex justify-start px-3 mb-2",staticStyle:{"border-radius":"5px","width":"100%","height":"40px !important","border":"solid 1px var(--color-foreground-high)"},attrs:{"depressed":"","minHeight":"40px"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"ml-1 mr-2",staticStyle:{"opacity":"0.8"},attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]),(_vm.localValue && _vm.localValue?.length > 0)?_c('UserLimitElements',{attrs:{"users":_vm.usersList.reduce((acc, user) => {
              if (_vm.localValue.includes(user.id)) {
                acc.push({ user })
              }
              return acc
            }, []),"size":26}}):_c('span',{staticClass:"font-weight-regular",staticStyle:{"opacity":"0.6"}},[_vm._v(" Tidak Memiliki Assignee ")])],1)]}}])},[(_vm.openInteraction)?_c('UserTeamSelector',{attrs:{"width":"100%","object-type":"job-type","object-id":_vm.jobTypeDetail.id,"single-select":""},on:{"input":function($event){return _vm.$emit('input', _vm.localValue)}},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}}):_vm._e()],1):(_vm.type === 'multiple' || _vm.type === 'single')?[_c('v-autocomplete',{attrs:{"flat":"","dense.":"","outlined":"","auto-select-first":"","hide-details":"auto","placeholder":"Masukan Pilihan","item-text":"name","item-value":"id","multiple":_vm.type === 'multiple',"rules":[_vm.required],"items":_vm.localList},on:{"input":function($event){return _vm.$emit('input', _vm.localValue)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"ml-1 mr-2",staticStyle:{"margin-top":"1.5px"},attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])]},proxy:true},{key:"selection",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center flex-grow-1 py-3"},[_c('span',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(item.name))])])]}}]),model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}})]:_c('div',{staticClass:"d-flex align-center px-3",staticStyle:{"border-radius":"5px","min-height":"40px","width":"100%","border":"solid 1px var(--color-foreground-high)"}},[_c('v-icon',{staticClass:"ml-1 mr-2",staticStyle:{"opacity":"0.8"},attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAlertOutline)+" ")]),_c('span',{staticClass:"font-weight-regular text--disabled",staticStyle:{"color":"var(--ck-color-base-text)"}},[_vm._v(" Tipe Data belum di implementasikan! ")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }