<template>
  <div class="notification-settings d-flex flex-column h-full">
    <div class="d-flex flex-column flex-fill px-5 py-3 overflow-auto">
      <v-card
        outlined
        class="d-flex flex-column mt-3"
      >
        <v-row class="d-flex align-center mx-0 my-0 py-2 px-2">
          <v-col
            cols="12"
            md="9"
            lg="10"
            class="d-flex align-center"
          >
            <v-icon
              class="mr-2"
              size="38"
            >
              {{ icons.mdiInformationSlabBoxOutline }}
            </v-icon>

            <div class="d-flex flex-column">
              <h3 class="text-subtitle-1 font-weight-semibold">Histori Notifikasi</h3>
              <p class="text-caption">
                <span
                  :class="`${notificationContext[notificationState.inAppOption].color}--text`"
                ></span>

                {{ notificationContext[notificationState.inAppOption].label }} –
                {{ notificationContext[notificationState.inAppOption].description }}
              </p>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="3"
            lg="2"
            class="d-flex align-center"
          >
            <v-menu
              bottom
              offset-y
            >
              <template #activator="{ attrs, on }">
                <v-btn
                  outlined
                  depressed
                  class="w-100 justify-center v-card v-sheet--outlined"
                  style="border-radius: 5px; box-shadow: none"
                  :loading="isLoading"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    size="18"
                    :color="notificationContext[notificationState.inAppOption].color"
                  >
                    {{ notificationContext[notificationState.inAppOption].icon }}
                  </v-icon>

                  <span class="text-subtitle-1 font-medium pl-2">
                    {{ notificationContext[notificationState.inAppOption].label }}
                  </span>
                </v-btn>
              </template>

              <v-card class="d-flex flex-column px-3 py-3">
                <v-btn
                  v-for="(context, index) in notificationContext"
                  :key="index"
                  depressed
                  class="d-flex justify-start w-100 px-3 mt-1"
                  style="border-radius: 4px"
                  @click="notificationState.inAppOption = index"
                >
                  <v-icon
                    size="18"
                    :color="context.color"
                  >
                    {{ context.icon }}
                  </v-icon>

                  <span class="text-subtitle-1 font-medium pl-2">
                    {{ context.label }}
                  </span>
                </v-btn>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>

        <v-expand-transition>
          <div v-if="notificationState.inAppOption == 2">
            <v-card
              outlined
              class="d-flex mb-3 mx-3"
            >
              <v-expansion-panels multiple>
                <v-expansion-panel
                  outlined
                  class="custom-panel v-card v-sheet--outlined"
                >
                  <v-expansion-panel-header class="custom-panel-head py-3 px-4">
                    <div
                      class="d-flex justify-center align-center mr-2"
                      style="flex: 0"
                    >
                      <v-checkbox
                        v-model="inAppNotification.job"
                        hide-details
                        class="mt-0 pt-0"
                        :false-value="0"
                        :true-value="1"
                        :indeterminate="inAppNotification.job === 2"
                        @click="handleSetNotificationGroupChange('job', inAppNotification.job, 0)"
                        @click.stop
                      ></v-checkbox>
                    </div>

                    <div class="d-flex flex-column">
                      <span class="text-subtitle-1 font-weight-medium">Job</span>
                      <span class="text-body-2">
                        ON: {{ inAppNotification.active_job }}&nbsp;&nbsp;–&nbsp;
                        <span class="error--text">OFF: {{ inAppNotification.disabled_job }}</span>
                      </span>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content class="pl-10 py-0">
                    <v-row
                      no-gutters
                      class="mb-3"
                    >
                      <v-divider></v-divider>
                    </v-row>

                    <v-row
                      no-gutters
                      class="align-center my-5"
                      v-for="(context, index) in settingsContext[0].children"
                      :key="index"
                    >
                      <v-checkbox
                        v-model="notificationState.job[context.accessor][0]"
                        hide-details
                        class="mt-0 pt-0"
                        :label="context.description"
                        :false-value="0"
                        :true-value="1"
                        @click="handleSetNotificationIndividualChange('job', context.accessor, 0)"
                      ></v-checkbox>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>

            <v-card
              outlined
              class="d-flex mb-3 mx-3"
            >
              <v-expansion-panels multiple>
                <v-expansion-panel
                  outlined
                  class="custom-panel v-card v-sheet--outlined"
                >
                  <v-expansion-panel-header class="custom-panel-head py-3 px-4">
                    <div
                      class="d-flex justify-center align-center mr-2"
                      style="flex: 0"
                    >
                      <v-checkbox
                        v-model="inAppNotification.document"
                        hide-details
                        class="mt-0 pt-0"
                        :false-value="0"
                        :true-value="1"
                        :indeterminate="inAppNotification.document === 2"
                        @click="
                          handleSetNotificationGroupChange(
                            'document',
                            inAppNotification.document,
                            0,
                          )
                        "
                        @click.stop
                      ></v-checkbox>
                    </div>

                    <div class="d-flex flex-column">
                      <span class="text-subtitle-1 font-weight-medium">Dokumen</span>
                      <span class="text-body-2">
                        ON: {{ inAppNotification.active_document }}&nbsp;&nbsp;–&nbsp;
                        <span class="error--text"
                          >OFF: {{ inAppNotification.disabled_document }}</span
                        >
                      </span>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content class="pl-10 py-0">
                    <v-row
                      no-gutters
                      class="mb-3"
                    >
                      <v-divider></v-divider>
                    </v-row>

                    <v-row
                      no-gutters
                      class="align-center my-5"
                      v-for="(context, index) in settingsContext[1].children"
                      :key="index"
                    >
                      <v-checkbox
                        v-model="notificationState.document[context.accessor][0]"
                        hide-details
                        class="mt-0 pt-0"
                        :color="
                          notificationState.document[context.accessor][0] == 1
                            ? 'primary'
                            : 'secondary'
                        "
                        :label="context.description"
                        :false-value="0"
                        :true-value="1"
                        @click="
                          handleSetNotificationIndividualChange('document', context.accessor, 0)
                        "
                      ></v-checkbox>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>

            <v-card
              outlined
              class="d-flex mb-3 mx-3"
            >
              <v-expansion-panels multiple>
                <v-expansion-panel
                  outlined
                  class="custom-panel v-card v-sheet--outlined"
                >
                  <v-expansion-panel-header class="custom-panel-head py-3 px-4">
                    <div
                      class="d-flex justify-center align-center mr-2"
                      style="flex: 0"
                    >
                      <v-checkbox
                        v-model="inAppNotification.expected_close_date"
                        hide-details
                        class="mt-0 pt-0"
                        :false-value="0"
                        :true-value="1"
                        :indeterminate="inAppNotification.expected_close_date === 2"
                        @click="
                          handleSetNotificationGroupChange(
                            'expected_close_date',
                            inAppNotification.expected_close_date,
                            0,
                          )
                        "
                        @click.stop
                      ></v-checkbox>
                    </div>

                    <div class="d-flex flex-column">
                      <span class="text-subtitle-1 font-weight-medium">Tanggal Awal & Akhir</span>
                      <span class="text-body-2">
                        ON: {{ inAppNotification.active_expected_close_date }}&nbsp;&nbsp;–&nbsp;
                        <span class="error--text">
                          OFF: {{ inAppNotification.disabled_expected_close_date }}
                        </span>
                      </span>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content class="pl-10 py-0">
                    <v-row
                      no-gutters
                      class="mb-3"
                    >
                      <v-divider></v-divider>
                    </v-row>

                    <v-row
                      no-gutters
                      class="align-center my-5"
                      v-for="(context, index) in settingsContext[2].children"
                      :key="index"
                    >
                      <v-checkbox
                        v-model="notificationState.expected_close_date[context.accessor][0]"
                        hide-details
                        class="mt-0 pt-0"
                        :color="
                          notificationState.expected_close_date[context.accessor][0] == 1
                            ? 'primary'
                            : 'secondary'
                        "
                        :label="context.description"
                        :false-value="0"
                        :true-value="1"
                        @click="
                          handleSetNotificationIndividualChange(
                            'expected_close_date',
                            context.accessor,
                            0,
                          )
                        "
                      ></v-checkbox>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </div>
        </v-expand-transition>
      </v-card>

      <v-card
        outlined
        class="d-flex flex-column mt-3"
      >
        <v-row class="d-flex align-center mx-0 my-0 py-2 px-2">
          <v-col
            cols="12"
            md="9"
            lg="10"
            class="d-flex align-center"
          >
            <v-icon
              class="mr-2"
              size="38"
            >
              {{ icons.mdiEmailOutline }}
            </v-icon>

            <div class="d-flex flex-column">
              <h3 class="text-subtitle-1 font-weight-semibold">Notifikasi Email</h3>
              <p class="text-caption">
                {{ notificationContext[notificationState.emailOption].label }} –
                {{ notificationContext[notificationState.emailOption].description }}
              </p>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="3"
            lg="2"
            class="d-flex align-center"
          >
            <v-menu
              bottom
              offset-y
            >
              <template #activator="{ attrs, on }">
                <v-btn
                  outlined
                  depressed
                  class="w-100 justify-center v-card v-sheet--outlined"
                  style="border-radius: 5px; box-shadow: none"
                  :loading="isLoading"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    size="18"
                    :color="notificationContext[notificationState.emailOption].color"
                  >
                    {{ notificationContext[notificationState.emailOption].icon }}
                  </v-icon>

                  <span class="text-subtitle-1 font-medium pl-2">
                    {{ notificationContext[notificationState.emailOption].label }}
                  </span>
                </v-btn>
              </template>

              <v-card class="d-flex flex-column px-3 py-3">
                <v-btn
                  v-for="(context, index) in notificationContext"
                  :key="index"
                  depressed
                  class="d-flex justify-start w-100 px-3 mt-1"
                  style="border-radius: 4px"
                  @click="notificationState.emailOption = index"
                >
                  <v-icon
                    size="18"
                    :color="context.color"
                  >
                    {{ context.icon }}
                  </v-icon>

                  <span class="text-subtitle-1 font-medium pl-2">
                    {{ context.label }}
                  </span>
                </v-btn>
              </v-card>
            </v-menu>

            <v-card
              outlined
              class="d-flex mt-3"
            >
            </v-card>
          </v-col>
        </v-row>

        <v-expand-transition>
          <div v-if="notificationState.emailOption == 2">
            <v-card
              outlined
              class="d-flex mb-3 mx-3"
            >
              <v-expansion-panels multiple>
                <v-expansion-panel
                  outlined
                  class="custom-panel v-card v-sheet--outlined"
                >
                  <v-expansion-panel-header class="custom-panel-head py-3 px-4">
                    <div
                      class="d-flex justify-center align-center mr-2"
                      style="flex: 0"
                    >
                      <v-checkbox
                        v-model="emailNotification.job"
                        hide-details
                        class="mt-0 pt-0"
                        :false-value="0"
                        :true-value="1"
                        :indeterminate="emailNotification.job === 2"
                        @click="handleSetNotificationGroupChange('job', emailNotification.job, 1)"
                        @click.stop
                      ></v-checkbox>
                    </div>

                    <div class="d-flex flex-column">
                      <span class="text-subtitle-1 font-weight-medium">Job</span>
                      <span class="text-body-2">
                        ON: {{ emailNotification.active_job }}&nbsp;&nbsp;–&nbsp;
                        <span class="error--text"> OFF: {{ emailNotification.disabled_job }} </span>
                      </span>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content class="pl-10 py-0">
                    <v-row
                      no-gutters
                      class="mb-3"
                    >
                      <v-divider></v-divider>
                    </v-row>

                    <v-row
                      no-gutters
                      class="align-center my-5"
                      v-for="(context, index) in settingsContext[0].children"
                      :key="index"
                    >
                      <v-checkbox
                        v-model="notificationState.job[context.accessor][1]"
                        hide-details
                        class="mt-0 pt-0"
                        :color="
                          notificationState.job[context.accessor][1] == 1 ? 'primary' : 'secondary'
                        "
                        :label="context.description"
                        :false-value="0"
                        :true-value="1"
                        @click="handleSetNotificationIndividualChange('job', context.accessor, 1)"
                      ></v-checkbox>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>

            <v-card
              outlined
              class="d-flex mb-3 mx-3"
            >
              <v-expansion-panels multiple>
                <v-expansion-panel
                  outlined
                  class="custom-panel v-card v-sheet--outlined"
                >
                  <v-expansion-panel-header class="custom-panel-head py-3 px-4">
                    <div
                      class="d-flex justify-center align-center mr-2"
                      style="flex: 0"
                    >
                      <v-checkbox
                        v-model="emailNotification.document"
                        hide-details
                        class="mt-0 pt-0"
                        :false-value="0"
                        :true-value="1"
                        :indeterminate="emailNotification.document === 2"
                        @click="
                          handleSetNotificationGroupChange(
                            'document',
                            emailNotification.document,
                            1,
                          )
                        "
                        @click.stop
                      ></v-checkbox>
                    </div>

                    <div class="d-flex flex-column">
                      <span class="text-subtitle-1 font-weight-medium">Dokumen</span>
                      <span class="text-body-2">
                        ON: {{ emailNotification.active_document }}&nbsp;&nbsp;–&nbsp;
                        <span class="error--text">
                          OFF: {{ emailNotification.disabled_document }}
                        </span>
                      </span>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content class="pl-10 py-0">
                    <v-row
                      no-gutters
                      class="mb-3"
                    >
                      <v-divider></v-divider>
                    </v-row>

                    <v-row
                      no-gutters
                      class="align-center my-5"
                      v-for="(context, index) in settingsContext[1].children"
                      :key="index"
                    >
                      <v-checkbox
                        v-model="notificationState.document[context.accessor][1]"
                        hide-details
                        class="mt-0 pt-0"
                        :color="
                          notificationState.document[context.accessor][1] == 1
                            ? 'primary'
                            : 'secondary'
                        "
                        :label="context.description"
                        :false-value="0"
                        :true-value="1"
                        @click="
                          handleSetNotificationIndividualChange('document', context.accessor, 1)
                        "
                      ></v-checkbox>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>

            <v-card
              outlined
              class="d-flex mb-3 mx-3"
            >
              <v-expansion-panels multiple>
                <v-expansion-panel
                  outlined
                  class="custom-panel v-card v-sheet--outlined"
                >
                  <v-expansion-panel-header class="custom-panel-head py-3 px-4">
                    <div
                      class="d-flex justify-center align-center mr-2"
                      style="flex: 0"
                    >
                      <v-checkbox
                        v-model="emailNotification.expected_close_date"
                        hide-details
                        class="mt-0 pt-0"
                        :false-value="0"
                        :true-value="1"
                        :indeterminate="emailNotification.expected_close_date === 2"
                        @click="
                          handleSetNotificationGroupChange(
                            'expected_close_date',
                            emailNotification.expected_close_date,
                            1,
                          )
                        "
                        @click.stop
                      ></v-checkbox>
                    </div>

                    <div class="d-flex flex-column">
                      <span class="text-subtitle-1 font-weight-medium">Tanggal Awal & Akhir</span>
                      <span class="text-body-2">
                        ON: {{ emailNotification.active_expected_close_date }}&nbsp;&nbsp;–&nbsp;
                        <span class="error--text">
                          OFF: {{ emailNotification.disabled_expected_close_date }}
                        </span>
                      </span>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content class="pl-10 py-0">
                    <v-row
                      no-gutters
                      class="mb-3"
                    >
                      <v-divider></v-divider>
                    </v-row>

                    <v-row
                      no-gutters
                      class="align-center my-5"
                      v-for="(context, index) in settingsContext[2].children"
                      :key="index"
                    >
                      <v-checkbox
                        v-model="notificationState.expected_close_date[context.accessor][1]"
                        hide-details
                        class="mt-0 pt-0"
                        :color="
                          notificationState.expected_close_date[context.accessor][1] == 1
                            ? 'primary'
                            : 'secondary'
                        "
                        :label="context.description"
                        :false-value="0"
                        :true-value="1"
                        @click="
                          handleSetNotificationIndividualChange(
                            'expected_close_date',
                            context.accessor,
                            1,
                          )
                        "
                      ></v-checkbox>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </div>
        </v-expand-transition>
      </v-card>

      <v-card
        outlined
        disabled
        class="d-flex mt-3"
      >
        <v-row class="d-flex align-center mx-0 my-0 py-2 px-2">
          <v-col
            cols="12"
            md="9"
            lg="10"
            class="d-flex align-center"
          >
            <v-icon
              class="mr-2"
              size="38"
            >
              {{ icons.mdiMonitor }}
            </v-icon>

            <div class="d-flex flex-column">
              <h3 class="text-subtitle-1 font-weight-semibold">Notifikasi Desktop</h3>
              <p class="text-caption">Off – semua notifikasi dalam konsidi off</p>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="3"
            lg="2"
            class="d-flex align-center"
          >
            <v-menu
              bottom
              offset-y
            >
              <template #activator="{ attrs, on }">
                <v-btn
                  disabled
                  outlined
                  depressed
                  class="w-100 justify-center v-card v-sheet--outlined"
                  style="border-radius: 5px; box-shadow: none"
                  :loading="isLoading"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiCancel }}
                  </v-icon>

                  <h3 class="font-medium pl-2">Off</h3>
                </v-btn>
              </template>

              <v-card class="d-flex flex-column px-3 py-3">
                <v-btn
                  depressed
                  class="d-flex justify-start w-100 px-3 mt-1"
                  style="border-radius: 4px"
                >
                  <v-icon size="18">
                    {{ icons.mdiCardBulletedOutline }}
                  </v-icon>

                  <h3 class="font-medium pl-2">Default</h3>
                </v-btn>

                <v-btn
                  depressed
                  class="d-flex justify-start w-100 px-3 mt-1"
                  style="border-radius: 4px"
                >
                  <v-icon size="18">
                    {{ icons.mdiStarFourPointsOutline }}
                  </v-icon>

                  <h3 class="font-medium pl-2">Custom</h3>
                </v-btn>

                <v-btn
                  depressed
                  class="d-flex justify-start w-100 px-3 mt-1"
                  style="border-radius: 4px"
                >
                  <v-icon size="18">
                    {{ icons.mdiCancel }}
                  </v-icon>

                  <h3 class="font-medium pl-2">Off</h3>
                </v-btn>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <v-card class="error--text w-full d-flex justify-end px-5 py-3">
      <v-btn
        depressed
        color="primary"
        style="border-radius: 4px"
        @click="handleUpdateNotificationSettings"
      >
        <v-icon size="18">
          {{ icons.mdiContentSaveOutline }}
        </v-icon>

        <h3
          class="font-medium pl-2"
          style="color: white !important"
        >
          Simpan Perubahan
        </h3>
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import useNotification from '@/composables/useNotification'
import { defaultNotification, notificationContext, settingsContext } from '@/constants/notification'
import {
  mdiCancel,
  mdiCardBulletedOutline,
  mdiContentSaveOutline,
  mdiEmailOutline,
  mdiInformationSlabBoxOutline,
  mdiMonitor,
  mdiStarFourPointsOutline,
} from '@mdi/js'
import { onMounted, ref } from 'vue'

/**
 * A simple explanation and legends words that occured multiple times in this file documentation
 *
 * ---
 * @name notificationType - a type of notification, to provide what type of notification it is
 * @var inAppNotification
 * @var emailNotification
 * @var desktopNotification - not yet implemented
 *
 * @name notificationGroup - a group of notification, to represent where does the notification comes from
 * @var notificationType.job
 * @var notificationType.document
 * @var notificationType.expected_close_date
 *
 * @name notificationCounter - count the total of active and disabled individual notification available
 * @var notificationGroup_active
 * @var notificationGroup_disabled
 * ```
 */
export default {
  setup() {
    const isLoading = ref(false)

    const inAppNotification = ref({
      job: 1,
      active_job: 0,
      disabled_job: 0,

      document: 1,
      active_document: 0,
      disabled_document: 0,

      expected_close_date: 1,
      active_expected_close_date: 0,
      disabled_expected_close_date: 0,
    })
    const emailNotification = ref({
      job: 1,
      active_job: 0,
      disabled_job: 0,

      document: 1,
      active_document: 0,
      disabled_document: 0,

      expected_close_date: 1,
      active_expected_close_date: 0,
      disabled_expected_close_date: 0,
    })
    const desktopNotification = ref(0)
    const NOTIFICATION_TYPE = [inAppNotification, emailNotification]

    const notificationState = ref(defaultNotification)

    const { fetchNotificationSettings, updateNotificationSettings } = useNotification()

    /**
     * checkNotificationGroupActive, is here to simply check whether all notification by their type is active or not
     * and to control initial count of `notifiationCounter` by their type
     *
     * ---
     * @param {string} target - to give ease of acesss where the data is located, using the type
     * @example
     * ```javascript
     * checkNotificationGroupActive('job')
     * checkNotificationGroupActive('document')
     * checkNotificationGroupActive('expected_close_date')
     * ```
     */
    const checkNotificationGroupActive = target => {
      for (const key in notificationState.value[target]) {
        if (notificationState.value[target][key][0] === 0) {
          if (inAppNotification.value[target] !== 2) inAppNotification.value[target] = 2

          inAppNotification.value[`disabled_${target}`]++
        } else inAppNotification.value[`active_${target}`]++

        if (notificationState.value[target][key][1] === 0) {
          if (emailNotification.value[target] !== 2) emailNotification.value[target] = 2

          emailNotification.value[`disabled_${target}`]++
        } else emailNotification.value[`active_${target}`]++
      }
    }

    /**
     * handleSetNotificatiopnGroupChange, is quite self explanatory, it simply changes an entire group of notification
     * while also controlling `notificationCounter` based on the `total_entry` or the total of individual notification
     *
     * ---
     * @param {string} target - here to simply give direction on what `active_TYPE` & `disabled_TYPE` to modify
     * @param {number} value - self-explanatory, pass the value that is changed into
     * @param {number} index - determine which index to edit, based on what notifcationGroup
     * @see [what-is-notification-settings-index](../../../constants/notification/notificationSettingsContext.js)
     * @example
     * ```javascript
     * handleSetNotificationGroupChange('job', inAppNotification.job, 0)
     * handleSetNotificationGroupChange('document', emailNotification.document, 1)
     * ```
     */
    const handleSetNotificationGroupChange = (target, value, index) => {
      const decide_type = NOTIFICATION_TYPE[index].value
      const total_entry = Object.entries(notificationState.value[target]).length

      for (const key in notificationState.value[target]) {
        notificationState.value[target][key][index] = value
      }

      if (value === 0) {
        decide_type[`active_${target}`] = 0
        decide_type[`disabled_${target}`] = total_entry
      } else {
        decide_type[`active_${target}`] = total_entry
        decide_type[`disabled_${target}`] = 0
      }
    }

    /**
     * handleSetNotificationIndividualChange, is quite self explanatory as well, it simply change a notificationSetting
     * while also increment or decrement  of `notificationCounter` that is passed into as an agrument
     *
     * ---
     * @param {string} target - here to simply give direction on what `active_TYPE` & `disabled_TYPE` to modify
     * @param {string} accessor - using
     * @param {number} index - determine which index to edit, based on what notifcationGroup
     * @see [what-is-notification-settings-index](../../../constants/notification/notificationSettingsContext.js)
     * @example
     * ```javascript
     * handleSetNotificationGroupChange('job', context.accessor, 0)
     * handleSetNotificationGroupChange('document', context.accessor, 1)
     * ```
     */
    const handleSetNotificationIndividualChange = (target, accessor, index) => {
      const decide_type = NOTIFICATION_TYPE[index].value
      const total_entry = Object.entries(notificationState.value[target]).length

      if (notificationState.value[target][accessor][index] === 0) {
        decide_type[`active_${target}`]--
        decide_type[`disabled_${target}`]++
      } else {
        decide_type[`active_${target}`]++
        decide_type[`disabled_${target}`]--
      }

      if (decide_type[`active_${target}`] === 0) return (decide_type[target] = 0)
      if (decide_type[`active_${target}`] === total_entry) return (decide_type[target] = 1)
      decide_type[target] = 2
    }

    const handleUpdateNotificationSettings = () => {
      updateNotificationSettings(notificationState.value)
    }

    onMounted(() => {
      isLoading.value = true

      fetchNotificationSettings()
        .then(data => {
          if (!data.inAppOption && !data.emailOption && !data.desktopasOption)
            data = {
              inAppOption: 0,
              emailOption: 0,
              desktopOption: 0,
              ...data,
            }

          notificationState.value = data

          checkNotificationGroupActive('job')
          checkNotificationGroupActive('document')
          checkNotificationGroupActive('expected_close_date')
        })
        .finally(() => (isLoading.value = false))
    })

    return {
      isLoading,
      notificationContext,
      settingsContext,

      inAppNotification,
      emailNotification,
      desktopNotification,

      notificationState,

      handleSetNotificationGroupChange,
      handleSetNotificationIndividualChange,
      handleUpdateNotificationSettings,

      icons: {
        mdiInformationSlabBoxOutline,
        mdiEmailOutline,
        mdiMonitor,

        mdiCardBulletedOutline,
        mdiCancel,
        mdiStarFourPointsOutline,
        mdiContentSaveOutline,
      },
    }
  },
}
</script>

<style lang="scss">
.custom-panel,
.custom-btn-toggle,
.custom-panel-head {
  &::before {
    background: transparent !important;
    box-shadow: none !important;
  }
}

.custom-panel-head {
  cursor: default;
}

.custom-panel-content {
  .v-expansion-panel-content__wrap {
    padding-inline: 8px !important;
    padding-block: 0 !important;
  }
}

.notification-settings {
  p {
    margin: 0 !important;
  }
}
</style>
