<!-- Taken from CommentContent.vue -->

<template>
  <div
    style="max-width: 100%"
    class="notification-comment d-flex text-truncate w-100"
  >
    <template v-if="strip">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-avatar
            v-bind="attrs"
            size="30"
            class="v-avatar-light-bg elevation-1 cursor-pointer"
            v-on="on"
            @click="
              router.push({
                name: 'user-detail',
                params: { id: comment.user.id },
              })
            "
            @click.stop
          >
            <v-img
              v-if="comment.user.photo"
              :src="comment.user.photo"
            />
            <span
              class="caption"
              v-else
            >
              {{
                avatarText(
                  comment.user.id === store.getters.getUserData.id ? 'U' : comment.user.name,
                )
              }}
            </span>
          </v-avatar>
        </template>
        <span>{{
          comment.user.id === store.getters.getUserData.id ? 'Anda' : comment.user.name
        }}</span>
      </v-tooltip>

      <span class="d-flex align-center body-2 mx-2 text-truncate">
        Membuat komentar :

        <v-btn
          text
          x-small
          color="primary"
          class="mx-1 justify-start flex-fill"
          @click.stop
        >
          <span
            v-html="
              comment.strip_comment.length > 50
                ? comment.strip_comment.substring(0, 50) + '...'
                : comment.strip_comment
            "
            class="assign-comment-text body-2 text-truncate"
            @click="$emit('comment-click', comment.id)"
          />
        </v-btn>
      </span>
    </template>

    <template v-else>
      <v-hover v-slot="{ hover }">
        <v-card
          outlined
          width="100%"
          class="px-4 pb-5 d-flex flex-column justify-start"
          @click="isReplying = true"
          :ripple="false"
        >
          <template v-if="comment.parent">
            <v-card
              outlined
              width="100%"
              class="px-4 py-5 d-flex flex-column justify-start mt-4"
            >
              <div class="d-flex align-center">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-avatar
                      v-bind="attrs"
                      size="30"
                      class="v-avatar-light-bg elevation-1 cursor-pointer mr-2"
                      v-on="on"
                      @click="
                        router.push({
                          name: 'user-detail',
                          params: { id: comment.parent.user.id },
                        })
                      "
                      @click.stop
                    >
                      <v-img
                        v-if="comment.parent.user.photo"
                        :src="comment.parent.user.photo"
                      />
                      <span
                        class="caption"
                        v-else
                      >
                        {{
                          avatarText(
                            comment.parent.user.id === store.getters.getUserData.id
                              ? 'U'
                              : comment.parent.user.name,
                          )
                        }}</span
                      >
                    </v-avatar>
                  </template>
                  <span>
                    {{
                      comment.parent.user.id === store.getters.getUserData.id
                        ? 'Anda'
                        : comment.parent.user.name
                    }}
                  </span>
                </v-tooltip>

                <span class="body-2 font-medium primary--text mr-2">
                  {{
                    comment.parent.user.name.length > 6
                      ? comment.parent.user.name.substring(0, 6) + '..'
                      : comment.parent.user.name
                  }}
                </span>

                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      class="caption text--disabled"
                      v-on="on"
                    >
                      {{ dateFormat(comment.parent.created_at, 11) }}
                    </span>
                  </template>
                  <div class="d-flex flex-column">
                    <span>Dikirim: {{ dateFormat(comment.parent.created_at, 11) }}</span>
                    <span>Diperbarui: {{ dateFormat(comment.parent.updated_at, 11) }}</span>
                  </div>
                </v-tooltip>

                <template v-if="comment.parent.attachment && !hideDetails">
                  <v-icon
                    class="mx-2"
                    size="22"
                  >
                    {{ icons.mdiMinus }}
                  </v-icon>

                  <span class="caption text--disabled"> berkomentar di attachment </span>

                  <v-btn
                    text
                    small
                    color="primary"
                    class="d-flex align-center mx-1"
                    @click="
                      router.push(
                        `${baseAttachmentUrl}/${comment.parent.job.job_type.id}/${comment.parent.attachment}`,
                      )
                    "
                    @click.stop
                  >
                    <v-img
                      width="22"
                      height="22"
                      aspect-ratio="400/400"
                      class="rounded elevation-1 mr-2"
                      :src="thumbnailURL(comment.parent.attachment)"
                    />

                    {{ comment.parent.attachment.file_name }}
                  </v-btn>
                </template>

                <v-spacer></v-spacer>

                <template v-if="comment.parent.assigned_user && !hideDetails">
                  <v-slide-x-reverse-transition mode="out-in">
                    <div v-show="hover">
                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-avatar
                              size="25"
                              class="mr-1 v-avatar-light-bg"
                            >
                              <v-img
                                v-if="comment.parent.assigned_user.photo"
                                :src="comment.parent.assigned_user.photo"
                              />
                              <span
                                v-else
                                class="text-caption font-medium"
                              >
                                {{ avatarText(comment.parent.assigned_user.name) }}
                              </span>
                            </v-avatar>
                            <span class="text-caption font-medium primary--text mr-2">
                              {{ comment.parent.assigned_user.name }}
                            </span>
                          </div>
                        </template>
                        <span>
                          Komentar ini di assign kepada
                          <span class="font-medium">
                            {{ comment.parent.assigned_user.name }}
                          </span>
                        </span>
                      </v-tooltip>
                    </div>
                  </v-slide-x-reverse-transition>
                </template>

                <v-slide-x-reverse-transition mode="out-in">
                  <div v-show="hover">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          small
                          depressed
                          class="mr-1"
                          style="border-radius: 4px"
                          @click="handleGoToComment(comment.parent.id)"
                          @click.stop
                          v-bind="{ ...attrs }"
                          v-on="{ ...on }"
                        >
                          <v-icon size="14">
                            {{ icons.mdiOpenInNew }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ t('Pergi ke komentar') }}</span>
                    </v-tooltip>
                  </div>
                </v-slide-x-reverse-transition>

                <v-menu
                  bottom
                  center
                  offset-y
                  :close-on-content-click="true"
                  style="z-index: 205"
                >
                  <template #activator="menu">
                    <v-tooltip top>
                      <template #activator="tooltip">
                        <v-btn
                          icon
                          small
                          depressed
                          class="mr-1"
                          style="border-radius: 4px"
                          v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                          v-on="{ ...menu.on, ...tooltip.on }"
                          @click.stop
                        >
                          <v-icon size="14">
                            {{ icons.mdiDotsVertical }}
                          </v-icon>
                        </v-btn>
                      </template>

                      <span>{{ t('Aksi Lebih') }}</span>
                    </v-tooltip>
                  </template>

                  <v-card
                    class="d-flex flex-column user-selector-container elevation-0 mx-auto px-3 pt-3 pb-2"
                    width="200px"
                  >
                    <v-menu
                      bottom
                      center
                      offset-y
                      v-model="assignUserMenuParent"
                      :close-on-content-click="false"
                      style="z-index: 205"
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          depressed
                          class="d-flex justify-start px-3"
                          style="border-radius: 4px"
                          @click="() => {}"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon size="18">
                            {{ icons.mdiAccountPlus }}
                          </v-icon>

                          <h3 class="font-medium pl-2">Assign To</h3>
                        </v-btn>
                      </template>

                      <user-selector
                        v-if="assignUserMenuParent"
                        v-model="selectAssignUserParent"
                        :assign-user="comment.parent.assigned_user"
                        :object-id="document ? document.uuid : job.job_type.id"
                        :object-type="document ? 'document' : 'job_type'"
                      ></user-selector>
                    </v-menu>

                    <v-btn
                      v-if="comment.parent.user.id == store.getters.getUserData.id"
                      depressed
                      class="d-flex justify-start px-3 mt-1"
                      style="border-radius: 4px"
                      @click="handleStartEditParent"
                    >
                      <v-icon size="18">
                        {{ icons.mdiPencil }}
                      </v-icon>

                      <h3 class="font-medium pl-2">Edit Comment</h3>
                    </v-btn>

                    <v-btn
                      v-if="comment.parent.user.id == store.getters.getUserData.id"
                      depressed
                      class="d-flex justify-start px-3 mt-1"
                      style="border-radius: 4px"
                      @click="$emit('delete')"
                    >
                      <v-icon size="18">
                        {{ icons.mdiDelete }}
                      </v-icon>

                      <h3 class="font-medium pl-2">Delete Comment</h3>
                    </v-btn>
                  </v-card>
                </v-menu>

                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      depressed
                      style="border-radius: 4px"
                      @click="resolveUnResolveComment(comment.parent.id)"
                      @click.stop
                      v-bind="{ ...attrs }"
                      v-on="{ ...on }"
                    >
                      <v-icon
                        size="20"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{
                          comment.resolved_at
                            ? icons.mdiCheckboxMarked
                            : icons.mdiCheckboxBlankOutline
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                  Resolve
                </v-tooltip>
              </div>

              <div
                class="body-2 mt-2"
                style="cursor: text; margin-left: 38px"
              >
                <ckeditor-toggleable
                  :read-only="readOnlyParent"
                  :ready-only="readOnlyParent"
                  :content.sync="comment.parent.comment"
                />

                <template v-if="!readOnlyParent">
                  <div class="h-full mt-2">
                    <v-btn
                      small
                      depressed
                      class="mr-2"
                      color="secondary"
                      v-show="!readOnlyParent"
                      style="border-radius: 4px"
                      @click="handleStartEditParent"
                      @click.stop
                    >
                      <v-icon size="18">
                        {{ icons.mdiCancel }}
                      </v-icon>

                      <h3
                        class="font-medium pl-2"
                        style="color: white !important"
                      >
                        {{ t('Batalkan') }}
                      </h3>
                    </v-btn>

                    <v-btn
                      small
                      depressed
                      color="primary"
                      v-show="!readOnlyParent"
                      style="border-radius: 4px"
                      @click="updateComment(comment.parent.id, comment.parent.comment)"
                      @click.stop
                    >
                      <v-icon size="18">
                        {{ icons.mdiContentSave }}
                      </v-icon>

                      <h3
                        class="font-medium pl-2"
                        style="color: white !important"
                      >
                        {{ t('Simpan') }}
                      </h3>
                    </v-btn>
                  </div>
                </template>
              </div>
            </v-card>
          </template>

          <div class="d-flex align-center mt-4">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  size="30"
                  class="v-avatar-light-bg elevation-1 cursor-pointer mr-2"
                  v-on="on"
                  @click="
                    router.push({
                      name: 'user-detail',
                      params: { id: comment.user.id },
                    })
                  "
                  @click.stop
                >
                  <v-img
                    v-if="comment.user.photo"
                    :src="comment.user.photo"
                  />
                  <span
                    class="caption"
                    v-else
                  >
                    {{
                      avatarText(
                        comment.user.id === store.getters.getUserData.id ? 'U' : comment.user.name,
                      )
                    }}
                  </span>
                </v-avatar>
              </template>
              <span>
                {{ comment.user.id === store.getters.getUserData.id ? 'Anda' : comment.user.name }}
              </span>
            </v-tooltip>

            <span class="body-2 font-medium primary--text mr-2">
              {{ comment.user.name }}
            </span>

            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <span
                  class="caption text--disabled"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ dateFormat(comment.created_at, 11) }}
                </span>
              </template>
              <div class="d-flex flex-column">
                <span>Dikirim: {{ dateFormat(comment.created_at, 11) }}</span>
                <span>Diperbarui: {{ dateFormat(comment.updated_at, 11) }}</span>
              </div>
            </v-tooltip>

            <template v-if="comment.attachment && !hideDetails">
              <v-icon
                class="mx-2"
                size="22"
              >
                {{ icons.mdiMinus }}
              </v-icon>

              <span class="caption text--disabled"> berkomentar di attachment </span>

              <v-btn
                text
                small
                color="primary"
                class="d-flex align-center mx-1"
                @click="
                  router.push({ name: 'attachment-detail', params: { id: comment.attachment.id } })
                "
                @click.stop
              >
                <v-img
                  width="22"
                  height="22"
                  aspect-ratio="400/400"
                  class="rounded elevation-1 mr-2"
                  :src="thumbnailURL(comment.attachment)"
                />

                {{ comment.attachment.file_name }}
              </v-btn>
            </template>

            <v-spacer></v-spacer>

            <template v-if="comment.assigned_user && !hideDetails">
              <v-slide-x-reverse-transition mode="out-in">
                <div v-show="hover">
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-avatar
                          size="25"
                          class="mr-1 v-avatar-light-bg"
                        >
                          <v-img
                            v-if="comment.assigned_user.photo"
                            :src="comment.assigned_user.photo"
                          />
                          <span
                            v-else
                            class="text-caption font-medium"
                          >
                            {{ avatarText(comment.assigned_user.name) }}
                          </span>
                        </v-avatar>
                        <span class="text-caption font-medium primary--text mr-2">
                          {{ comment.assigned_user.name }}
                        </span>
                      </div>
                    </template>
                    <span>
                      Komentar ini di assign kepada
                      <span class="font-medium">
                        {{ comment.assigned_user.name }}
                      </span>
                    </span>
                  </v-tooltip>
                </div>
              </v-slide-x-reverse-transition>
            </template>

            <v-slide-x-reverse-transition mode="out-in">
              <div v-show="hover">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      depressed
                      class="mr-1"
                      style="border-radius: 4px"
                      @click="handleGoToComment(comment.id)"
                      @click.stop
                      v-bind="{ ...attrs }"
                      v-on="{ ...on }"
                    >
                      <v-icon size="14">
                        {{ icons.mdiOpenInNew }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ t('Pergi ke komentar') }}</span>
                </v-tooltip>
              </div>
            </v-slide-x-reverse-transition>

            <v-menu
              bottom
              center
              offset-y
              :close-on-content-click="true"
              style="z-index: 205"
            >
              <template #activator="menu">
                <v-tooltip top>
                  <template #activator="tooltip">
                    <v-btn
                      icon
                      small
                      depressed
                      class="mr-1"
                      style="border-radius: 4px"
                      @click="() => {}"
                      v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                      v-on="{ ...menu.on, ...tooltip.on }"
                      @click.stop
                    >
                      <v-icon size="14">
                        {{ icons.mdiDotsVertical }}
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>{{ t('Aksi Lebih') }}</span>
                </v-tooltip>
              </template>

              <v-card
                class="d-flex flex-column user-selector-container elevation-0 mx-auto px-3 pt-3 pb-2"
                width="200px"
              >
                <v-menu
                  bottom
                  center
                  offset-y
                  v-model="assignUserMenu"
                  :close-on-content-click="false"
                  style="z-index: 205"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      depressed
                      class="d-flex justify-start px-3"
                      style="border-radius: 4px"
                      @click="() => {}"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiAccountPlus }}
                      </v-icon>

                      <h3 class="font-medium pl-2">Assign To</h3>
                    </v-btn>
                  </template>

                  <user-selector
                    v-if="assignUserMenu"
                    v-model="selectAssignUser"
                    :assign-user="comment.assigned_user"
                    :object-id="document ? document.uuid : job.job_type.id"
                    :object-type="document ? 'document' : 'job_type'"
                  ></user-selector>
                </v-menu>

                <v-btn
                  v-if="comment.user.id == store.getters.getUserData.id"
                  depressed
                  class="d-flex justify-start px-3 mt-1"
                  style="border-radius: 4px"
                  @click="handleStartEdit"
                >
                  <v-icon size="18">
                    {{ icons.mdiPencil }}
                  </v-icon>

                  <h3 class="font-medium pl-2">Edit Comment</h3>
                </v-btn>

                <v-btn
                  v-if="comment.user.id == store.getters.getUserData.id"
                  depressed
                  class="d-flex justify-start px-3 mt-1"
                  style="border-radius: 4px"
                  @click="deleteComment(comment.id)"
                >
                  <v-icon size="18">
                    {{ icons.mdiDelete }}
                  </v-icon>

                  <h3 class="font-medium pl-2">Delete Comment</h3>
                </v-btn>
              </v-card>
            </v-menu>

            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  depressed
                  style="border-radius: 4px"
                  @click="resolveUnResolveComment(comment.id)"
                  @click.stop
                  v-bind="{ ...attrs }"
                  v-on="{ ...on }"
                >
                  <v-icon
                    size="20"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{
                      comment.resolved_at ? icons.mdiCheckboxMarked : icons.mdiCheckboxBlankOutline
                    }}
                  </v-icon>
                </v-btn>
              </template>
              Resolve
            </v-tooltip>
          </div>

          <div
            class="d-flex flex-column body-2 mt-2 included"
            style="cursor: text; margin-left: 38px"
          >
            <ckeditor-toggleable
              :read-only="readOnly"
              :ready-only="readOnly"
              :content.sync="comment.comment"
            />

            <template v-if="!readOnly">
              <div class="h-full mt-2">
                <v-btn
                  small
                  depressed
                  class="mr-2"
                  color="secondary"
                  v-show="!readOnly"
                  style="border-radius: 4px"
                  @click="handleStartEdit"
                  @click.stop
                >
                  <v-icon size="18">
                    {{ icons.mdiCancel }}
                  </v-icon>

                  <h3
                    class="font-medium pl-2"
                    style="color: white !important"
                  >
                    {{ t('Batalkan') }}
                  </h3>
                </v-btn>

                <v-btn
                  small
                  depressed
                  color="primary"
                  v-show="!readOnly"
                  style="border-radius: 4px"
                  @click="updateComment(comment.id, comment.comment)"
                  @click.stop
                >
                  <v-icon size="18">
                    {{ icons.mdiContentSave }}
                  </v-icon>

                  <h3
                    class="font-medium pl-2"
                    style="color: white !important"
                  >
                    {{ t('Simpan') }}
                  </h3>
                </v-btn>
              </div>
            </template>
          </div>

          <v-slide-y-reverse-transition mode="out-in">
            <div
              v-if="isReplying && readOnlyParent"
              v-show="isReplying"
              class="d-flex align-center mt-2"
              style="cursor: text; margin-left: 38px"
            >
              <v-icon
                class="mb-1 mr-2"
                style="transform: rotate(-90deg)"
                size="22"
              >
                {{ icons.mdiArrowDownLeft }}
              </v-icon>

              <ckeditor-toggleable
                ref="editor"
                placeholder="Reply..."
                :content.sync="replyData"
                :read-only="false"
                class="flex-fill"
                style="margin-top: 2px"
              />

              <v-btn
                small
                depressed
                class="ml-2"
                color="primary"
                style="border-radius: 4px"
                @click="handleReply"
                @click.stop
              >
                <v-icon size="16">
                  {{ icons.mdiSend }}
                </v-icon>

                <h3
                  class="font-medium pl-2"
                  style="color: white !important"
                >
                  {{ t('Balas') }}
                </h3>
              </v-btn>
            </div>
          </v-slide-y-reverse-transition>
        </v-card>
      </v-hover>
    </template>
  </div>
</template>

<script>
import UserSelector from '@/components/inputs/UserSelector.vue'
import useAttachment from '@/composables/useAttachment'
import useComment from '@/composables/useComment'
import store from '@/store'
import dateFormat from '@/utils/dateFormat'
import errorHandling from '@/utils/errorHandling'
import CkeditorToggleable from '@/views/document/detail/content/comment/CkeditorToggleable.vue'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { avatarText } from '@core/utils/filter'
import {
  mdiAccountPlus,
  mdiArrowDownLeft,
  mdiCancel,
  mdiCheck,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiCommentOutline,
  mdiContentSave,
  mdiDelete,
  mdiDotsVertical,
  mdiMinus,
  mdiOpenInNew,
  mdiPencil,
  mdiSend,
} from '@mdi/js'
import { provide, ref, watch } from 'vue'

export default {
  components: {
    CkeditorToggleable,
    UserSelector,
  },
  props: {
    hideDetails: {
      type: Boolean,
    },
    comment: {
      type: Object,
      required: true,
    },
    job: {
      type: Object,
    },
    attachment: {
      type: Object,
    },
    document: {
      type: Object,
    },
    strip: {
      type: Boolean,
    },
  },

  setup(props) {
    const { t } = useUtils()
    const { router } = useRouter()
    const {
      addComment,
      updateComment,
      assignComment,
      unassignComment,
      deleteComment,
      resolveUnResolveComment,
    } = useComment()
    const { baseAttachmentUrl } = useAttachment()

    const isReplying = ref(false)
    const replyData = ref('')

    provide('jobDetail', props.comment)

    // Control if comment has parent
    const readOnlyParent = ref(true)
    const initialContentParent = ref('')
    const assignUserMenuParent = ref(false)
    const selectAssignUserParent = ref()
    const handleStartEditParent = () => {
      if (readOnlyParent.value) {
        readOnlyParent.value = false
        initialContentParent.value = props.comment.parent.comment
      } else {
        readOnlyParent.value = true
        // eslint-disable-next-line no-param-reassign
        props.comment.parent.comment = initialContentParent.value
      }
    }
    watch(assignUserMenuParent, () => {
      // ketika memiliki selectAssignuser dan belum memiliki assignUser
      if (
        !assignUserMenuParent.value &&
        selectAssignUserParent.value &&
        !props.comment.parent.assigned_user
      ) {
        return assignComment({
          commentId: props.comment.parent.id,
          idUser: selectAssignUserParent.value,
        })
      }

      // ketika tidak memiliki selectAssignuser dan memiliki assignUser
      if (
        !assignUserMenuParent.value &&
        !selectAssignUserParent.value &&
        props.comment.parent.assigned_user
      ) {
        return unassignComment(props.comment.parent.id)
      }

      // ketika memiliki selectAssignuser dan memiliki assignUser dan ingin mau mengganti ke user lain
      if (
        props.comment.parent.assigned_user &&
        selectAssignUserParent.value &&
        props.comment.parent.assigned_user.id !== selectAssignUserParent.value
      ) {
        assignComment({ commentId: props.comment.parent.id, idUser: selectAssignUserParent.value })
      }
    })

    // Control default comment
    const readOnly = ref(true)
    const initialContent = ref('')
    const assignUserMenu = ref(false)
    const selectAssignUser = ref()
    const handleStartEdit = () => {
      if (readOnly.value) {
        readOnly.value = false
        initialContent.value = props.comment.comment
      } else {
        readOnly.value = true
        // eslint-disable-next-line no-param-reassign
        props.comment.comment = initialContent.value
      }
    }
    watch(assignUserMenu, () => {
      // ketika memiliki selectAssignuser dan belum memiliki assignUser
      if (!assignUserMenu.value && selectAssignUser.value && !props.comment.assigned_user) {
        return assignComment({ commentId: props.comment.id, idUser: selectAssignUser.value })
      }

      // ketika tidak memiliki selectAssignuser dan memiliki assignUser
      if (!assignUserMenu.value && !selectAssignUser.value && props.comment.assigned_user) {
        return unassignComment(props.comment.id)
      }

      // ketika memiliki selectAssignuser dan memiliki assignUser dan ingin mau mengganti ke user lain
      if (
        props.comment.assigned_user &&
        selectAssignUser.value &&
        props.comment.assigned_user.id !== selectAssignUser.value
      ) {
        assignComment({ commentId: props.comment.id, idUser: selectAssignUser.value })
      }
    })

    const handleReply = () => {
      addComment({
        jobId: props?.job?.id,
        attachmentId: props?.attachment?.id,
        documentUUID: props?.document?.uuid,
        replyToId: props.comment.id,
        comment: replyData.value,
      })

      isReplying.value = false
      replyData.value = ''
    }

    const handleGoToComment = commentId => {
      if (props.attachment?.id)
        return router.push({
          name: 'attachment-detail',
          params: {
            id: props.comment.attachment.id,
            commentId: commentId,
          },
        })

      if (props.job.id)
        return router.push({
          name: 'job-detail',
          params: { id: props.job.id, commentId: commentId },
        })

      return errorHandling('Tidak dapat membuka komentar!')
    }

    const thumbnailURL = data => {
      if (data.file_type !== 'image') {
        return `/filetypes/${data.file_name.split('.').pop()}.png`
      }

      // eslint-disable-next-line camelcase
      const { id, file_name } = data
      const fullname = file_name.split('.')
      const extension = fullname.pop()
      const name = fullname.join('.')

      return `${baseAttachmentUrl}/${props.comment.job.job_type.id}/${id}/${name}_thumbnail.${extension}`
    }

    return {
      isReplying,
      replyData,
      handleReply,
      handleGoToComment,
      addComment,
      deleteComment,
      updateComment,
      resolveUnResolveComment,

      readOnlyParent,
      initialContentParent,
      assignUserMenuParent,
      selectAssignUserParent,
      handleStartEditParent,

      readOnly,
      initialContent,
      assignUserMenu,
      selectAssignUser,
      handleStartEdit,

      store,
      router,
      avatarText,
      baseAttachmentUrl,
      thumbnailURL,

      // i18n
      t,

      dateFormat,

      icons: {
        mdiCommentOutline,
        mdiMinus,
        mdiOpenInNew,

        mdiDotsVertical,
        mdiAccountPlus,
        mdiPencil,
        mdiDelete,
        mdiCheckboxMarked,
        mdiCheckboxBlankOutline,
        mdiCheck,
        mdiContentSave,
        mdiCancel,
        mdiArrowDownLeft,
        mdiSend,
      },
    }
  },
}
</script>

<style lang="scss">
.notification-comment {
  .v-btn__content {
    max-width: 100%;
  }

  .ck-editor__editable {
    &:not(.ck-read-only) {
      padding: 8px 20px !important;
    }

    & > * {
      margin: 0 !important;
    }
  }
}
</style>
