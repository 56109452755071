import gql from 'graphql-tag'

export const createAutomation = gql`
  mutation CreateAutomation(
    $action: [CreateActionParams!]!
    $trigger: [CreateTriggerParams!]!
    $automation: CreateAutomationParams!
    $workspaceId: Float!
  ) {
    createAutomation(
      action: $action
      trigger: $trigger
      automation: $automation
      workspace_id: $workspaceId
    ) {
      status
      msg
      data
    }
  }
`
