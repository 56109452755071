import {
  mdiArchiveArrowUpOutline,
  mdiArchiveOutline,
  mdiBriefcaseOutline,
  mdiCalendarPlusOutline,
  mdiCalendarRemoveOutline,
  mdiCartOutline,
  mdiCheckboxMultipleOutline,
  mdiClipboardAccountOutline,
  mdiCommentCheckOutline,
  mdiCommentTextMultipleOutline,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiFaceMan,
  mdiFileOutline,
  mdiLabelOutline,
  mdiLightbulbAlertOutline,
  mdiProgressCheck,
  mdiTagMultipleOutline,
  mdiTagOutline,
  mdiText,
} from '@mdi/js'

export const categoryList = [
  {
    id: 1,
    label: 'Job',
    icon: mdiBriefcaseOutline,
  },
  {
    id: 2,
    label: 'Status',
    icon: mdiProgressCheck,
  },
  {
    id: 3,
    label: 'Prioritas',
    icon: mdiLightbulbAlertOutline,
  },
  {
    id: 4,
    label: 'Waktu Ditutup',
    icon: mdiCalendarRemoveOutline,
  },
  {
    id: 5,
    label: 'Waktu Dimulai',
    icon: mdiCalendarPlusOutline,
  },
  {
    id: 6,
    label: 'Ditugaskan ke',
    icon: mdiClipboardAccountOutline,
  },
  {
    id: 7,
    label: 'Pelanggan',
    icon: mdiFaceMan,
  },
  {
    id: 8,
    label: 'Produk',
    icon: mdiCartOutline,
  },
  {
    id: 9,
    label: 'Tag',
    icon: mdiTagOutline,
  },
  {
    id: 10,
    label: 'Nama',
    icon: mdiLabelOutline,
  },
  {
    id: 11,
    label: 'Deskripsi',
    icon: mdiText,
  },
  {
    id: 12,
    label: 'Atribut Kustom',
    icon: mdiTagMultipleOutline,
  },
  {
    id: 13,
    label: 'Archive',
    icon: mdiArchiveOutline,
  },
  {
    id: 14,
    label: 'Unarchive',
    icon: mdiArchiveArrowUpOutline,
  },
  {
    id: 15,
    label: 'Delete',
    icon: mdiDeleteOutline,
  },
  {
    id: 16,
    label: 'Task',
    icon: mdiCheckboxMultipleOutline,
  },
  {
    id: 17,
    label: 'Attachment',
    icon: mdiFileOutline,
  },
  {
    id: 18,
    label: 'Watcher',
    icon: mdiEyeOutline,
  },
  {
    id: 19,
    label: 'Assign Comment',
    icon: mdiCommentTextMultipleOutline,
  },
  {
    id: 20,
    label: 'Resolve Comment',
    icon: mdiCommentCheckOutline,
  },
]

export const messageList = {
  1: 'membuat',
  11: 'mengubah deskripsi',
  13: 'men-archive',
  14: 'men-unarchive',
  15: 'menghapus',
  4: 'tanggal akhir',
  5: 'tanggal mulai',
}
