<template>
  <div class="d-flex flex-column overflow-hidden h-full">
    <div>
      <span class="caption ml-1 mb-1">{{ t('Tampilkan berdasarkan') }}</span>
    </div>

    <div class="d-flex mb-4">
      <v-chip
        class="d-flex flex-column align-stretch flex-fill mr-2 pl-3 pr-1 overflow-hidden"
        style="border-radius: 6px; height: 38px"
        :color="filterUnread ? 'primary' : ''"
        @click="filterUnread = !filterUnread"
      >
        <span class="text-subtitle-2 text-truncate">
          {{ filterUnread ? t('Hanya yang telah ter-Resolve') : t('Hanya yang belum ter-Resolve') }}
        </span>

        <v-icon
          v-if="filterUnread"
          class="ml-auto mr-2"
          size="20"
          style="color: white !important"
        >
          {{ icons.mdiCheck }}
        </v-icon>
      </v-chip>

      <v-menu
        bottom
        center
        offset-y
        :close-on-content-click="false"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="v-card v-sheet--outlined px-4"
            depressed
            outlined
            style="border-radius: 6px"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon size="20">
              {{ icons.mdiFilterVariant }}
            </v-icon>

            <span class="text-subtitle-1 font-weight-medium pl-2">{{ t('Urutkan') }}</span>

            <v-badge
              class="pl-1"
              inline
              v-show="filterCategory.id"
              :value="filterCategory.id"
              :content="filterCategory.id ? '1' : undefined"
            >
            </v-badge>
          </v-btn>
        </template>

        <v-card
          class="d-flex flex-column px-3 pt-2 pb-3"
          width="320px"
        >
          <v-row
            no-gutters
            dense
            class="mb-1"
          >
            <v-col>
              <span class="body-2">{{ t('Urutkan berdasarkan') }}</span>
            </v-col>

            <v-col
              class="py-0 px-0 d-flex justify-end"
              cols="4"
            >
              <v-btn
                text
                class="px-4"
                color="primary"
                style="height: unset !important; min-width: unset !important"
                @click="handleResetSort"
              >
                Reset
              </v-btn>
            </v-col>
          </v-row>

          <v-row
            class="mt-2"
            no-gutters
            dense
          >
            <v-chip
              class="d-flex flex-column align-stretch flex-fill pl-3 pr-1 overflow-hidden"
              style="border-radius: 6px; height: 38px"
              @click="handleToggleSortOrder"
            >
              <v-icon
                class="mr-2"
                size="20"
                style="color: white !important"
              >
                {{
                  commentsFilter.order === 'ASC' ? icons.mdiSortAscending : icons.mdiSortDescending
                }}
              </v-icon>

              <span class="text-subtitle-2 text-truncate">
                {{
                  commentsFilter.order === 'ASC'
                    ? t('Dari terendah ke tertinggi')
                    : t('Dari tertinggi ke terendah')
                }}
              </span>
            </v-chip>
          </v-row>

          <v-row
            class="mt-2"
            no-gutters
            dense
          >
            <v-autocomplete
              v-model="commentsFilter.field"
              solo
              flat
              dense
              outlined
              item-value="target"
              item-text="label"
              auto-select-first
              hide-details="auto"
              :items="commentSortFieldList"
              :placeholder="t('Berdasarkan ketegori')"
            >
              <template #selection="{ item }">
                <v-icon
                  class="mr-2"
                  size="20"
                >
                  {{ item.icon }}
                </v-icon>

                {{ item.label }}
              </template>

              <template #item="{ item }">
                <div class="d-flex align-center flex-grow-1 py-3">
                  <v-icon
                    class="mr-2"
                    size="20"
                  >
                    {{ item.icon }}
                  </v-icon>
                  <span class="subtitle-2"> {{ item.label }}</span>
                </div>
              </template>
            </v-autocomplete>
          </v-row>
        </v-card>
      </v-menu>
    </div>

    <div
      class="d-flex flex-column h-full overflow-auto px-1"
      style="max-height: 100%"
    >
      <template v-if="!filterUnread">
        <v-card
          v-for="(list, index) in commentsList.slice(0, 2)"
          :key="index"
          class="d-flex mb-2"
          outlined
        >
          <v-expansion-panels>
            <v-expansion-panel
              outlined
              class="custom-panel v-card v-sheet--outlined"
            >
              <v-expansion-panel-header
                class="py-2 px-4"
                style="min-height: unset !important"
              >
                <span class="text-subtitle-2 font-weight-semibold">
                  {{ list.title === 'job' ? 'Job' : 'Document' }}&nbsp;
                  <span style="opacity: 0.6">
                    {{ list.count }}
                  </span>
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content
                class="custom-panel-content overflow-auto mt-2 mb-2 px-2"
                style="max-height: 420px"
              >
                <template v-if="list.count === 0">
                  <v-alert
                    class="v-sheet--outlined mb-2 d-flex align-center justify-center flex-fill"
                    width="100%"
                    min-height="150px"
                  >
                    <span class="d-flex w-full text-subtitle-1 text-center">
                      Tidak ada Comment yang di-assign kepada pengguna
                    </span>
                  </v-alert>
                </template>

                <template v-else>
                  <v-card
                    v-for="(comment, index) in list.comments"
                    :key="index"
                    class="d-flex flex-column px-4 py-3 mb-2 overflow-hidden"
                    outlined
                    style="max-width: 100%"
                  >
                    <span
                      class="d-flex align-center mt-1 mb-2 text-subtitle-2 text-no-wrap font-weight-semibold overflow-hidden"
                      style="max-width: 100%"
                    >
                      <template v-if="comment.document">
                        <v-hover v-slot="{ hover }">
                          <span
                            :class="`${hover ? 'text-decoration-underline' : ''}`"
                            :style="{
                              transition: 'opacity 200ms linear',
                              cursor: 'pointer',
                            }"
                            @click="
                              $router.push({
                                name: 'document-detail',
                                params: { id: comment.document.uuid },
                              })
                            "
                            @click.stop
                          >
                            <v-icon
                              class="mb-1 mr-1"
                              size="20"
                            >
                              {{ icons.mdiFileDocumentOutline }} </v-icon
                            >{{ comment.document.name }}
                          </span>
                        </v-hover>
                      </template>

                      <template v-else-if="comment.job">
                        <v-hover v-slot="{ hover }">
                          <span
                            :class="`${hover ? 'text-decoration-underline' : ''}`"
                            :style="{
                              transition: 'opacity 200ms linear',
                              cursor: 'pointer',
                            }"
                            @click="
                              $router.push({
                                name: 'folder-detail',
                                params: { id: comment.job.job_type.folder.id },
                              })
                            "
                            @click.stop
                          >
                            <v-icon
                              class="mb-1 mr-1"
                              size="20"
                              :color="comment.job.job_type.folder.color"
                            >
                              {{ icons.mdiFolder }} </v-icon
                            >{{ comment.job.job_type.folder.name }}
                          </span>
                        </v-hover>
                        <span style="opacity: 0.6">&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                        <v-hover v-slot="{ hover }">
                          <span
                            :class="`text-truncate${hover ? ' text-decoration-underline' : ''}`"
                            :style="{
                              transition: 'opacity 200ms linear',
                              cursor: 'pointer',
                            }"
                            @click="
                              $router.push({
                                name: 'jobtype-detail',
                                params: {
                                  id: comment.job.job_type.folder.id,
                                  jobtypeid: comment.job.job_type.id,
                                },
                              })
                            "
                            @click.stop
                          >
                            <v-icon
                              class="mr-1"
                              size="10"
                              :color="comment.job.job_type.color"
                              style="margin-bottom: 2.5px"
                            >
                              {{ icons.mdiCircle }} </v-icon
                            >{{ comment.job.job_type.name }}
                          </span>
                        </v-hover>
                      </template>
                    </span>

                    <NotificationItemComment
                      class="mx-1"
                      :hide-details="
                        !isFullview || $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                      "
                      :comment="comment"
                      :job="comment?.job"
                      :attachment="comment?.attachment"
                      :document="comment?.document"
                    ></NotificationItemComment>
                  </v-card>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </template>

      <template v-else>
        <v-card
          v-for="(list, index) in commentsList.slice(2, 4)"
          :key="index"
          class="d-flex mb-2"
          outlined
        >
          <v-expansion-panels>
            <v-expansion-panel
              outlined
              class="custom-panel v-card v-sheet--outlined"
            >
              <v-expansion-panel-header
                class="py-2 px-4"
                style="min-height: unset !important"
              >
                <span class="text-subtitle-2 font-weight-semibold">
                  {{ list.title === 'job' ? 'Job' : 'Document' }}&nbsp;
                  <span style="opacity: 0.6">
                    {{ list.count }}
                  </span>
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content
                class="custom-panel-content overflow-auto mt-2 mb-2 px-2"
                style="max-height: 420px"
              >
                <template v-if="list.count === 0">
                  <v-alert
                    class="v-sheet--outlined mb-2 d-flex align-center justify-center flex-fill"
                    width="100%"
                    min-height="150px"
                  >
                    <span class="d-flex w-full text-subtitle-1 text-center">
                      Tidak ada Comment yang di-assign kepada pengguna
                    </span>
                  </v-alert>
                </template>

                <template v-else>
                  <v-card
                    v-for="(list, index) in list.comments"
                    :key="index"
                    class="d-flex flex-column px-4 py-3 mb-2 overflow-hidden"
                    outlined
                    style="max-width: 100%"
                  >
                    <span
                      class="d-flex align-center mt-1 mb-2 text-subtitle-2 text-no-wrap font-weight-semibold overflow-hidden"
                      style="max-width: 100%"
                    >
                      <template v-if="list.document">
                        <v-hover v-slot="{ hover }">
                          <span
                            :class="`${hover ? 'text-decoration-underline' : ''}`"
                            :style="{
                              transition: 'opacity 200ms linear',
                              cursor: 'pointer',
                            }"
                            @click="
                              $router.push({
                                name: 'document-detail',
                                params: { id: list.document.uuid },
                              })
                            "
                            @click.stop
                          >
                            <v-icon
                              class="mb-1 mr-1"
                              size="20"
                            >
                              {{ icons.mdiFileDocumentOutline }} </v-icon
                            >{{ list.document.name }}
                          </span>
                        </v-hover>
                      </template>
                      <template v-else-if="list.job">
                        <v-hover v-slot="{ hover }">
                          <span
                            :class="`${hover ? 'text-decoration-underline' : ''}`"
                            :style="{
                              transition: 'opacity 200ms linear',
                              cursor: 'pointer',
                            }"
                            @click="
                              $router.push({
                                name: 'folder-detail',
                                params: { id: list.job.job_type.folder.id },
                              })
                            "
                            @click.stop
                          >
                            <v-icon
                              class="mb-1 mr-1"
                              size="20"
                              :color="list.job.job_type.folder.color"
                            >
                              {{ icons.mdiFolder }} </v-icon
                            >{{ list.job.job_type.folder.name }}
                          </span>
                        </v-hover>
                        <span style="opacity: 0.6">&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                        <v-hover v-slot="{ hover }">
                          <span
                            :class="`text-truncate${hover ? ' text-decoration-underline' : ''}`"
                            :style="{
                              transition: 'opacity 200ms linear',
                              cursor: 'pointer',
                            }"
                            @click="
                              $router.push({
                                name: 'jobtype-detail',
                                params: {
                                  id: list.job.job_type.folder.id,
                                  jobtypeid: list.job.job_type.id,
                                },
                              })
                            "
                            @click.stop
                          >
                            <v-icon
                              class="mr-1"
                              size="10"
                              :color="list.job.job_type.color"
                              style="margin-bottom: 2.5px"
                            >
                              {{ icons.mdiCircle }} </v-icon
                            >{{ list.job.job_type.name }}
                          </span>
                        </v-hover>
                      </template>
                    </span>

                    <NotificationItemComment
                      class="mx-1"
                      :hide-details="
                        !isFullview || $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                      "
                      :comment="comment"
                      :job="comment?.job"
                      :attachment="comment?.attachment"
                      :document="comment?.document"
                    ></NotificationItemComment>
                  </v-card>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
import { commentSortFieldList } from '@/constants/user-detail/sortField'
import NotificationItemComment from '@/views/notification/components/NotificationItemComment.vue'
import { useUtils } from '@core/libs/i18n'
import {
  mdiCheck,
  mdiCircle,
  mdiFilterVariant,
  mdiFolder,
  mdiOpenInNew,
  mdiFileDocumentOutline,
  mdiSortAscending,
  mdiSortDescending,
} from '@mdi/js'
import { inject, ref } from 'vue'

export default {
  components: {
    NotificationItemComment,
  },

  setup() {
    const commentsList = inject('commentsList')
    const commentsFilter = inject('commentsFilter')
    const isFullview = inject('isFullview')
    const filterUnread = ref(false)
    const filterCategory = ref({
      id: null,
      label: 'Pilih kategori',
      icon: null,
      message: null,
    })

    const { t } = useUtils()

    const handleToggleSortOrder = () => {
      commentsFilter.value.order = commentsFilter.value.order === 'ASC' ? 'DESC' : 'ASC'
    }
    const handleResetSort = () => {
      commentsFilter.value = {
        order: 'DESC',
        field: 'created_at',
      }
    }

    return {
      // i18n
      t,

      commentsList,
      isFullview,
      filterUnread,
      filterCategory,
      commentsFilter,

      handleResetSort,
      handleToggleSortOrder,

      // constant
      commentSortFieldList,

      icons: {
        mdiFilterVariant,
        mdiCheck,
        mdiFolder,
        mdiCircle,
        mdiFileDocumentOutline,
        mdiOpenInNew,
        mdiSortAscending,
        mdiSortDescending,
      },
    }
  },
}
</script>
