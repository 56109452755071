import Vue from 'vue'
import { apolloClient } from '@/vue-apollo'
import { ref, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import {
  assignComment as assignCommentMutation,
  deleteComment as deleteCommentMutation,
  unassignComment as unassignCommentMutation,
  resolveUnresolveCommentMutation,
  updateComment as updateCommentMutation,
  addComment as addCommentMutation,
} from '@/graphql/mutations'
import { comments, commentsAssigned, commentSearch } from '@/graphql/queries'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'

const useComment = ({ pagination, search, jobId, attachmentId, documentId, objectType } = {}) => {
  const commentListOri = ref([])
  const commentList = ref([])
  const commentListSearch = ref([])
  const matchedIds = ref([])
  const commentCount = ref(0)
  const matchesCount = ref(0)
  const commentFetches = ref(0)
  const commentFilter = ref({
    pagination: {
      limit: 20,
      offset: 0,
    },
    search: null,
  })
  const loadingComments = ref(false)
  const loadingFiles = ref(false)

  const commentListRef = ref()
  const isInputEnable = ref(false)
  const currentTabComment = ref(0)
  const showSearch = ref(false)
  const isSearching = ref(false)
  const scrollTop = ref(0)
  const scrollHeight = ref(0)

  watch(currentTabComment, val => {
    if (val === 1) {
      isInputEnable.value = false
    }
  })

  const calculateScrollHeight = () => {
    const el = commentListRef.value

    if (el) {
      scrollHeight.value = el.scrollHeight
      scrollTop.value = el.scrollTop
    }
  }

  const initializeScroll = () => {
    const el = commentListRef.value

    if (el) {
      el.scroll({ top: el.scrollHeight, behavior: 'smooth' })
      calculateScrollHeight()
    }
  }

  const sortComment = () => {
    commentListOri.value = commentListOri.value.sort(
      (a, b) => new Date(Date.parse(a.created_at)) - new Date(Date.parse(b.created_at)),
    )
  }

  const replaceWithSearchedComment = () => {
    commentList.value = commentListOri.value.map(
      comment => commentListSearch.value.find(sComment => sComment.id === comment.id) || comment,
    )
  }

  const pushComment = list => {
    const filteredList = list.filter(el => !commentListOri.value.some(c => c.id === el.id))
    const updatedList = commentListOri.value.map(el => {
      const findOnNewList = list.find(l => el.id === l.id)

      if (findOnNewList) return findOnNewList

      return el
    })
    commentListOri.value = [...updatedList, ...filteredList]
  }

  const fetchComments = async ({ fetchMore = false } = {}) => {
    loadingComments.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: comments,
          fetchPolicy: 'no-cache',
          variables: {
            workspace_id: store.getters.getCurrentWorkspaceId,
            pagination:
              pagination && !fetchMore
                ? pagination
                : {
                    limit:
                      commentFilter.value.pagination.offset *
                      (commentFilter.value.pagination.limit + 1),
                    offset: 0,
                  },
            ...(objectType === 'attachment' && { attachment_id: attachmentId }),
            ...(objectType === 'job' && { job_id: jobId }),
            ...(objectType === 'document' && { document_uuid: documentId }),
            sort: {
              field: 'lexorank',
              order: 'ASC',
            },
          },
        })
        .then(result => {
          loadingComments.value = false
          commentCount.value = result.data.comments.count
          resolve(result.data.comments.comments)
          if (fetchMore) {
            pushComment(result.data.comments.comments)
          } else {
            commentListOri.value = result.data.comments.comments
          }

          if (objectType !== 'document') {
            sortComment()
          }

          commentList.value = commentListOri.value
        })
        .catch(err => {
          loadingComments.value = false
          reject(err)
          errorHandling(err)
        })
    })
  }

  const fetchCommentsMore = async () => {
    commentFilter.value.pagination.offset += 20
    commentFetches.value += 1
    const data = await fetchComments({ fetchMore: true })

    if (commentListSearch.value.length) {
      replaceWithSearchedComment()
    }

    return data
  }

  const searchComment = async () => {
    loadingComments.value = true
    await apolloClient
      .query({
        query: commentSearch,
        fetchPolicy: 'no-cache',
        variables: {
          search: search && search.value ? search.value : commentFilter.value.search,
          ...(objectType === 'attachment' && { attachment_id: attachmentId }),
          ...(objectType === 'job' && { job_id: jobId }),
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      })
      .then(result => {
        loadingComments.value = false
        matchesCount.value = result.data.commentSearch.count
        matchedIds.value = result.data.commentSearch.comments.map(comment => comment.id)

        replaceWithSearchedComment()
      })
      .catch(err => {
        loadingComments.value = false
        errorHandling(err)
      })
  }

  const resetComments = async () => {
    commentListSearch.value = []
    matchesCount.value = 0
    commentList.value = commentListOri.value
  }

  const listenScrollFetchMoreComment = useDebounceFn(async data => {
    if (
      data.target.scrollTop === 0 &&
      commentFilter.value.pagination.offset <= commentCount.value
    ) {
      await fetchCommentsMore()
    }
  }, 200)

  const addComment = ({ jobId, attachmentId, documentUUID, comment, replyToId }) => {
    apolloClient
      .mutate({
        mutation: addCommentMutation,
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          reply_to_comment_id: replyToId || null,
          job_id: jobId || null,
          attachment_id: attachmentId || null,
          document_uuid: documentUUID || null,
          comment: comment,
        },
      })
      .then(() => {
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil men-reply komentar!',
        })
      })
      .catch(err => {
        errorHandling(err)
      })
  }

  const deleteComment = id =>
    new Promise((resolve, reject) => {
      Vue.$dialog({
        title: 'Hapus komentar ini?',
        body: 'Anda yakin ingin menghapus komentar ini?',
      }).then(confirm => {
        if (confirm) {
          loadingComments.value = true

          apolloClient
            .mutate({
              mutation: deleteCommentMutation,
              variables: {
                id,
                workspace_id: store.getters.getCurrentWorkspaceId,
              },
            })
            .then(result => {
              loadingComments.value = false

              Vue.notify({
                title: 'Sukses!',
                text: 'Berhasil menghapus komentar.',
              })
              fetchComments()

              resolve(result)
            })
            .catch(err => {
              loadingComments.value = false

              reject(err)
              errorHandling(err, 'Hapus Komentar')
            })
        } else {
          reject()
        }
      })
    })

  const updateComment = (id, comment) => {
    const variables = {
      id,
      comment,
      workspace_id: store.getters.getCurrentWorkspaceId,
    }

    apolloClient
      .mutate({
        mutation: updateCommentMutation,
        variables,
      })
      .then(() => {})
      .catch(err => {
        errorHandling(err)
      })
  }

  const resolveUnResolveComment = commentId => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: resolveUnresolveCommentMutation,
          variables: {
            comment_id: commentId,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(result => {
          loadingComments.value = false

          // fetchComments()

          resolve(result)
        })
        .catch(err => {
          loadingComments.value = false

          reject(err)
          errorHandling('err', 'Resolve Unresolve Komentar')
        })
    })
  }

  const assignComment = e => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: assignCommentMutation,
          variables: {
            comment_id: e.commentId,
            workspace_id: store.getters.getCurrentWorkspaceId,
            id_user: e.idUser,
          },
        })
        .then(result => {
          loadingComments.value = false

          // fetchComments()

          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil assign komentar.',
          })

          resolve(result)
        })
        .catch(err => {
          loadingComments.value = false

          reject(err)
          errorHandling(err)
        })
    })
  }

  const unassignComment = commentId => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: unassignCommentMutation,
          variables: {
            comment_id: commentId,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(result => {
          loadingComments.value = false

          // fetchComments()

          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil unassign komentar.',
          })

          resolve(result)
        })
        .catch(err => {
          loadingComments.value = false

          reject(err)
          errorHandling(err)
        })
    })
  }

  const updateCommentLexorank = (commentId, lexorank) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: updateCommentLexorank,
          variables: {
            id: commentId,
            workspace_id: store.getters.getCurrentWorkspaceId,
            lexorank,
          },
        })
        .then(result => {
          loadingComments.value = false

          // fetchComments()

          resolve(result)
        })
        .catch(err => {
          loadingComments.value = false

          reject(err)
          errorHandling(err)
        })
    })
  }

  const fetchAssignedComments = (pagination, user_id, isResolved, tabFor, sort) =>
    new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: commentsAssigned,
          fetchPolicy: 'no-cache',
          variables: {
            pagination: pagination,
            filter: {
              tab_for: tabFor,
              sort: sort,
            },
            isResolved,
            workspaceId: store.getters.getCurrentWorkspaceId,
            userId: user_id,
          },
        })
        .then(({ data }) => {
          resolve({ comments: data.commentsAssigned.comments, count: data.commentsAssigned.count })
        })
        .catch(err => {
          errorHandling(err)
          reject(err)
        })
    })

  return {
    commentList,
    commentListSearch,
    commentCount,
    matchesCount,
    loadingComments,
    loadingFiles,
    matchedIds,

    commentListRef,
    isInputEnable,
    currentTabComment,
    showSearch,
    isSearching,
    scrollHeight,
    scrollTop,

    calculateScrollHeight,
    initializeScroll,
    fetchComments,
    fetchCommentsMore,
    searchComment,
    resetComments,
    listenScrollFetchMoreComment,
    addComment,
    deleteComment,
    updateComment,
    resolveUnResolveComment,
    assignComment,
    unassignComment,
    updateCommentLexorank,

    fetchAssignedComments,
  }
}

export default useComment
