<template>
  <v-menu
    v-model="editMode"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
        :editMode="editMode"
      >
        <v-btn
          text
          v-on="on"
          v-bind="attrs"
          x-small
          elevation="0"
          class="font-weight-regular"
          style="padding-left: 5px; padding-right: 5px"
          @click.prevent.stop="editMode = true"
        >
          <slot>
            <v-chip
              v-if="value"
              x-small
              label
              style="cursor: pointer"
              :color="value.color"
            >
              {{ value.name }}
            </v-chip>
            <span v-else>-</span>
          </slot>
        </v-btn>
      </slot>
    </template>

    <ItemSelect
      v-model="localValue"
      :items="items"
    />
  </v-menu>
</template>

<script setup>
import { ref, watch } from 'vue'
import ItemSelect from './components/ItemSelect.vue'

const emit = defineEmits(['input'])
const props = defineProps({
  // id, name, color (optional)
  value: {
    type: Object,
    default: null,
  },
  items: {
    type: Array,
    default: () => [],
  },
  deletable: {
    type: Boolean,
    default: false,
  },
})

const localValue = ref(props.value)
const editMode = ref(false)

watch(editMode, val => {
  if (!val) emit('input', localValue.value)
})
</script>
