import gql from 'graphql-tag'

const commentsAssigned = gql`
  query CommentsAssigned(
    $filter: paramCommentsAssigned!
    $workspaceId: Float!
    $isResolved: String!
    $userId: [Float!]!
    $pagination: pagination
  ) {
    commentsAssigned(
      filter: $filter
      workspace_id: $workspaceId
      is_resolved: $isResolved
      user_id: $userId
      pagination: $pagination
    ) {
      count
      comments {
        id
        comment
        user {
          id
          name
          photo
        }
        created_at
        updated_at
        deleted_at
        resolved_at
        lexorank
        assigned_user {
          id
          name
          photo
        }

        parent {
          id
          comment
          user {
            id
            name
            photo
          }
          created_at
          updated_at
          deleted_at
          resolved_at
          lexorank
          assigned_user {
            id
            name
            photo
          }
        }

        document {
          uuid
          name
          folder {
            id
            name
          }
        }

        job {
          id
          name
          job_type {
            id
            name
            color
            folder {
              id
              name
              color
            }
          }
        }

        attachment {
          id
          file_name
          file_type
        }
      }
    }
  }
`

export default commentsAssigned
