import gql from 'graphql-tag'

export const automations = gql`
  query Automations(
    $filter: ParamQueryAutomation!
    $workspaceId: Float!
    $pagination: pagination!
    $jobTypeId: Float
    $folderId: Float
  ) {
    automations(
      filter: $filter
      workspace_id: $workspaceId
      pagination: $pagination
      job_type_id: $jobTypeId
      folder_id: $folderId
    ) {
      count

      data {
        id
        name
        description
        is_active
        is_automation_locked
        group_operator

        created_by {
          id
          name
          photo
        }
      }
    }
  }
`
