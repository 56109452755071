/* eslint-disable no-restricted-syntax */
import { permissionSpliter } from './permissionSplitter'

/**
 * @function
 * @param {import("./definition/acStoreParser").IPermissionList} permissionList
 * @param {import("./definition/acStoreParser").IPermissionData} permissionData
 * @returns {{action:string, subject:string}[]}
 */
export const acStoreParser = (permissionList, permissionData) => {
  const permissionObject = ['create', 'read', 'update', 'del']
  let splittedPermission = {}
  const finalPermission = []
  for (const key in permissionData) {
    if (permissionObject.includes(key)) {
      splittedPermission = { ...splittedPermission, [key]: permissionSpliter(permissionData[key]) }
    }
  }
  for (const key in splittedPermission) {
    if (!splittedPermission[key].includes(0)) {
      splittedPermission[key].map(val => {
        const findResult = permissionList.find(v => v.id === val)
        finalPermission.push({ action: `${key}`, subject: `${findResult?.name}` })

        return ''
      })
    }
  }

  return finalPermission
}
