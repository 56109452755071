<template>
  <v-dialog
    v-model="isOpen"
    max-width="1000"
  >
    <v-card height="60vh">
      <div class="d-flex pa-4">
        <h4>Set Target</h4>
        <v-icon
          class="ml-auto"
          @click="isOpen = false"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-divider />
      <div class="pa-4">
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-list
              nav
              class="pa-0"
            >
              <v-list-item
                v-for="item in object"
                :key="item.name"
                class="d-flex target-object mb-2"
                @click.stop
              >
                <v-icon class="mr-4">
                  {{ item.icon }}
                </v-icon>
                <span class="text-subtitle-2">
                  {{ item.name }}
                </span>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col
            cols="12"
            md="8"
          >
            <v-card
              outlined
              class="pa-3"
            >
              hola
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from 'vue'
import { mdiClose, mdiTicketAccount, mdiTagHeart, mdiForum, mdiCalendarText } from '@mdi/js'

export default {
  setup() {
    const isOpen = ref(false)

    const object = ref([
      {
        name: 'Job',
        icon: mdiTicketAccount,
      },
      {
        name: 'Prospek',
        icon: mdiTagHeart,
      },
      {
        name: 'Chat',
        icon: mdiForum,
      },
      {
        name: 'Task',
        icon: mdiCalendarText,
      },
    ])

    return {
      isOpen,
      object,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';

.target-object {
  border: 1px rgb(240, 240, 240) solid;

  .theme--dark & {
    border: 1px map-deep-get($material-dark, 'dividers') solid;
  }
}
</style>
