<template>
  <v-card
    class="pt-0 pb-5 px-5 d-flex flex-column flex-fill overflow-auto"
    id="user-view"
    style="max-height: 100%"
  >
    <v-row
      :class="`${
        (isFullview && $vuetify.breakpoint.lg) || (isFullview && $vuetify.breakpoint.xl)
          ? 'overflow-hidden'
          : ''
      }`"
      dense
      no-gutters
      align-content="start"
    >
      <v-col
        cols="12"
        :lg="isFullview ? 3 : 12"
        :style="{
          height:
            (isFullview && $vuetify.breakpoint.lg) || (isFullview && $vuetify.breakpoint.xl)
              ? '100%'
              : 'unset',
          maxHeight: '100%',
        }"
      >
        <div
          class="d-flex flex-column overflow-hidden h-full"
          style="max-height: 100%"
        >
          <v-card
            class="pa-4 mb-4 d-flex flex-column align-center justify-center"
            outlined
          >
            <v-avatar
              size="80"
              class="v-avatar-light-bg mb-2"
            >
              {{ avatarText(`${userDetail.user.first_name} ${userDetail.user.last_name}`) }}
            </v-avatar>

            <h6 class="text-h6 font-weight-bold primary--text text-center">
              {{ userDetail.user.first_name }} {{ userDetail.user.last_name }}
            </h6>
            <span class="text-subtitle-1 text-center">{{ userDetail.user.email }}</span>
            <span class="text-subtitle-2 text-center">{{ userDetail.user.phone }}</span>

            <div class="d-flex mt-3 mb-4">
              <v-chip
                small
                color="primary"
                class="mx-1 v-chip-light-bg primary--text"
              >
                {{ userDetail.role.name }}
              </v-chip>
              <v-chip
                small
                class="mx-1"
                :color="userStatusColor(userDetail.user.user_status.id)"
              >
                {{ userDetail.user.user_status.name }}
              </v-chip>
            </div>

            <v-card
              outlined
              class="pa-3 d-flex justify-space-between w-100 align-center"
            >
              <span class="text-subtitle-2">Ketersediaan</span>
              <v-switch
                :value="userDetail.user.availability"
                disabled
                hide-details
                dense
                class="ma-0"
              />
            </v-card>
          </v-card>

          <v-card
            :class="`d-flex py-2 px-4 ${
              (isFullview && $vuetify.breakpoint.lg) || (isFullview && $vuetify.breakpoint.xl)
                ? 'overflow-hidden h-full'
                : 'mb-4'
            }`"
            outlined
          >
            <template v-if="userTeams.length === 0">
              <v-alert
                class="mt-2 mb-2 d-flex align-center justify-center"
                elevation="2"
                width="100%"
              >
                <span>Pengguna tidak bergabung ke dalam tim.</span>
              </v-alert>
            </template>

            <template v-else>
              <v-expansion-panels
                :value="isFullview ? 0 : undefined"
                :readonly="isFullview"
              >
                <v-expansion-panel
                  outlined
                  key="0"
                  class="custom-panel v-card v-sheet--outlined"
                  style="display: flex; overflow: hidden; max-height: 100%; flex-direction: column"
                >
                  <v-expansion-panel-header class="py-0 px-2">
                    <span class="text-subtitle-1 font-weight-medium">Tim</span>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content
                    class="custom-panel-content overflow-auto mb-2"
                    :style="{
                      maxHeight:
                        !isFullview || $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                          ? '420px'
                          : '100%',
                    }"
                  >
                    <template v-for="team in userTeams">
                      <v-card
                        v-if="!successLeaveTeam.includes(team.id)"
                        outlined
                        :key="team.id"
                        class="px-4 py-3 d-flex flex-column mb-2"
                      >
                        <div class="d-flex align-center">
                          <router-link
                            class="text-subtitle-2 primary--text pa-0 ma-0 text-truncate font-weight-semibold d-inline-block"
                            style="max-width: 125px"
                            :to="{
                              name: 'team-detail',
                              params: {
                                id: team.id,
                              },
                            }"
                          >
                            <v-icon
                              v-if="team.archived_at"
                              size="18"
                              class="ms-1"
                            >
                              {{ icons.mdiArchive }}
                            </v-icon>

                            {{ team.name }}
                          </router-link>

                          <v-chip
                            class="ml-2 px-3"
                            small
                            active
                            style="height: 20px"
                          >
                            {{
                              team.users.length > 0 &&
                              team.users.filter(tu => tu.user.id == idUser)[0]?.permission_type
                            }}
                          </v-chip>
                        </div>

                        <div class="d-flex flex-column">
                          <template v-if="isJoinedTeam(team) && currentUserId === idUser">
                            <v-btn
                              v-if="team.is_public"
                              class="items-center w-full mt-3"
                              style="min-width: 80px"
                              small
                              variant="outlined"
                              color="error"
                              @click="
                                handleLeaveTeam({
                                  team_id: team.id,
                                })
                              "
                              :loading="isLoadingTeam.includes(team.id)"
                              v-show="!successLeaveTeam.includes(team.id)"
                            >
                              Leave Team
                            </v-btn>
                            <v-btn
                              v-else
                              class="items-center w-full mt-3"
                              small
                              style="min-width: 80px"
                              variant="outlined"
                              color="error"
                              @click="
                                handleLeaveTeam({
                                  team_id: team.id,
                                })
                              "
                              :loading="isLoadingTeam.includes(team.id)"
                              v-show="!successLeaveTeam.includes(team.id)"
                            >
                              Leave Team
                            </v-btn>
                          </template>

                          <template
                            v-if="!isJoinedTeam(team) || successLeaveTeam.includes(team.id)"
                          >
                            <v-btn
                              v-if="team.is_public && !successJoinTeam.includes(team.id)"
                              class="items-center w-full mt-3"
                              style="min-width: 80px"
                              small
                              color="primary"
                              @click="
                                handleJoinTeam({
                                  id: team.id,
                                  isPublic: team.is_public,
                                })
                              "
                              :loading="isLoadingTeam.includes(team.id)"
                            >
                              Join
                            </v-btn>
                            <v-btn
                              v-else-if="!successRequestJoinTeam.includes(team.id)"
                              class="items-center w-full mt-3"
                              small
                              style="min-width: 80px"
                              color="primary"
                              @click="
                                handleJoinTeam({
                                  id: team.id,
                                  isPublic: team.is_public,
                                })
                              "
                              :loading="isLoadingTeam.includes(team.id)"
                            >
                              Request Join
                            </v-btn>
                            <v-btn
                              v-else-if="
                                successRequestJoinTeam.includes(team.id) || isRequestedTeam(team)
                              "
                              class="items-center w-full mt-3"
                              small
                              style="min-width: 80px"
                              color="primary"
                              @click="
                                handleJoinTeam({
                                  id: team.id,
                                  isPublic: team.is_public,
                                })
                              "
                              :loading="isLoadingTeam.includes(team.id)"
                            >
                              Re-request Join
                            </v-btn>
                          </template>
                        </div>
                      </v-card>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
          </v-card>
        </div>
      </v-col>

      <v-col
        v-if="!isLoading"
        class="w-full"
        cols="12"
        :lg="isFullview ? 9 : 12"
        :style="{
          height:
            (isFullview && $vuetify.breakpoint.lg) || (isFullview && $vuetify.breakpoint.xl)
              ? '100%'
              : 'unset',
          maxHeight:
            (isFullview && $vuetify.breakpoint.lg) || (isFullview && $vuetify.breakpoint.xl)
              ? '100%'
              : '500px',
        }"
      >
        <v-card
          :class="`pa-4 h-full d-flex flex-column flex-fill overflow-hidden ${
            (isFullview && $vuetify.breakpoint.lg) || (isFullview && $vuetify.breakpoint.xl)
              ? 'ml-4'
              : ''
          }`"
          outlined
        >
          <v-tabs
            v-model="currentTab"
            class="user-tabs mb-3"
            style="flex: 0"
            show-arrows
            centered
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.label"
              class="w-full"
            >
              <v-icon
                size="20"
                class="me-3"
              >
                {{ tab.icon }}
              </v-icon>
              <span class="text-capitalize text-subtitle-2">{{ tab.label }}</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items
            class="flex-fill h-full overflow-hidden px-2"
            id="user-tabs-content"
            v-model="currentTab"
            touchless
          >
            <v-tab-item class="h-full">
              <UserDetailReferenceLog @handleScroll="handleReferenceLogsByUser" />
            </v-tab-item>

            <v-tab-item class="h-full">
              <UserDetailTrackJob @handleScrollJob="handleEachJobsAssignedUserPagination" />
            </v-tab-item>

            <v-tab-item class="h-full">
              <UserDetailAssignedComment />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <user-target-form ref="userTargetForm" />
  </v-card>
</template>

<script>
import useTeam from '@/composables/useTeam'
import useUser from '@/composables/useUser'
import { leaveTeam } from '@/graphql/mutations'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { avatarText } from '@core/utils/filter'
import {
  mdiArchive,
  mdiComment,
  mdiInformation,
  mdiLibrary,
  mdiOpenInNew,
  mdiTicketAccount,
} from '@mdi/js'
import Vue, { computed, inject, onMounted, provide, ref, watch } from 'vue'
// import UserComment from './components/UserComment.vue'
// import UserJob from './components/UserJob.vue'
// import UserReferenceLog from './components/UserReferenceLog.vue'
import useComment from '@/composables/useComment'
import useJob from '@/composables/useJob'
import useTimeline from '@/composables/useTimeline'
import { categoryList } from '@/constants/timeline'
import UserTargetForm from './UserTargetForm.vue'
import UserDetailAssignedComment from './components/UserDetailAssignedComment.vue'
import UserDetailReferenceLog from './components/UserDetailReferenceLog.vue'
import UserDetailTrackJob from './components/UserDetailTrackJob.vue'
import { useDebounceFn } from '@vueuse/core'

export default {
  components: {
    UserTargetForm,
    // UserPerformance,
    // UserReferenceLog,
    // UserActivity,
    // UserJob,
    // UserComment,
    // UserTask,

    UserDetailReferenceLog,
    UserDetailTrackJob,
    UserDetailAssignedComment,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
      required: true,
    },
  },
  setup(props) {
    const idUser = computed(() => Number(props.id))
    const TEAM_PREFIX = 't'
    const isTeam = item => item.key.startsWith(TEAM_PREFIX)
    const isJoinedObject = item => item.status_permission !== 0
    const isJoinedTeam = item => item.status === 'joined'
    const isRequestedTeam = item => item.status === 'requested'
    const ownerId = ref()
    const { joinTeam, requestJoin } = useTeam()
    // const loadingTeam = ref([])
    const successJoinTeam = ref([])
    const successRequestJoinTeam = ref([])
    const successLeaveTeam = ref([])
    const isLoadingTeam = ref([])
    const loadingSkeleton = ref(true)
    const currentUserId = ref(store.getters.getUserData.id)
    const showToast = (title, text, icon, type = 'error') => {
      Vue.notify({
        title,
        type,
        icon,
        text,
      })
    }
    const leaveTeamMutate = ({ team_id }) => {
      apolloClient
        .mutate({
          mutation: leaveTeam,
          variables: {
            team_id,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(() => {
          showToast('Berhasil', 'Anda telah berhasil meninggalkan Tim', 'success')
          successLeaveTeam.value.push(team_id)
          const index = isLoadingTeam.value.indexOf(team_id)
          if (index != undefined && index >= 0) isLoadingTeam.value.splice(index, 1)
          const index2 = successJoinTeam.value.indexOf(team_id)
          if (index2 != undefined && index2 >= 0) successJoinTeam.value.splice(index2, 1)
          const index3 = successRequestJoinTeam.value.indexOf(team_id)
          if (index3 != undefined && index3 >= 0) successRequestJoinTeam.value.splice(index3, 1)
        })
        .catch(err => {
          errorHandling(err)
          const index = isLoadingTeam.value.indexOf(team_id)
          if (index != undefined && index >= 0) isLoadingTeam.value.splice(index, 1)
        })
    }

    const handleLeaveTeam = ({ team_id }) => {
      Vue.$dialog({
        title: 'Keluar dari tim?',
        body: 'Konfirmasi jika anda ingin keluar dari tim ini.',
      }).then(async confirm => {
        if (confirm) {
          isLoadingTeam.value.push(team_id)
          try {
            await leaveTeamMutate({ team_id })
            await store.dispatch('team/fetchTeams')
          } catch (err) {
            //
          }
        }
      })
    }
    const handleJoinTeam = async ({ id, isPublic }) => {
      isLoadingTeam.value.push(id)
      if (isPublic) {
        await joinTeam(id)
          .then(() => {
            const index = isLoadingTeam.value.indexOf(id)
            if (index != undefined && index >= 0) isLoadingTeam.value.splice(index, 1)
            const index2 = successLeaveTeam.value.indexOf(id)
            if (index2 != undefined && index2 >= 0) successLeaveTeam.value.splice(index2, 1)
            successJoinTeam.value.push(id)
            showToast('Berhasil', 'Anda telah berhasil bergabung dalam Tim', 'success')
          })
          .catch(err => {
            errorHandling(err)
            const index = isLoadingTeam.value.indexOf(id)
            if (index != undefined && index >= 0) isLoadingTeam.value.splice(index, 1)
          })
      } else {
        await requestJoin(id)
          .then(() => {
            const index = isLoadingTeam.value.indexOf(id)
            if (index != undefined && index >= 0) isLoadingTeam.value.splice(index, 1)
            successRequestJoinTeam.value.push(id)
            showToast('Berhasil', 'Request Terkirim', 'icon: mdiLibrary', 'success')
          })
          .catch(err => {
            errorHandling(err)
            const index = isLoadingTeam.value.indexOf(id)
            if (index != undefined && index >= 0) isLoadingTeam.value.splice(index, 1)
          })
      }
    }

    const getRoleInTeam = async team => {
      const index = team.users.findIndex(el => el.user.id === idUser.value)

      return team.users[index]?.permission_type
    }

    const loadingDetail = ref(true)
    const detail = ref({})
    const teamsResultList = ref([])
    const teamsResultCount = ref(0)
    const handleInvite = () => console.log('invite to object')
    const currentTab = ref(0)
    const perfectScrollbarOptions = {
      maxScrollbarLength: 200,
      wheelPropagation: false,
    }
    const tabs = ref([
      {
        label: 'Aktivitas',
        icon: mdiLibrary,
      },
      {
        label: 'Job',
        icon: mdiTicketAccount,
      },
      {
        label: 'Comment',
        icon: mdiComment,
      },
    ])

    const { userStatusColor } = useUser()

    // --------------------------------- @dev-deeka --------------------------------- //
    const isLoading = ref(true)
    const userId = computed(() => Number(props.id))
    const userDetail = ref(
      store.state.workspace.selectedWorkspace.workspace.members.find(
        el => el.user.id === Number(props.id),
      ),
    )
    const userTeams = ref([])
    const pagination = ref({
      limit: 10,
      offset: 0,
    })
    const referenceLogsPagination = ref({
      count: 0,
      current: 0,
      control: {
        limit: 75,
        offset: 0,
      },
    })
    const referenceLogsList = ref({})
    const referenceLogsFilter = ref(categoryList.map(category => category.id))
    const referenceLogsSearch = ref('')
    const jobsList = ref({})
    const jobsFilter = ref({
      order: 'DESC',
      field: 'name',
    })
    const commentsList = ref([])
    const commentsFilter = ref({
      order: 'DESC',
      field: 'created_at',
    })
    const { fetchReferenceLogsByUser } = useTimeline()
    const { fetchJobsAssignedUser } = useJob()
    const { fetchAssignedComments } = useComment()
    const { fetchTeams } = useTeam()

    const handleReferenceLogsByUser = async () => {
      await fetchReferenceLogsByUser(
        referenceLogsPagination.value.control,
        idUser.value,
        referenceLogsFilter.value,
        referenceLogsSearch.value,
      ).then(data => {
        referenceLogsPagination.value.count = data.count
        referenceLogsPagination.value.current += data.referenceLogs.length
        referenceLogsPagination.value.control.offset = referenceLogsPagination.value.current

        data.referenceLogs.map((item, index) => {
          if (index > 0 && item.job.id === data.referenceLogs[index - 1].job.id)
            return referenceLogsList.value[item.job.id].logList.push(item)

          referenceLogsList.value = {
            ...referenceLogsList.value,
            [item.job.id]: {
              id: item.id,
              job: item.job,

              logList: [item],
            },
          }
        })
      })
    }

    const handleJobsAssignedUser = async () => {
      for (const tabFor of [
        {
          target: 'to_day',
          title: 'Hari Ini',
          placeholder: 'Tidak ada job untuk dikerjakan hari ini.',
        },
        {
          target: 'late',
          title: 'Terlambat',
          placeholder: 'Tidak ada job yang terlambat saat ini.',
        },
        {
          target: 'next',
          title: 'Selanjutnya',
          placeholder: 'Belum ada job untuk selanjutnya.',
        },
        {
          target: 'assigned',
          title: 'Ditugaskan',
          placeholder: 'Belum ada job yang ditugaskan saat ini.',
        },
        {
          target: 'done',
          title: 'Terselesaikan',
          placeholder: 'Belum ada job yang terselesaikan.',
        },
      ]) {
        try {
          const data = await fetchJobsAssignedUser(
            pagination.value,
            idUser.value,
            tabFor.target,
            jobsFilter.value,
          )

          jobsList.value = {
            ...jobsList.value,
            [tabFor.target]: {
              key: tabFor.target,
              title: tabFor.title,
              placeholder: tabFor.placeholder,
              count: data.count,
              pagination: {
                limit: pagination.value.limit,
                offset: pagination.value.offset + data.jobs.length,
              },
              jobs: data.jobs,
            },
          }
        } catch (error) {
          console.error(`Error fetching data for ${tabFor}:`, error)
        }
      }
    }
    const handleEachJobsAssignedUserPagination = tabFor => {
      console.log(tabFor)

      fetchJobsAssignedUser(
        jobsList.value[tabFor].pagination,
        idUser.value,
        tabFor,
        jobsFilter.value,
      ).then(data => {
        jobsList.value[tabFor].pagination.offset += data.jobs.length
        jobsList.value[tabFor].jobs.push(...data.jobs)
      })
    }

    const handleAssignedComments = async () => {
      for (const tabFor of [
        { id: 0, target: 'job', accessor: 'job', indentify: 'id', resolve: 'FALSE' },
        { id: 1, target: 'doc', accessor: 'document', indentify: 'uuid', resolve: 'FALSE' },
        { id: 2, target: 'job', accessor: 'job', indentify: 'id', resolve: 'TRUE' },
        { id: 3, target: 'doc', accessor: 'document', indentify: 'uuid', resolve: 'TRUE' },
      ]) {
        try {
          const data = await fetchAssignedComments(
            pagination.value,
            idUser.value,
            tabFor.resolve,
            tabFor.target,
            commentsFilter.value,
          )

          let commentsGroup = {} || commentsList.value[tabFor.id].comments

          data.comments.map((item, index) => {
            if (
              index > 0 &&
              item[tabFor.accessor][tabFor.indentify] ===
                data.comments[index - 1][tabFor.accessor][tabFor.indentify]
            )
              return commentsList.value[tabFor.id].commentsGroup[
                [tabFor.accessor][tabFor.indentify]
              ].push(item)

            commentsGroup = {
              ...commentsGroup,
              [item[tabFor.accessor][tabFor.indentify]]: [item],
            }
          })

          if (commentsList.value[tabFor.id])
            return (commentsList.value[tabFor.id] = {
              title: tabFor.target,
              count: data.count,
              list: commentsGroup,
            })

          commentsList.value.push({
            title: tabFor.target,
            count: data.count,
            list: commentsGroup,
          })
        } catch (error) {
          console.error(`Error fetching data for ${tabFor}:`, error)
        }
      }
    }

    const handleTeams = async () => {
      await fetchTeams({
        search: null,
        sort: null,
        pagination: pagination.value,
        filter: null,
        user_id: userId.value,
      }).then(data => (userTeams.value = data.teams))
    }

    const handleDataFetch = async () => {
      referenceLogsList.value = {}
      jobsList.value = {}
      commentsList.value = []

      userDetail.value = store.state.workspace.selectedWorkspace.workspace.members.find(
        el => el.user.id === Number(props.id),
      )

      handleTeams()

      handleReferenceLogsByUser()
      handleJobsAssignedUser()
      handleAssignedComments()

      isLoading.value = false
    }

    const isFullview = inject('isFullview')
    provide('isLoading', isLoading)
    provide('userId', userId)
    provide('referenceLogsPagination', referenceLogsPagination)
    provide('referenceLogsList', referenceLogsList)
    provide('referenceLogsFilter', referenceLogsFilter)
    provide('referenceLogsSearch', referenceLogsSearch)
    provide('jobsList', jobsList)
    provide('jobsFilter', jobsFilter)
    provide('commentsList', commentsList)
    provide('commentsFilter', commentsFilter)

    watch(userId, () => {
      handleDataFetch()
    })

    watch(
      [referenceLogsFilter, referenceLogsSearch],
      useDebounceFn(() => handleReferenceLogsByUser(), 200),
    )

    watch(jobsFilter, () => handleJobsAssignedUser(), {
      deep: true,
    })

    watch(commentsFilter, () => handleAssignedComments(), {
      deep: true,
    })

    onMounted(() => {
      handleDataFetch()
    })

    return {
      isLoading,
      userDetail,
      userTeams,
      isFullview,

      handleReferenceLogsByUser,
      handleEachJobsAssignedUserPagination,

      fetchTeams,
      loadingDetail,
      detail,
      tabs,
      avatarText,
      currentTab,
      userStatusColor,
      icons: {
        mdiInformation,
        mdiOpenInNew,
        mdiComment,
        mdiArchive,
      },
      idUser,
      teamsResultList,
      teamsResultCount,
      handleJoinTeam,
      isJoinedTeam,
      isRequestedTeam,
      isTeam,
      // loadingTeam,
      successJoinTeam,
      successRequestJoinTeam,
      isJoinedObject,
      ownerId,
      handleInvite,
      isLoadingTeam,
      perfectScrollbarOptions,
      currentUserId,
      showToast,
      handleLeaveTeam,
      leaveTeam,
      leaveTeamMutate,
      successLeaveTeam,
      loadingSkeleton,
      getRoleInTeam,
    }
  },
}
</script>
