import gql from 'graphql-tag'

export const automationLog = gql`
  query AutomationLog(
    $jobTypeId: Float!
    $folderId: Float!
    $filter: FilterAutomationLog!
    $pagination: pagination!
  ) {
    automationLog(
      job_type_id: $jobTypeId
      folder_id: $folderId
      filter: $filter
      pagination: $pagination
    ) {
      count

      data {
        id
        history
        is_success
        executed_at

        automation {
          id
          name
        }
        job {
          id
          name
        }
        document {
          uuid
          name
        }
        triggered_by {
          id
          name
          photo
        }
      }
    }
  }
`
