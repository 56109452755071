<!-- Taken example from TimelineList.vue -->

<template>
  <div
    style="max-width: 100%"
    :class="`notification-reference-log d-flex flex-fill ${showfull ? '' : 'text-truncate'}`"
  >
    <!-- user avatar -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-avatar
          v-bind="attrs"
          size="30"
          class="v-avatar-light-bg elevation-1 cursor-pointer"
          v-on="on"
          @click="
            router.push({
              name: 'user-detail',
              params: { id: referenceLog.created_by.id },
            })
          "
          @click.stop
        >
          <v-img
            v-if="referenceLog.created_by.photo"
            :src="referenceLog.created_by.photo"
          />
          <span
            class="caption"
            v-else
          >
            {{
              avatarText(
                referenceLog.created_by.id === store.getters.getUserData.id
                  ? 'U'
                  : referenceLog.created_by.name,
              )
            }}</span
          >
        </v-avatar>
      </template>
      <span>{{
        referenceLog.created_by.id === store.getters.getUserData.id
          ? 'Anda'
          : referenceLog.created_by.name
      }}</span>
    </v-tooltip>

    <!-- category tanpa from & to -->
    <span
      v-if="[1, 11, 13, 14, 15].includes(referenceLog.category_id)"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      {{ messageList[referenceLog.category_id] }} {{ referenceLog.job.name }}
    </span>

    <!-- category date -->
    <span
      v-if="[4, 5].includes(referenceLog.category_id)"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      mengubah {{ messageList[referenceLog.category_id] }}
      {{ referenceLog.from ? `dari "${dateFormat(referenceLog.from)}"` : null }}
      menjadi "{{ dateFormat(referenceLog.to) }}"
    </span>

    <!-- category status -->
    <span
      v-if="referenceLog.category_id === 2"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      men-set status
      <template v-if="referenceLog.from">
        dari
        <v-chip
          :color="referenceLog.from.statusColor"
          class="mx-1 my-1 px-3"
          style="height: 20px !important"
          small
        >
          {{ referenceLog.from.statusName }}
        </v-chip>
      </template>
      menjadi
      <v-chip
        :color="referenceLog.to.statusColor"
        class="mx-1 my-1 px-3"
        style="height: 20px !important"
        small
      >
        {{ referenceLog.to.statusName }}
      </v-chip>
    </span>

    <!-- category priority -->
    <span
      v-if="referenceLog.category_id === 3"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      men-set prioritas Job
      <template v-if="referenceLog.from">
        dari
        <v-chip
          :color="referenceLog.from.priorityColor"
          class="mx-1 my-1 px-3"
          style="height: 20px !important"
          small
        >
          {{ referenceLog.from.priorityName }}
        </v-chip>
      </template>
      menjadi
      <v-chip
        :color="referenceLog.to.priorityColor"
        class="mx-1 my-1 px-3"
        style="height: 20px !important"
        small
      >
        {{ referenceLog.to.priorityName }}
      </v-chip>
    </span>

    <!-- category customer -->
    <span
      v-else-if="referenceLog.category_id === 7"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      men-set customer
      <template v-if="referenceLog.from">
        dari
        <!-- still broken from backend -->
        <span class="primary--text font-medium mx-1 my-1 text-truncate">{{
          referenceLog.from
        }}</span>
      </template>
      menjadi
      <!-- still broken from backend -->
      <span class="primary--text font-medium mx-1 my-1 text-truncate">{{ referenceLog.to }}</span>
    </span>

    <!-- category product -->
    <span
      v-else-if="referenceLog.category_id === 8"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      men-set produk
      <template v-if="referenceLog.from && referenceLog.from.length">
        dari
        <!-- still broken from backend -->
        <span
          class="primary--text font-medium mx-1 my-1 text-truncate"
          v-for="(pro, idx) in referenceLog.from"
          :key="pro.product_id"
        >
          {{ pro.product_id }}<template v-if="referenceLog.from.length - 1 !== idx">, </template>
        </span>
      </template>
      menjadi
      <!-- still broken from backend -->
      <span
        class="primary--text font-medium mx-1 my-1 text-truncate"
        v-for="(pro, idx) in referenceLog.to"
        :key="pro.product_id"
      >
        {{ pro.product_id }}
        <template v-if="referenceLog.to.length - 1 !== idx">, </template>
      </span>
    </span>

    <!-- category tags -->
    <!-- not yet implemented -->
    <span
      v-else-if="referenceLog.category_id === 9"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      men-set tags
      <template v-if="referenceLog.from.length">
        dari
        <span class="primary--text font-medium mx-1 my-1 text-truncate">{{
          referenceLog.from
        }}</span>
      </template>
      menjadi
      <span class="primary--text font-medium mx-1 my-1 text-truncate">{{ referenceLog.to }}</span>
    </span>

    <!-- category assignee -->
    <span
      v-else-if="referenceLog.category_id === 6"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      <template v-if="referenceLog.from.length && findRemovedItems(referenceLog, 'userId').length">
        menghapus
        <v-tooltip
          v-for="user in findRemovedItems(referenceLog, 'userId')"
          :key="'uf' + user.userId"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-avatar
              v-bind="attrs"
              size="30"
              class="v-avatar-light-bg elevation-1 cursor-pointer mx-2"
              v-on="on"
              @click="router.push({ name: 'user-detail', params: { id: user.userId } })"
              @click.stop
            >
              <v-img
                v-if="user.userPhoto"
                :src="user.userPhoto"
              />
              <span
                v-else
                class="body-2 text-truncate"
              >
                {{ avatarText(user.userName) }}
              </span>
            </v-avatar>
          </template>
          {{ user.userName }}
        </v-tooltip>
        sebagai assignee
      </template>
      <template v-if="referenceLog.to.length && findAddedItems(referenceLog, 'userId').length">
        {{
          referenceLog.from.length && findRemovedItems(referenceLog, 'userId').length ? 'dan ' : ''
        }}
        menambahkan
        <v-tooltip
          v-for="user in findAddedItems(referenceLog, 'userId')"
          :key="'ut' + user.userId"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-avatar
              v-bind="attrs"
              size="30"
              class="v-avatar-light-bg elevation-1 cursor-pointer mx-2"
              v-on="on"
              @click="router.push({ name: 'user-detail', params: { id: user.userId } })"
              @click.stop
            >
              <v-img
                v-if="user.userPhoto"
                :src="user.userPhoto"
              />
              <span
                v-else
                class="body-2 text-truncate"
              >
                {{ avatarText(user.userName) }}
              </span>
            </v-avatar>
          </template>
          {{ user.userName }}
        </v-tooltip>
        sebagai assignee
      </template>
    </span>

    <!-- category name -->
    <span
      v-if="referenceLog.category_id === 10"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      mengubah nama
      {{ referenceLog.from ? `dari "${referenceLog.from}"` : null }}
      menjadi "{{ referenceLog.to }}"
    </span>

    <!-- category sub job -->
    <span
      v-if="referenceLog.category_id === 16"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      membuat sub job
      <router-link
        @click.stop
        v-for="(job, idx) in referenceLog.to"
        :key="job.jobId"
        class="primary--text font-medium mx-1 my-1 text-truncate"
        :to="{ name: 'job-detail', params: { id: job.jobId } }"
      >
        {{ job.jobName }}
        <template v-if="idx !== referenceLog.to.length - 1">, </template>
      </router-link>
    </span>

    <!-- category job attachment -->
    <span
      v-if="referenceLog.category_id === 17 && userDetailOnly"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      <span v-if="!referenceLog.from">
        <span>menambahkan attachment baru</span>
        <router-link
          v-for="(attachment, idx) in referenceLog.from"
          :key="attachment.attachId"
          class="primary--text font-medium"
          :to="{ name: 'attachment-detail', params: { id: attachment.attachId } }"
        >
          <span>{{ attachment.attachName }}</span>
          <span v-if="idx !== referenceLog.from.length - 1">, </span>
        </router-link>
      </span>
      <span v-else-if="!referenceLog.to">
        <span>menghapus attachment</span>
        <router-link
          v-for="(attachment, idx) in referenceLog.to"
          :key="attachment.attachId"
          class="primary--text font-medium"
          :to="{ name: 'attachment-detail', params: { id: attachment.attachId } }"
        >
          <span>{{ attachment.attachName }}</span>
          <span v-if="idx !== referenceLog.to.length - 1">, </span>
        </router-link>
      </span>
      <span v-else>
        merename attachment dari
        <router-link
          v-for="(attachment, idx) in referenceLog.from"
          :key="`f${attachment.attachId}`"
          class="primary--text font-medium"
          :to="{ name: 'attachment-detail', params: { id: attachment.attachId } }"
        >
          <span>{{ attachment.attachName }}</span>
          <span v-if="idx !== referenceLog.from.length - 1">, </span>
        </router-link>
        menjadi
        <router-link
          v-for="(attachment, idx) in referenceLog.to"
          :key="`t${attachment.attachId}`"
          class="primary--text font-medium"
          :to="{ name: 'attachment-detail', params: { id: attachment.attachId } }"
        >
          <span>{{ attachment.attachName }}</span>
          <span v-if="idx !== referenceLog.to.length - 1">, </span>
        </router-link>
      </span>
    </span>

    <!-- category watcher -->
    <span
      v-else-if="referenceLog.category_id === 18"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      <template v-if="referenceLog.from.length && findRemovedItems(referenceLog, 'userId').length">
        menghapus
        <v-tooltip
          v-for="user in findRemovedItems(referenceLog, 'userId')"
          :key="'uf' + user.userId"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-avatar
              v-bind="attrs"
              size="30"
              class="v-avatar-light-bg elevation-1 cursor-pointer mx-2"
              v-on="on"
              @click="router.push({ name: 'user-detail', params: { id: user.userId } })"
              @click.stop
            >
              <v-img
                v-if="user.userPhoto"
                :src="user.userPhoto"
              />
              <span
                v-else
                class="body-2 text-truncate"
              >
                {{ avatarText(user.userName) }}
              </span>
            </v-avatar>
          </template>
          {{ user.userName }}
        </v-tooltip>
      </template>

      <template v-if="referenceLog.to.length && findAddedItems(referenceLog, 'userId').length">
        {{
          referenceLog.from.length && findRemovedItems(referenceLog, 'userId').length ? 'dan ' : ''
        }}
        menambahkan
        <v-tooltip
          v-for="user in findAddedItems(referenceLog, 'userId')"
          :key="'ut' + user.userId"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-avatar
              v-bind="attrs"
              size="30"
              class="v-avatar-light-bg elevation-1 cursor-pointer mx-2"
              v-on="on"
              @click="router.push({ name: 'user-detail', params: { id: user.userId } })"
              @click.stop
            >
              <v-img
                v-if="user.userPhoto"
                :src="user.userPhoto"
              />
              <span
                v-else
                class="body-2 text-truncate"
              >
                {{ avatarText(user.userName) }}
              </span>
            </v-avatar>
          </template>
          {{ user.userName }}
        </v-tooltip>
      </template>
      <span> sebagai watcher</span>
    </span>

    <!-- category assign comment -->
    <span
      v-else-if="referenceLog.category_id === 19"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      <template v-if="referenceLog.to"> men-assign </template>
      <template v-else-if="!referenceLog.to"> men-unassign </template>
      <template v-else> me-reassign </template>

      <template v-if="referenceLog.to && referenceLog.from.userId">
        dari
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-avatar
              v-bind="attrs"
              size="30"
              class="v-avatar-light-bg elevation-1 cursor-pointer mx-2"
              v-on="on"
              @click="
                router.push({
                  name: 'user-detail',
                  params: { id: referenceLog.from.userId },
                })
              "
              @click.stop
            >
              <v-img
                v-if="referenceLog.from.userPhoto"
                :src="referenceLog.from.userPhoto"
              />
              <span
                class="body-2 text-truncate"
                v-else
              >
                {{ avatarText(referenceLog.from.userName) }}
              </span>
            </v-avatar>
          </template>
          {{ referenceLog.from.userName }}
        </v-tooltip>
        menjadi
      </template>

      <v-tooltip
        bottom
        v-if="referenceLog.to"
      >
        <template #activator="{ on, attrs }">
          <v-avatar
            v-bind="attrs"
            size="30"
            class="v-avatar-light-bg elevation-1 cursor-pointer mx-2"
            v-on="on"
            @click="
              router.push({
                name: 'user-detail',
                params: { id: referenceLog.to.userId },
              })
            "
            @click.stop
          >
            <v-img
              v-if="referenceLog.to.userPhoto"
              :src="referenceLog.to.userPhoto"
            />
            <span
              class="body-2 text-truncate"
              v-else
            >
              {{ avatarText(referenceLog.to.userName) }}
            </span>
          </v-avatar>
        </template>
        {{ referenceLog.to.userName }}
      </v-tooltip>

      <v-tooltip
        bottom
        v-if="referenceLog.from && !referenceLog.to"
      >
        <template #activator="{ on, attrs }">
          <v-avatar
            v-bind="attrs"
            size="30"
            class="v-avatar-light-bg elevation-1 cursor-pointer mx-2"
            v-on="on"
            @click="
              router.push({
                name: 'user-detail',
                params: { id: referenceLog.from.userId },
              })
            "
            @click.stop
          >
            <v-img
              v-if="referenceLog.from.userPhoto"
              :src="referenceLog.from.userPhoto"
            />
            <span
              class="body-2 text-truncate"
              v-else
            >
              {{ avatarText(referenceLog.from.userName) }}
            </span>
          </v-avatar>
        </template>
        {{ referenceLog.from.userName }}
      </v-tooltip>

      {{ referenceLog.to ? 'ke' : '' }}
      {{ referenceLog.from && !referenceLog.to ? 'dari' : '' }}

      komentar :

      <v-btn
        text
        x-small
        color="primary"
        class="mx-1 my-1"
        @click.stop
      >
        <span
          v-html="referenceLog.from.commentText"
          class="assign-comment-text body-2 text-truncate"
          style="max-width: 250px"
          @click="
            router.push({
              name: 'job-detail',
              params: { id: referenceLog.job.id, commentId: referenceLog.from },
            })
            $emit('comment-click', referenceLog.from.commentId)
          "
        />
      </v-btn>

      <template v-if="referenceLog.from.attachId">
        di attachment

        <router-link
          class="primary--text font-medium mx-1 my-1 text-truncate"
          :to="{
            name: 'attachment-detail',
            params: { id: referenceLog.from.attachId },
          }"
          @click.stop
        >
          {{ referenceLog.from.attachName }}
        </router-link>
      </template>
    </span>

    <!-- category custom attribute text -->
    <span
      v-if="
        referenceLog.category_id === 12 &&
        [1, 2].includes(referenceLog.custom_attribute.data_type.id)
      "
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      men-set {{ referenceLog.custom_attribute.name }}
      {{ referenceLog.from ? `dari "${referenceLog.from}"` : null }}
      menjadi "{{ referenceLog.to }}"
    </span>

    <!-- category custom attribute date -->
    <span
      v-if="referenceLog.category_id === 12 && referenceLog.custom_attribute.data_type.id === 3"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      men-set {{ referenceLog.custom_attribute.name }}
      {{ referenceLog.from ? `dari "${dateFormat(referenceLog.from)}"` : null }}
      menjadi "{{ dateFormat(referenceLog.to) }}"
    </span>

    <!-- category custom attribute select single -->
    <span
      v-if="referenceLog.category_id === 12 && referenceLog.custom_attribute.data_type.id === 4"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      men-set {{ referenceLog.custom_attribute.name }}
      <template v-if="referenceLog.from.length">
        dari
        <span
          class="primary--text font-medium mx-1 my-1 text-truncate"
          v-for="(option, idx) in referenceLog.from"
          :key="option.caoId"
        >
          {{ option.caoName }}<template v-if="idx !== referenceLog.from.length - 1">, </template>
        </span>
      </template>
      menjadi
      <template v-if="referenceLog.to.length">
        <span
          class="primary--text font-medium mx-1 my-1 text-truncate"
          v-for="(option, idx) in referenceLog.to"
          :key="option.caoId"
        >
          {{ option.caoName }}<template v-if="idx !== referenceLog.to.length - 1">, </template>
        </span>
      </template>
      <template v-else>-</template>
    </span>

    <!-- category custom attribute select multiple -->
    <span
      v-if="referenceLog.category_id === 12 && referenceLog.custom_attribute.data_type.id === 5"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      <template v-if="referenceLog.from.length && findRemovedItems(referenceLog, 'caoId').length">
        menghapus
        <span
          class="primary--text font-medium mx-1 my-1 text-truncate"
          v-for="(option, idx) in referenceLog.from"
          :key="option.caoId"
        >
          {{ option.caoName }}<template v-if="idx !== referenceLog.from.length - 1">, </template>
        </span>
      </template>

      <template v-if="referenceLog.to.length">
        {{
          referenceLog.from.length && findRemovedItems(referenceLog, 'caoId').length ? 'dan ' : ''
        }}
        menambahkan
        <span
          class="primary--text font-medium mx-1 my-1 text-truncate"
          v-for="(option, idx) in referenceLog.to"
          :key="option.caoId"
        >
          {{ option.caoName }}<template v-if="idx !== referenceLog.to.length - 1">, </template>
        </span>
      </template>
      pada {{ referenceLog.custom_attribute.name }}
    </span>

    <!-- category custom attribute boolean -->
    <span
      v-if="referenceLog.category_id === 12 && referenceLog.custom_attribute.data_type.id === 6"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      men-set {{ referenceLog.custom_attribute.name }} dari
      {{ referenceLog.from ? 'true' : 'false' }} menjadi
      {{ referenceLog.to ? 'true' : 'false' }}
    </span>

    <!-- category custom attribute attachment -->
    <span
      v-if="referenceLog.category_id === 12 && referenceLog.custom_attribute.data_type.id === 7"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      <template v-if="findRemovedItems(referenceLog, 'attachId').length">
        menghapus
        <router-link
          v-for="(attachment, idx) in referenceLog.from.filter(
            att => !referenceLog.to.map(a => a.attachId).includes(att.attachId),
          )"
          :key="attachment.attachId"
          @click.stop
          class="primary--text font-medium mx-1 my-1 text-truncate"
          :to="{ name: 'attachment-detail', params: { id: attachment.attachId } }"
        >
          {{ attachment.attachName
          }}<template v-if="idx !== findRemovedItems(referenceLog, 'attachId').length - 1"
            >,
          </template>
        </router-link>
      </template>

      <template v-if="findAddedItems(referenceLog, 'attachId').length">
        {{
          referenceLog.from.length && findRemovedItems(referenceLog, 'attachId').length
            ? 'dan '
            : ''
        }}
        menambahkan
        <router-link
          v-for="(attachment, idx) in findAddedItems(referenceLog, 'attachId')"
          :key="attachment.attachId"
          class="primary--text font-medium mx-1 my-1 text-truncate"
          :to="{ name: 'attachment-detail', params: { id: attachment.attachId } }"
          @click.stop
        >
          {{ attachment.attachName
          }}<template v-if="idx !== findAddedItems(referenceLog, 'attachId').length - 1"
            >,
          </template>
        </router-link>
      </template>
      pada {{ referenceLog.custom_attribute.name }}
    </span>

    <!-- category custom attribute boolean -->
    <span
      v-if="referenceLog.category_id === 12 && referenceLog.custom_attribute.data_type.id === 8"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      PLACEHOLDER FORMULA NOTIFICATION
    </span>

    <!-- category update comment commentIsRevoled value -->
    <span
      v-if="referenceLog.category_id === 20"
      :class="`d-flex align-center body-2 mx-2 ${showfull ? 'flex-wrap' : 'text-truncate'}`"
    >
      mengubah

      <v-btn
        text
        x-small
        color="primary"
        class="mx-1 my-1"
        @click.stop
      >
        <span
          v-html="referenceLog.from.commentText"
          class="assign-comment-text body-2 text-truncate"
          style="max-width: 250px"
          @click="
            router.push({
              name: 'job-detail',
              params: { id: referenceLog.job.id, commentId: referenceLog.from },
            })
            $emit('comment-click', referenceLog.from.commentId)
          "
        />
      </v-btn>

      status Resolve dari

      <span class="primary--text font-medium mx-1 my-1 text-truncate text-capitalize">
        {{ referenceLog.from.commentIsResolved }}
      </span>

      menjadi

      <span class="primary--text font-medium mx-1 my-1 text-truncate text-capitalize">
        {{ referenceLog.to.commentIsResolved }}
      </span>
    </span>
  </div>
</template>

<script>
import { messageList } from '@/constants/notification'
import store from '@/store'
import dateFormat from '@/utils/dateFormat'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { avatarText } from '@core/utils/filter'

export default {
  props: {
    referenceLog: {
      type: Object,
      required: true,
    },
    showfull: {
      type: Boolean,
    },
    userDetailOnly: {
      type: Boolean,
    },
    strip: {
      type: Boolean,
    },
  },

  setup() {
    const { t } = useUtils()
    const { router } = useRouter()

    // Taken example from TimelineList.vue
    const findAddedItems = (log, key) => {
      return log.to.filter(el => !log.from.map(item => item[key]).includes(el[key]))
    }
    const findRemovedItems = (referenceLog, key) => {
      return referenceLog.from.filter(
        el => !referenceLog.to.map(item => item[key]).includes(el[key]),
      )
    }

    return {
      findAddedItems,
      findRemovedItems,

      avatarText,
      messageList,
      store,
      router,

      // i18n
      t,

      dateFormat,
    }
  },
}
</script>

<style lang="scss">
.notification-reference-log {
  .assign-comment-text > * {
    margin: 0 !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}
</style>
