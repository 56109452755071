<template>
  <v-slide-y-transition>
    <v-navigation-drawer
      v-if="isOpen"
      class="overflow-hidden d-flex flex-column pl-0 pr-1"
      v-model="isOpen"
      right
      fixed
      permanent
      :width="
        rootRoute.fullPath === '/' || isFullview
          ? '100vw'
          : $vuetify.breakpoint.md
          ? '45vw'
          : $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
          ? '100vw'
          : drawerWidth && (rootRoute.fullPath !== '/' || !isFullview)
          ? drawerWidth
          : '35vw'
      "
      style="z-index: 203; min-width: 475px"
    >
      <div
        v-if="rootRoute.fullPath !== '/' && !isFullview"
        v-show="$vuetify.breakpoint.mdAndUp"
        class="primary-fg"
        ref="globalDrawerResizer"
        @mousedown="handleResize"
        style="
          position: absolute !important;
          height: 100vh;
          width: 5px;
          z-index: 999;
          border: 5px inherit;
          cursor: col-resize;
        "
      ></div>

      <div
        class="d-flex flex-column overflow-auto position-relative"
        style="height: 100vh"
      >
        <app-breadcrumb
          class="mt-6 px-4"
          title="Detail Pengguna"
          hide-breadcrumbs
          :onClickBack="handleBack"
        >
          <template #action>
            <v-icon
              v-if="rootRoute?.fullPath !== '/'"
              v-show="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? false : true"
              class="primary--text mr-4"
              @click="handleOpenFullview()"
            >
              {{ isFullview ? icons.mdiFullscreenExit : icons.mdiFullscreen }}
            </v-icon>
          </template>
        </app-breadcrumb>

        <UserDetail
          v-bind="componentProps"
          :isFullview="isFullview"
        />
      </div>
    </v-navigation-drawer>
  </v-slide-y-transition>
</template>

<script>
import router from '@/router'
import UserDetail from '@/views/user/UserDetail.vue'
import { mdiFullscreen, mdiFullscreenExit } from '@mdi/js'
import Vue, { onMounted, provide, ref } from 'vue'
import AppBreadcrumb from './AppBreadcrumb.vue'

export default {
  components: {
    UserDetail,
    AppBreadcrumb,
  },
  setup() {
    const isOpen = ref(false)
    const rootRoute = ref(router.currentRoute)
    const componentProps = ref()
    const isFullview = ref(false)
    const drawerWidth = ref('35vw')

    provide('isFullview', isFullview)

    const handleBack = () => {
      isOpen.value = false

      if (rootRoute.value?.name) return window.history.pushState({}, null, rootRoute.value.fullPath)

      router.push({ name: 'workspace-root' })
    }

    const handleOpenFullview = () => {
      isFullview.value = !isFullview.value

      if (drawerWidth.value.includes('px')) drawerWidth.value = '35vw'
    }

    const handleResize = () => {
      document.body.style.userSelect = 'none'
      document.addEventListener('mousemove', throttledResizeSidebar)
      document.addEventListener('mouseup', stopResize)
    }

    /* Throttle function, stolen from ChatGPT itself
     * this function used to limits the amount of eventListener
     * callback function being called, to also reduce lag per se.
     */
    const throttle = (func, limit) => {
      let lastFunc
      let lastRan

      return function (...args) {
        const context = this
        if (!lastRan) {
          func.apply(context, args)
          lastRan = Date.now()
        } else {
          clearTimeout(lastFunc)
          lastFunc = setTimeout(() => {
            if (Date.now() - lastRan >= limit) {
              func.apply(context, args)
              lastRan = Date.now()
            }
          }, limit - (Date.now() - lastRan))
        }
      }
    }
    const throttledResizeSidebar = throttle(event => {
      const newWidth = `${window.innerWidth - event.clientX}px`

      if (event.clientX === 0) {
        isFullview.value = true

        return stopResize()
      }
      drawerWidth.value = newWidth
    }, 50)

    const stopResize = () => {
      document.body.style.userSelect = ''
      document.removeEventListener('mousemove', throttledResizeSidebar)
      document.removeEventListener('mouseup', stopResize)
    }

    onMounted(() => {
      Vue.$drawer = props => {
        if (!isOpen.value) rootRoute.value = router.currentRoute

        componentProps.value = props

        isOpen.value = true
      }

      if (rootRoute.value?.fullPath === '/') isFullview.value = true
    })

    return {
      isOpen,
      rootRoute,
      componentProps,
      isFullview,
      drawerWidth,

      handleBack,
      handleOpenFullview,
      handleResize,

      icons: {
        mdiFullscreen,
        mdiFullscreenExit,
      },
    }
  },
}
</script>
